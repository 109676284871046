import React from "react";
import "./ParametreAdmin.css";
import { EditSquare } from "react-iconly";
import "react-responsive-modal/styles.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "./ParametreAdmin.css";
import { NavLink } from "react-router-dom";
import { Delete } from "@material-ui/icons/";

const Listeporteur = () => {
  var listeadminprojet = [
    {
      id: 1,
      prenom: "Modou ",
      nom: "Sow",
      phone: 7700000,
      email: "email@gmail.com",
    },
    {
      id: 2,
      prenom: "Abdou ",
      nom: "Diop",
      phone: 7700000,
      email: "email@gmail.com",
    },
  ];

  const actionFormatter = (cell, row) => {
    return (
      <div className="parametre-actions-container">
        <NavLink to="/admin/parametre" style={{ color: "#ACABAB" }}>
          <Delete
            set="bold"
            primarycolor="#ACABAB"
            style={{ fontSize: "20px" }}
          />
        </NavLink>

        <NavLink to="/admin/parametre" style={{ color: "#ACABAB" }}>
          <EditSquare
            set="bold"
            primaryColor="#ACABAB"
            style={{ fontSize: "16px", width: "20px", marginLeft: " 10px" }}
          />
        </NavLink>
      </div>
    );
  };

  return (
    <div classprenom="infos-perso-input">
      <BootstrapTable
        data={listeadminprojet}
        striped={true}
        hover={true}
        condensed={false}
        multiColumnSort={2}
        search={false}
        version="4"
        bordered={false}
      >
        <TableHeaderColumn
          dataField="id"
          isKey={true}
          bordered={false}
          thStyle={{
            fontWeight: 600,
            fontSize: 18,
            color: "#115C67",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 16,
            color: "rgba(0, 0, 0, 0.7)",
            padding: 15,
          }}
          width="25"
        >
          N°
        </TableHeaderColumn>

        <TableHeaderColumn
          bordered={false}
          thStyle={{
            fontWeight: 600,
            fontSize: 18,
            color: "#115C67",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 16,
            color: "rgba(0, 0, 0, 0.7)",
            padding: 15,
          }}
          dataField="prenom"
          width="180"
        >
          Prénom
        </TableHeaderColumn>
        <TableHeaderColumn
          bordered={false}
          thStyle={{
            fontWeight: 600,
            fontSize: 18,
            color: "#115C67",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 16,
            color: "rgba(0, 0, 0, 0.7)",
            padding: 15,
          }}
          dataField="nom"
          width="150"
        >
          Nom
        </TableHeaderColumn>

        <TableHeaderColumn
          bordered={false}
          thStyle={{
            fontWeight: 600,
            fontSize: 18,
            color: "#115C67",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 16,
            color: "rgba(0, 0, 0, 0.7)",
            padding: 15,
          }}
          width="150"
          dataField="phone"
        >
          Téléphone
        </TableHeaderColumn>
        <TableHeaderColumn
          bordered={false}
          thStyle={{
            fontWeight: 600,
            fontSize: 18,
            color: "#115C67",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 16,
            color: "rgba(0, 0, 0, 0.7)",
            padding: 15,
          }}
          width="150"
          dataField="email"
        >
          Adresse Mail
        </TableHeaderColumn>
        <TableHeaderColumn
          bordered={false}
          thStyle={{
            fontWeight: 600,
            fontSize: 18,
            color: "#115C67",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 16,
            color: "rgba(0, 0, 0, 0.7)",
            padding: 15,
          }}
          width="200"
          dataFormat={(cell, row) => actionFormatter(cell, row)}
        >
          Actions
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

export default Listeporteur;
