import React, { useState } from "react";
import "./SidebarTableauDeBord.css";
import { NavLink } from "react-router-dom";

// import FeatherIcon from 'feather-icons-react';

const SidebarTableauDeBord = ({ value }) => {
  const [active, setActive] = useState(value);
  return (
    <div className="component-sidebar-tableau-bord">
      <div className="sidebar-customisation pl-5">
        <div className="sidebar-customisation-content">
          <ul className="nav nav-items-custom flex-column pl-0">
            <li className="nav-item nav-item-vertical-custom">
              <NavLink
                className={`nav-link nav-link-sidebar-custom ${
                  active === 0 && "actived"
                }`}
                to="/dashboard/editer-mon-profile"
                onClick={() => setActive(0)}
              >
                <span className="">Mon profil</span>
              </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom nav-sidebar-items-custom">
              <NavLink
                className={`nav-link nav-link-sidebar-custom ${
                  active === 1 && "actived"
                }`}
                to="/dashboard/activite"
                onClick={() => setActive(1)}
              >
                <span className="">Mon activité</span>
              </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom nav-sidebar-items-custom">
              <NavLink
                className={`nav-link nav-link-sidebar-custom ${
                  active === 2 && "actived"
                }`}
                to="/dashboard/projets"
                onClick={() => setActive(2)}
              >
                <span className="">Mes projets</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidebarTableauDeBord;
