import {
  FETCH_PROJECTS_FOR_VISITORS,
  FILTER_PROJECTS_FOR_VISITORS,
} from "../types";

export const projectsForVisitorsReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECTS_FOR_VISITORS:
      return {
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    case FILTER_PROJECTS_FOR_VISITORS:
      // console.log("action", action.payload)
      return {
        ...state,
        filteredData: action.payload.items,
      };

    default:
      return state;
  }
};
