import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Message } from "react-iconly";
import { useNavigate  } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import AuthServices from "../../Services/AuthServices";
import "./ForgetPassword.css";
const ForgetPassword = ({ saveData }) => {
	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
		React.useState(false);
	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				email: yup
					.string()
					.email("Email invalide!")
					.required("L'email est requis."),
			}),
		[]
	);

	const navigate  = useNavigate ();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = (data) => {
		// console.log(data);
		setIsSuccessfullySubmitted(true);
		let request = AuthServices.onRequestResetPassword(data);
		request
			.then(async (response) => {
				// console.log(response);
				setIsSuccessfullySubmitted(false);
				if (response?.data.status === "success") {
					Swal.fire({
						icon: "success",
						html: "<h4> Votre requête a bien été prise en compte, Nous vous avons envoyé un email contenant le code de confirmation pour le changement de votre mot de passe</h4>",
						timer: 3000,
						showConfirmButton: false,
					}).then(() =>
						navigate({
							pathname: "/reset-password",
							email: data,
						})
					);
				}
			})
			.catch((error) => {
				setIsSuccessfullySubmitted(false);
				// console.log(error);
				if (error?.response.status === 400) {
					Swal.fire({
						icon: "error",
						html: "<h4>L'email que vous avez saisi n'existe pas dans notre base de donnée</h4>",
						timer: 4000,
						showConfirmButton: false,
					});
				} else {
					Swal.fire({
						icon: "error",
						html: "Error",
						timer: 4000,
						showConfirmButton: false,
					});
				}
			});
	};
	return (
		<div className="auth-component auth-forget-component">
			<div className="container-cayore auth-page-container">
				<div className="row auth-page-row">
					<div className="col-md-6 auth-section left-side">
						<div className="lefty-empty-content"></div>
					</div>

					<div className="col-md-6 auth-section right-side">
						<div className="auth-form-container">
							<h5 className="auth-form-forget-title">
								Récupération de mot de passe
							</h5>
							<div className="container-cayore mb-5">
								<p className="auth-form-text">
									Veuillez entrer votre
									email Nous vous enverrons
									un lien pour modifier le
									mot de passe
								</p>
							</div>
							<form
								id="login-form"
								onSubmit={handleSubmit(
									onSubmit
								)}
							>
								<div className="row auth-form-content">
									<div className="col-md-12">
										<div className="input-group auth-form-group">
											<div className="input-group-prepend">
												<span
													className="input-group-text span-input-icon"
													id="basic-addon1"
												>
													<Message
														set="bold"
														primaryColor="#C4C4C4"
														className="auth-icon"
													/>
												</span>
											</div>
											<input
												name="email"
												id="email"
												type="text"
												className="form-control cv-input-with-icon inputField"
												placeholder="Email"
												data-testid="emailId"
												{...register(
													"email"
												)}
											/>
											{errors &&
												errors.email && (
													<div
														className="alert alert-danger gfa-alert-danger mt-3"
														role="alert"
													>
														{
															errors
																.email
																?.message
														}
													</div>
												)}
										</div>
									</div>
								</div>

								<div className="row auth-submit-btn-container">
									<div className="col-md-6 offset-md-3 auth-submit-btn-content">
										{!isSuccessfullySubmitted ? (
											<button
												type="submit"
												data-testid="btnId"
												className="auth-submit-btn btn btn-success"
											>
												ENVOYER
											</button>
										) : (
											<button className="auth-submit-btn btn btn-success in-progress-btn">
												En cours
												&nbsp;
												<i className="fas fa-spin fa-spinner"></i>
											</button>
										)}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgetPassword;
