import { message_contacts } from "./Endpoints";
import axios from "axios";

export default {
  async getMessageContact(id, config) {
    const response = await axios.get(
      `${message_contacts.onGetMessageContactId(id)}`,
      config
    );
    // console.log(response)
    return response.data;
  },
  async editMessageContact(id, data, config) {
    const response = await axios.put(
      `${message_contacts.onPutMessageContact(id)}`,
      data,
      config
    );
    // console.log(response)
    return response.data;
  },
  async deleteMessageContact(id, config) {
    const response = await axios.delete(
      `${message_contacts.onDeleteMessageContact(id)}`,
      config
    );
    return response.data;
  },
  async postCreateMessageContact(data) {
    const response = await axios.post(
      message_contacts.onPostMessageContact(),
      data
    );
    return response.data;
  },
};
