import React, { useRef, useState } from "react";
import ListeDocuments from "../../../Tables/backend/ListeDocuments/ListeDocuments";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import * as IconlyPack from "react-iconly";

const DetailProjetDocument = ({ project, isLoading }) => {
	const fileInputRef = useRef(null);
	const [selectedFileName, setSelectedFileName] = useState(null);
	// const [isLoading, setIsLoading] = useState(true);
	const handleFileSelect = () => {
	  fileInputRef.current.click();
	};
  
	const handleFileChange = (e) => {
	  const selectedFiles = e.target.files;
	  const firstSelectedFile = selectedFiles[0];
  
	  if (firstSelectedFile) {
		setSelectedFileName(firstSelectedFile.name);
		console.log("Fichier sélectionné :", firstSelectedFile);
	  }
	};
  
	const handleAddFile = () => {
	  // Ajoutez ici la logique pour traiter le fichier sélectionné, si nécessaire
	  alert(`Ajouter le fichier : ${selectedFileName}`);
  
	  // Réinitialise le nom du fichier après l'ajout
	  setSelectedFileName(null);
	};
	let buttonStartStyle = {
		background: "#115c67",
		cursor: "pointer",
		textAlign: "center",
		color: "var(--primaryColor)",
	  };

  return (
    <div className="detail-projet-doc" style={{ width: "100%" }}>
      <div className="link-add-doc-container d-flex justify-content-center mt-5">
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
        />
        <button
          type="button"
          className="link-add-doc-button"
          onClick={handleFileSelect}
        >
          <AddCircleOutlineIcon className="btn-nouvelle-question-icon" />
          <span className="link-add-doc-button-text ml-2">
            Ajouter un document
          </span>
        </button>
		{selectedFileName && (<button
			type="button"
			className="btn-add-file"
			onClick={handleAddFile}
			style={buttonStartStyle}
		  >
			Enrégistré
		  </button>)}
		</div>
		  {selectedFileName && (
			<div className="selected-file-container d-flex align-items-center justify-content-center ml-2 mt-3">
			  <IconlyPack.Document style={{ color: "#6B92DC" }} set="bold" />
			  <h6 className="ml-2">{selectedFileName}</h6>
			</div>
		)}
      <ListeDocuments project={project} isLoading={isLoading} />
    </div>
  );
};

export default DetailProjetDocument;
