const InvestisseurCalendrierRemboursementTableData = [
    {   id:1,
        mois: 'Juin 2021',
        echeance: '20 000 FCFA',
        interet: '20 000 FCFA',
        capital: '20 000 FCFA',
        en_cours: '20 000 FCFA',
    },
    {   id:2,
        mois: 'Juin 2021',
        echeance: '20 000 FCFA',
        interet: '20 000 FCFA',
        capital: '20 000 FCFA',
        en_cours: '20 000 FCFA',
    },
    {   id: 3,
        mois: 'Juin 2021',
        echeance: '20 000 FCFA',
        interet: '20 000 FCFA',
        capital: '20 000 FCFA',
        en_cours: '20 000 FCFA',
    },
    {   id:4,
        mois: 'Juin 2021',
        echeance: '20 000 FCFA',
        interet: '20 000 FCFA',
        capital: '20 000 FCFA',
        en_cours: '20 000 FCFA',
    },
    
];



export default InvestisseurCalendrierRemboursementTableData;
