import axios from "axios";
import api from "../../Http/global-vars";
import { FETCH_PROJECTS_BY_PORTEUR } from "../types";

export const fetchProjectsByPorteur = (id) => {
	return async (dispatch) => {
		const config = {
			headers: {
				Authorization: `Bearer ${window.sessionStorage.getItem(
					"userToken"
				)}`,
			},
		};
		const token = window.sessionStorage.getItem("userToken");
		if (token) {
			const url = api + "projetbyporteur/" + id+"/";

		await axios
			.get(url, config)
			.then(async (res) => {
				dispatch({
					type: FETCH_PROJECTS_BY_PORTEUR,
					payload: res?.data.results.sort(
						(a, b) => a?.id > b?.id ? ( a?.id === b?.id) ? 0 : -1 : 1
					),
				});
			})
			.catch((error) => console.log("error", error?.response));
		}
	};
};
