import { FETCHS_FINISH_PROJECTS_BY_PORTEUR } from "../types";

export const finishProjectByPorteurReducer = (
	state = { isLoading: true, data: [] },
	action,
) => {
    switch (action.type) {
		case FETCHS_FINISH_PROJECTS_BY_PORTEUR:
			return {
				isLoading: false,
				data: action.payload,
				filteredData: action.payload,
			};
		default:
            return state;
	}
};
