import React from "react";

function Listeporteurprojetadminmodal() {
  return (
    <div className="modal-dialog modal-lg modal-dialog-centered">
      <div className="profile-container-photo">
        <div className="modal-header">
          <h5 className="modal-ajouter" id="ajouterunporteurdeprojet">
            Ajouter un porteur de projet
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form className="">
          <div className="container-input-address">
            <div className="row">
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Prenom</label>
                  <input
                    type="text"
                    className="form-control-profil"
                    name="first_name"
                    placeholder="Abdou"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Nom</label>
                  <input
                    type="text"
                    className="form-control-profil"
                    name="last_name"
                    placeholder="diop"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Telephone</label>
                  <input
                    type="telephone"
                    name="telephone"
                    className="form-control-profil"
                    placeholder="7700000"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control-profil"
                    placeholder="email"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-end flex-column">
            <div className="ms-auto">
              <button type="button" className="btn-modifier">
                Enregistrer
              </button>
            </div>
          </div>
        </form>{" "}
      </div>
    </div>
  );
}

export default Listeporteurprojetadminmodal;
