import React from "react";

import {
	BootstrapTable,
	TableHeaderColumn,
	ClearSearchButton,
} from "react-bootstrap-table";
import { useSelector } from "react-redux";
// import { CloseSquare } from "react-iconly";
import ProjetEnCoursDeCampagneSkeleton from "./ProjetEnCoursDeCampagneSkeleton";
import "./ProjetsEnCoursDeCampagne.css";
import { NavLink } from "react-router-dom";
import moment from "moment";

const ProjetsEnCoursDeCampagne = (props) => {
	const projectInCampaign = useSelector(
		(state) => state.projectsInCampaign
	);
	console.log("res", projectInCampaign);

	const handleClearButtonClick = (onClick) => {
		console.log(
			"This is my custom function for ClearSearchButton click event"
		);
		onClick();
	};

	const createCustomClearButton = (onClick) => {
		return (
			<ClearSearchButton
				btnText="Effacer"
				btnContextual="btn-warning"
				className="my-custom-class"
				onClick={(e) => handleClearButtonClick(onClick)}
			/>
		);
	};

	const options = {
		clearSearch: true,
		clearSearchBtn: createCustomClearButton,
		noDataText: "Aucune donnée disponible",
	};
	const nameFormatter = (cell, row) => {
		return (
			<div className="projets-en-cours-buttons-container">
				<NavLink
					to={{
						pathname: `/projet/${row.slug}`,
						state: row,
					}}
					className="redirection-project"
				>
					{cell}
				</NavLink>
			</div>
		);
	};
	// const actionFormatter = (cell, row) => {
	// 	return (
	// 		<div className="projets-en-cours-buttons-container">
	// 			<NavLink
	// 				to={{
	// 					pathname: `/projet/${row.slug}`,
	// 					state: row,
	// 				}}
	// 				className="projets-en-cours-button mr-2"
	// 			>
	// 				+detail
	// 			</NavLink>
	// 			{/* <button
	// 				className="projets-en-cours-button"
	// 				data-toggle="modal"
	// 				data-target="#securityModal"
	// 			>
	// 				Finaliser
	// 			</button> */}
	// 			{/* <button
	// 				to={``}
	// 				className="projets-en-cours-button-close"
	// 			>
	// 				<CloseSquare
	// 					set="curved"
	// 					stroke="regular"
	// 					size="medium"
	// 				/>
	// 			</button> */}
	// 		</div>
	// 	);
	// };

	const dateFormatter = (cell) => (
		<span>{moment(cell).format("DD/MM/YYYY")}</span>
	);

	// const currencyFormatter = (cell) => {
	// 	// const num = 1234567890.1234;
	// 	const formatConfig = {
	// 		style: "currency",
	// 		currency: "XOF",
	// 		minimumFractionDigits: 2,
	// 		currencyDisplay: "symbol",
	// 		currencySign: "accounting",
	// 	};

	// 	// setup formatters
	// 	const xofNumberFormatter = new Intl.NumberFormat(
	// 		"sn-SN",
	// 		formatConfig
	// 	);
	// 	return xofNumberFormatter.format(cell);
	// };

	return (
		<div className="child-table-container">
			<div className="table-body-container-activite">
				<div className="row easypm-table-row">
					<div className="col-md-12 easypm-table-col">
						{projectInCampaign?.isLoading && (
							<ProjetEnCoursDeCampagneSkeleton />
						)}
						{!projectInCampaign?.isLoading && (
							<div className="table-container-activite">
								<BootstrapTable
									data={
										projectInCampaign?.filteredData
									}
									hover={true}
									condensed={true}
									multiColumnSort={2}
									options={options}
									search={false}
									version="4"
									bordered={false}
								>
									<TableHeaderColumn
										dataField="id"
										isKey={true}
										hidden
									></TableHeaderColumn>
									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 16,
											color: "#BB4411",
										}}
										tdStyle={{
											fontWeight: 500,
											fontSize: 14,
											color: "rgba(0, 0, 0, 0.7)",
										}}
										width="100"
										dataField="title"
										dataFormat={(
											cell,
											row
										) =>
											nameFormatter(
												cell,
												row
											)
										}
									>
										Nom Projets
									</TableHeaderColumn>
									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 16,
											color: "#BB4411",
										}}
										tdStyle={{
											fontWeight: 500,
											fontSize: 14,
											color: "rgba(0, 0, 0, 0.7)",
										}}
										width="100"
										dataField="montant"
									>
										Montant
									</TableHeaderColumn>
									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 16,
											color: "#BB4411",
										}}
										tdStyle={{
											fontWeight: 500,
											fontSize: 14,
											color: "rgba(0, 0, 0, 0.7)",
										}}
										width="100"
										dataField="type_programme"
									>
										Type de projet
									</TableHeaderColumn>
									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 16,
											color: "#BB4411",
										}}
										tdStyle={{
											fontWeight: 500,
											fontSize: 14,
											color: "rgba(0, 0, 0, 0.7)",
										}}
										width="100"
										dataField="statut"
									>
										Statut
									</TableHeaderColumn>

									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 16,
											color: "#BB4411",
										}}
										tdStyle={{
											fontWeight: 500,
											fontSize: 14,
											color: "rgba(0, 0, 0, 0.7)",
										}}
										width="100"
										dataField="created_at"
										dataFormat={(
											cell
										) =>
											dateFormatter(
												cell
											)
										}
									>
										Date de création
									</TableHeaderColumn>
									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 16,
											color: "#BB4411",
										}}
										tdStyle={{
											fontWeight: 500,
											fontSize: 14,
											color: "rgba(0, 0, 0, 0.7)",
										}}
										width="100"
										dataField="restTime"
									>
										Temps restant
									</TableHeaderColumn>
									{/* <TableHeaderColumn
										dataField="id"
										// dataFormat={(
										// 	cell,
										// 	row
										// ) =>
										// 	actionFormatter(
										// 		cell,
										// 		row
										// 	)
										// }
										width="150"
									>
										Actions
									</TableHeaderColumn> */}
								</BootstrapTable>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjetsEnCoursDeCampagne;
