import React, { useState, useEffect } from "react";
import ProjetImg from "../../../../images/image2.png";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-iconly";
import { useDispatch, useSelector } from "react-redux";
import ListProjetSkeleton from "./ListProjetSkeleton";
import moment from "moment";
import { fetchProjectsForVisitors } from "../../../../redux/projectsForVisitors/ProjectForVisitorAction";

const ProjetsEnLigne = () => {
  const projectsForVisitors = useSelector((state) => state.projectsForVisitors);
  const { isLoading, filteredData } = projectsForVisitors;
  const [error, setError] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();
  // console.log(projectsForVisitors)

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        setShowSkeleton(false);
        setError("La requête a pris trop de temps. Veuillez réessayer.");
      }, 30000);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(fetchProjectsForVisitors());
      setShowSkeleton(false);
      setProjects(filteredData.slice(0, 3));
    }
  }, [isLoading, filteredData]);

  return (
    <section className="container-cayore home-section-container">
      <h1 className="home-project-section-title">Projets en ligne</h1>
      {showSkeleton && <ListProjetSkeleton />}
      {!showSkeleton && error && (
        <div className="col-md-12 text-center">
          <p className="bg-danger text-light font-weight-bold p-2">{error}</p>
        </div>
      )}
      {!showSkeleton && !error && (
        <>
          <div className="row project-list-container">
            {projects.map((project) => {
              const projectInvestisseurs = project.projet_invest || [];
              const projectMontantsInvestis = projectInvestisseurs.map(
                (investisseur) => investisseur.montant || 0
              );
              const projectTotalInvestment = projectMontantsInvestis.reduce(
                (acc, current) => {
                  const currentNumber = parseFloat(current) || 0;
                  return acc + currentNumber;
                },
                0
              );
              const projectObjectifCollecte =
                project.fonds_supplementaires_prix || 0;
              const projectPourcentage = Math.min(
                Math.floor(
                  (projectTotalInvestment * 100) / projectObjectifCollecte
                ),
                100
              );
              return (
                <div
                  className="col-md-4 project-item-container"
                  key={project.id}
                >
                  <Link
                    to={{ pathname: `/projet/${project.slug}`, state: project }}
                    key={project.id}
                  >
                    <div className="project-item-global-card">
                      <div className="card bg-white project-item-card">
                        <div className="project-item-card-img-container">
                          <img
                            src={ProjetImg}
                            className="card-img-top project-item-img"
                            alt="Projet Item"
                          />
                        </div>
                        <div className="project-funding-type-label-container">
                          <div
                            className={`project-funding-type ${
                              project?.mode_financement === "obligation"
                                ? "don-item"
                                : project?.mode_financement === "action"
                                ? "equity-item"
                                : project?.mode_financement === "don"
                                ? "dette-item"
                                : "dette-item"
                            }`}
                          >
                            {project?.mode_financement === "obligation"
                              ? "Prêt / Obligations"
                              : project?.mode_financement === "action"
                              ? "Action"
                              : project?.mode_financement === "don"
                              ? "Donation"
                              : "Autre"}
                          </div>
                          <div className="project-funding-label amorcage-item">
                            {project?.stade_development === "pre_seed"
                              ? "Pre Seed"
                              : project?.stade_development === "amorçage"
                              ? "Amorçage"
                              : project?.stade_development === "early_stage"
                              ? "Early Stage"
                              : "Non choisi"}
                          </div>
                        </div>
                        <div className="card-body bg-white project-item-card-body">
                          <div className="d-flex justify-content-between project-statistic-container">
                            <div className="project-funded-pourcentage">
                              <p>{projectPourcentage}%</p>
                            </div>
                            <div className="project-funding-container">
                              <p>
                                {project.fonds_supplementaires_prix
                                  ? project.fonds_supplementaires_prix
                                  : "Montant non défini"}{" "}
                                FCFA
                              </p>
                            </div>
                          </div>
                          <div className="progress project-progress-container">
                            <div
                              className="progress-bar project-project-bar"
                              role="progressbar"
                              style={{
                                width: `${projectPourcentage || 0}%`, // Set the width dynamically based on the calculated percentage
                              }}
                              aria-valuenow={projectPourcentage || 0}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="project-dates-container">
                              <p>
                                {project.created_at
                                  ? moment(project.created_at).format("LL")
                                  : "(Date non communiqué)"}{" "}
                                au &nbsp;
                                {project.date_lancement
                                  ? moment(project.date_lancement).format("LL")
                                  : "(Date non communiqué)"}
                              </p>
                            </div>
                            <div className="project-funding-donor-counting">
                              <p>
                                {projectInvestisseurs.length}
                                &nbsp; participations
                              </p>
                            </div>
                          </div>
                          <h5 className="card-title mt-3 project-item-name">
                            {project.title}
                          </h5>
                          <div className="project-description-container">
                            <p>{project.description.slice(0, 400)}</p>
                          </div>
                          <div className="py-1 text-center mt-1 fund-project-btn-container">
                            <Link
                              to={{
                                pathname:
                                  projectPourcentage >= 100
                                    ? ""
                                    : `/projet/${project.slug}`,
                                state: project,
                              }}
                              className={`btn ${
                                projectPourcentage >= 100 ? "disabled-btn bg-secondary" : "fund-project-btn"
                              }`}
                              disabled={projectPourcentage >= 100}
                            >
                              {projectPourcentage >= 100
                                ? "Déjà financé"
                                : "Financer ce projet"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-end">
            <div className="text-voir">
              <Link to="/liste-des-projets" className="text-voir-plus">
                Découvrir plus
                <ArrowRight set="light" />
              </Link>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default ProjetsEnLigne;
