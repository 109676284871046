import React, { useMemo, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import "./SoumettreProjet.css";

const InformationsSurLentreprise = ({ formData, setFormData, navigation }) => {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required("Ce champ est requis."),
        description: yup
          .string()
          .required("Ce champ est requis.")
          .max(100, "Vous devez écrire au maximum 100 caractères.")
          .nullable(),
        url_demo: yup.string().url("Votre url est invalide.").nullable(),
        taille_marche: yup.string().required("Ce champ est requis.").nullable(),
        fraction_objectif: yup
          .string()
          .required("Ce champ est requis.")
          .nullable(),
        quand: yup.string().required("Ce champ est requis.").nullable(),
        combien: yup.string().required("Ce champ est requis.").nullable(),
        probleme: yup.string().required("Ce champ est requis.").nullable(),
        pourquoi_probleme: yup
          .string()
          .required("Ce champ est requis.")
          .nullable(),
        sauce_secrete: yup.string().required("Ce champ est requis.").nullable(),
        valeur: yup.string().required("Ce champ est requis.").nullable(),
      }),
    []
  );
  const { register, handleSubmit, clearErrors, formState, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [description, setDescription] = useState("");
  useEffect(() => {
    setValue("title", formData?.title);
    setValue("description", formData?.description);
    setValue("url_demo", formData?.url_demo);
    setValue("taille_marche", formData?.taille_marche);
    setValue("fraction_objectif", formData?.fraction_objectif);
    setValue("quand", formData?.quand);
    setValue("combien", formData?.combien);
    setValue("probleme", formData?.probleme);
    setValue("pourquoi_probleme", formData?.pourquoi_probleme);
    setValue("sauce_secrete", formData?.sauce_secrete);
    setValue("valeur", formData?.valeur);
  }, [formData, setValue]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [clearErrors, formState]);

  const onChange = (e) => {
    setValue("description", e.target.value);
    setDescription(e.target.value);
  };

  const onSubmit = async (data) => {
    await setFormData({ ...formData, ...data });
    // await console.log(data, formData);
    return navigation?.go("competition-information");
  };

  return (
    <>
      <div className="soumettre-projet">
        <div className="d-flex justify-content-center align-items-end se-financer pt-5">
          <h1 className="">Se financer</h1>
        </div>
        <section className="container informations">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="formulaire-de-depot-dossier"
          >
            <div className="form-group-container">
              <h3 className="form-title">1.Résumé</h3>
              <div className="d-flex flex-column justify-content-between flex-md-row">
                <div className="flex-fill mr-5">
                  <label htmlFor="campagneName">
                    Nom de l’entreprise<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Titre de projet"
                    id="campagneName"
                    name="title"
                    {...register("title")}
                    data-testid="titleId"
                  />
                  {formState?.errors && formState?.errors?.title && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState?.errors?.title?.message}
                    </div>
                  )}
                </div>
                <div className="mt-5 mt-md-0 flex-fill">
                  <label htmlFor="url">
                    Url de l'entreprise et ou de la demo
                  </label>
                  <input
                    type="url"
                    placeholder="Url"
                    name="url_demo"
                    id="url"
                    {...register("url_demo")}
                    data-testid="url_demoId"
                  />
                  {formState?.errors && formState?.errors.url_demo && (
                    <div
                      className="alert alert-danger gfa-alert-danger"
                      role="alert"
                    >
                      {formState?.errors?.url_demo?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group-container d-flex flex-column">
              <div className="form-textarea-group">
                <label htmlFor="companyDescription">
                  Décrivez ce que votre entreprise fait ou fera
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="description"
                  id="companyDescription"
                  cols="50"
                  rows="10"
                  // {...register("description")}
                  onChange={onChange}
                  data-testid="descriptionId"
                />
                <small>100 caractères maximum</small>
                <br />
                <span
                  className="description-length"
                  style={{
                    color: description?.length <= 100 ? "#115c67" : "#e54747",
                  }}
                >
                  {" "}
                  {description?.length
                    ? description?.length +
                      `${
                        description?.length > 1 ? " caractères" : " caractère"
                      }`
                    : null}{" "}
                </span>
              </div>

              {formState?.errors && formState?.errors?.description && (
                <div
                  className="alert alert-danger gfa-alert-danger"
                  role="alert"
                >
                  {formState?.errors?.description?.message}
                </div>
              )}
            </div>
            <div className="form-group-container d-flex flex-column">
              <h3 className="form-title">2. Marché</h3>
              <div className="form-textarea-group">
                <label htmlFor="marketSize">
                  Quelle est la taille de votre marché adressable (estimation à
                  la fois du nombre de clients et de la valeur) ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="marketSize"
                  id="marketSize"
                  cols="50"
                  rows="10"
                  {...register("taille_marche")}
                  data-testid="taille_marcheId"
                />
                {formState?.errors && formState?.errors?.taille_marche && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.taille_marche?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="marketFraction">
                  Quelle fraction de ce marché avez-vous déjà conquise (le cas
                  échéant) ? Objectif en 1 an ? dans 3 ans ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="marketFraction"
                  id="marketFraction"
                  cols="50"
                  rows="10"
                  {...register("fraction_objectif")}
                  data-testid="fraction_objectifId"
                />
                {formState?.errors && formState?.errors.fraction_objectif && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.fraction_objectif?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group-container">
              <h3 className="form-title">3. Où en êtes-vous ?</h3>
              <div className="form-textarea-group">
                <label htmlFor="startWork">
                  Quand avez-vous commencé à travailler sur votre entreprise ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="startWork"
                  id="startWork"
                  cols="50"
                  rows="10"
                  {...register("quand")}
                  data-testid="quandId"
                />
                {formState?.errors && formState?.errors.quand && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.quand?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="fullTime">
                  Combien êtes-vous à travailler à temps plein et depuis quand ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="fullTime"
                  id="fullTime"
                  cols="50"
                  rows="10"
                  {...register("combien")}
                  data-testid="combienId"
                />
                {formState?.errors && formState?.errors?.combien && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.combien?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group-container">
              <h3 className="form-title">4. Problème & solution</h3>
              <div className="form-textarea-group">
                <label htmlFor="problem">
                  Quel problème résolvez-vous précisément ? Décrivez à quel
                  point ce problème est impactant pour votre marché cible ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="problem"
                  id="problem"
                  cols="50"
                  rows="10"
                  {...register("probleme")}
                  data-testid="problemeId"
                />
                {formState?.errors && formState?.errors?.probleme && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.probleme?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="whySolvedProblem">
                  Pourquoi ce problème n’a-t-il pas encore été résolu ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="whySolvedProblem"
                  id="whySolvedProblem"
                  cols="50"
                  rows="10"
                  {...register("pourquoi_probleme")}
                  data-testid="pourquoi_problemeId"
                />
                {formState?.errors && formState?.errors?.pourquoi_probleme && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.pourquoi_probleme?.message}
                  </div>
                )}
              </div>
              <div className="form-textarea-group">
                <label htmlFor="whyTopSolution">
                  Pourquoi et en quoi votre solution est-elle unique et
                  supérieure ? Quel est votre « secret » pour réussir ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="whyTopSolution"
                  id="whyTopSolution"
                  cols="50"
                  rows="10"
                  {...register("sauce_secrete")}
                  data-testid="sauce_secreteId"
                />
                {formState?.errors && formState?.errors?.sauce_secrete && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.sauce_secrete?.message}
                  </div>
                )}
              </div>
              <div className="">
                <label htmlFor="advantages">
                  Décrivez quelle valeur et quels avantages tangibles vous
                  apportez à vos Clients ?<br />
                  Dans quelle mesure est-ce décisif pour eux ?
                  <span className="text-danger">*</span>
                </label>
                <textarea
                  name="advantages"
                  id="advantages"
                  cols="50"
                  rows="10"
                  {...register("valeur")}
                  data-testid="valeurId"
                />
                {formState?.errors && formState?.errors?.valeur && (
                  <div
                    className="alert alert-danger gfa-alert-danger"
                    role="alert"
                  >
                    {formState?.errors?.valeur?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={navigation?.previous}
                className="button-precedent"
              >
                PRECEDENT
              </button>
              <button
                // onClick={navigation?.next}
                type="submit"
                className="button-suivant"
                data-testid="btnId"
              >
                SUIVANT
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
};

export default InformationsSurLentreprise;
