import { FETCH_PROJECTS_IN_CAMPAIGN } from "../types";
export const projectInCampaignReducer = (
	state = { isLoading: true, data: [] },
	action
) => {
	switch (action.type) {
		case FETCH_PROJECTS_IN_CAMPAIGN:
			return {
				isLoading: false,
				data: action.payload,
				filteredData: action.payload,
			};
		default:
            return state;
	}
};
