import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./EditerMonProfile.css";
import editimg from "../../../images/others/default-profile-avatar.png";
// import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Utilisateur from "../../Services/Utilisateur";
import Swal from "sweetalert2";
import { fetchMe } from "../../../redux/user/userAction";
import baseUrl from "../../../Http/backend-base-url";
registerLocale("fr", fr);

const EditerMonProfile = () => {
	const user = useSelector((state) => state.user);
	const [startDate, setStartDate] = useState();
	const [MrIsActive, setMrIsActive] = useState(false);
	const [MmeIsActive, setMmeIsActive] = useState(false);
	const dispatch = useDispatch();
	const config = {
		headers: {
			Authorization: `Bearer ${window.sessionStorage.getItem(
				"userToken"
			)}`,
		},
	};
	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
		React.useState(false);
	const [avatar, setAvatar] = useState(null);
	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				email: yup
					.string()
					.email("Email invalide!")
					.required("L'email est requis."),
				prenom: yup.string().required("Le prenom est requis."),
				nom: yup.string().required("Le nom est requis."),
				sexe: yup.string().nullable(),
				pays: yup.string().nullable(),
				ville: yup.string().nullable(),
				rue: yup.string().nullable(),
				code_pastal: yup.string().nullable(),
				date_naissance: yup.string().nullable(),
			}),
		[]
	);

	const { register, handleSubmit, formState, reset, setValue } = useForm({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (user?.data?.id) {
			setValue("prenom", user?.data?.prenom);
			setValue("nom", user?.data?.nom);
			setValue("email", user?.data?.email);
			setValue("phone", user?.data?.phone);
			setValue("sexe", user?.data?.sexe);
			if (user?.data?.sexe === "homme") {
				setMrIsActive(true);
				setMmeIsActive(false);
			}

			if (user?.data?.sexe === "femme") {
				setMrIsActive(false);
				setMmeIsActive(true);
			}
			setValue("rue", user?.data?.rue);
			setValue("date_naissance", user?.data?.date_naissance);
			setValue("code_postal", user?.data?.code_postal);
			setValue("ville", user?.data?.ville);
			setValue("pays", user?.data?.pays);
			if (user?.data?.date_naissance) {
				setStartDate(new Date(user.data.date_naissance));
			}
		}

		return () => {
			reset();
		};
	}, [reset, setValue, user]);

	const handleChangeDate = (date) => {
		setValue("date_naissance", moment(date).format("YYYY-MM-DD"));
		setStartDate(date);
	};

	const handleChangeAvatar = (e) => {
		if (e.target.files[0]) {
			setAvatar(URL.createObjectURL(e.target.files[0]));
			setValue("avatar", e.target.files[0]);
		}
	};

	const onSubmit = async (data) => {
		// console.log("data", data);
		setIsSuccessfullySubmitted(true);
		const formData = new FormData();
		if (data.avatar) {
			formData.append("avatar", data.avatar);
		}
		formData.append("prenom", data.prenom);
		formData.append("nom", data.prenom);
		formData.append("email", data.email);
		formData.append("phone", data.phone);
		formData.append("sexe", data.sexe);
		formData.append("rue", data.rue);
		formData.append("ville", data.ville);
		formData.append("pays", data.pays);
		formData.append("date_naissance", data.date_naissance);
		formData.append("code_postal", data.code_postal);
		let request = Utilisateur.onEditUtilisateur(
			user.data.id,
			formData,
			config
		);

		await request
			.then(async (response) => {
				// console.log("response", response);
				setAvatar(null);
				await dispatch(fetchMe());
				await Swal.fire({
					icon: "success",
					title: "Modification réussie!",
					showConfirmButton: false,
					timer: 1500,
				});
				await setIsSuccessfullySubmitted(false);
			})
			.catch((error) => {
				setIsSuccessfullySubmitted(false);
				// console.log("error", error?.response);
				if (
					error?.response?.data?.email?.length &&
					error?.response?.data?.email[0] ===
						"user with this email already exists."
				) {
					//   toast.error("Email ou mot de passe incorrect.");
					Swal.fire({
						icon: "error",
						title: "Votre e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte ou vous connecter.",
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (
					error?.response?.data?.status === "failure"
				) {
					//   toast.error(error?.response?.data);
					Swal.fire({
						icon: "error",
						title: error?.response?.data?.message,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					//   toast.error("Veuillez vérifier votre connexion internet.");admin
					Swal.fire({
						icon: "error",
						title: "Veuillez vérifier votre connexion internet.",
						showConfirmButton: false,
						timer: 3000,
					});
				}
			});
	};

	const handleChange = (e) => {
		setValue("sexe", e.target.value);
		if (e.target.value === "homme") {
			setMrIsActive(true);
			setMmeIsActive(false);
		}
		if (e.target.value === "femme") {
			setMmeIsActive(true);
			setMrIsActive(false);
		}
	};

	return (
		<div className="containeredite pb-4">
			<div className="imgeditcontent">
				<div className="user-avatar-img-container">
					<img
						src={
							avatar
								? avatar
								: user?.data.avatar &&
								  !user?.data.avatar.includes(
										"default.png"
								  )
								? baseUrl + user.data.avatar
								: editimg
						}
						className="user-avatar-img "
						alt="avatar-user"
						id="avatar"
					/>
					<div className="overload">
						<label
							className="user-avatar-label"
							htmlFor="avatar"
						>
							Changer votre avatar
						</label>
						<input
							type="file"
							className="edit-avatar-btn"
							name="avatar"
							onChange={handleChangeAvatar}
							title=""
							accept="image/*"
						/>
					</div>
				</div>
			</div>
			<form
				className="container-cayore-form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="content-choix m-b-50">
					<div className="d-flex justify-content-start ">
						<p className="mr-5 align-self-baseline civilite">
							Civilité
						</p>
						<div className="civilite-btn-radio-container">
							<div
								className={
									MrIsActive
										? "civilite-btn-radio-item checked-civilite"
										: "civilite-btn-radio-item"
								}
								htmlFor="mme-civilite"
							>
								<input
									onChange={handleChange}
									type="radio"
									value="homme"
									name="sexe"
									id="mr-civilite"
									className="civilite-btn-radio"
								/>
								<label htmlFor="mr-civilite">
									Mr
								</label>
							</div>
							<div
								className={
									MmeIsActive
										? "civilite-btn-radio-item checked-civilite"
										: "civilite-btn-radio-item"
								}
							>
								<input
									onChange={handleChange}
									type="radio"
									value="femme"
									name="sexe"
									id="mme-civilite"
									className="civilite-btn-radio"
								/>
								<label htmlFor="mme-civilite">
									Mme
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-5 m-b-50">
						<div className=" d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Prénom*"
								{...register("prenom")}
							/>
							{formState.errors &&
								formState.errors.prenom && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.prenom
												?.message
										}
									</div>
								)}
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50">
						<div className=" d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Nom*"
								{...register("nom")}
							/>
							{formState.errors &&
								formState.errors.nom && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.nom
												?.message
										}
									</div>
								)}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-5 m-b-50">
						<div className=" d-flex flex-column">
							<input
								type="email"
								className=" inputformedit"
								placeholder="Email*"
								{...register("email")}
							/>
							{formState.errors &&
								formState.errors.email && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.email
												?.message
										}
									</div>
								)}
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50">
						<div className=" d-flex flex-column">
							<input
								type="tel"
								className=" inputformedit"
								placeholder="Téléphone*"
								{...register("phone")}
							/>
							{formState.errors &&
								formState.errors.phone && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.phone
												?.message
										}
									</div>
								)}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-5 m-b-50">
						<div className=" d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Pays"
								{...register("pays")}
							/>
							{formState.errors &&
								formState.errors.pays && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.pays
												?.message
										}
									</div>
								)}
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50">
						<div className=" d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Ville"
								{...register("ville")}
							/>
							{formState.errors &&
								formState.errors.ville && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.ville
												?.message
										}
									</div>
								)}
						</div>
					</div>
				</div>
				<div className="row ">
					<div className="col-12 col-md-5 m-b-50 ">
						<div className=" d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Rue"
								{...register("rue")}
							/>
							{formState.errors &&
								formState.errors.rue && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.rue
												?.message
										}
									</div>
								)}
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50 ">
						<div className=" d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Code postal"
								{...register("code_postal")}
							/>
							{formState.errors &&
								formState.errors
									.code_postal && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.code_postal
												?.message
										}
									</div>
								)}
						</div>
					</div>
				</div>
				<div className="row ">
					<div className="col-12 col-md-5 m-b-50 ">
						<div className=" d-flex flex-column">
							{/* <input
                type="date"
                className=" inputformedit"
                placeholder="Date de naissance"
                {...register("date_naissance")}
              /> */}
							<DatePicker
								className=" inputformedit"
								locale="fr"
								showYearDropdown
								dateFormat={"dd/MM/yyyy"}
								selected={startDate}
								onChange={(date) =>
									handleChangeDate(date)
								}
								placeholderText="Date de naissance"
							/>
							{formState.errors &&
								formState.errors
									.date_naissance && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											formState
												.errors
												.date_naissance
												?.message
										}
									</div>
								)}
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-end">
					{!isSuccessfullySubmitted && (
						<button
							className="btnformedits"
							type="submit"
						>
							ENREGISTRER
						</button>
					)}

					{isSuccessfullySubmitted && (
						<button
							className="btnformedits"
							type="submit"
						>
							En cours &nbsp;
							<i className="fas fa-spin fa-spinner"></i>
						</button>
					)}
				</div>
			</form>
		</div>
	);
};

export default EditerMonProfile;
