import React from 'react'
import microsoft from "../../../assets/images/microsoft.png";
import savechildren from "../../../assets/images/savechildren.png";
import conservation from "../../../assets/images/conservation.png";
import unicef from "../../../assets/images/unicef.png";
import riverisland from "../../../assets/images/riverisland.png";
import hellowallet from "../../../assets/images/hellowallet.png";
import americanredcross from "../../../assets/images/americanredcross.png";
import pallanmeran from "../../../assets/images/pallanmeran.png";

function Partenaire(props) {
  return (
    <section className="container-cayore partenaire-section-container">
          <div className="partenaire-card-content">
            <h1 className={`${props.stylePartenaire}`}>Nos partenaires</h1>
          </div>
          <div className="row partenaires-logos-row">
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={microsoft}
                  className="partenaire-logo"
                  alt="Microsoft"
                />
              </div>
            </div>
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={savechildren}
                  className="partenaire-logo"
                  alt="Save Children"
                />
              </div>
            </div>
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={conservation}
                  className="partenaire-logo"
                  alt="Conservation"
                />
              </div>
            </div>
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img src={unicef} className="partenaire-logo" alt="UNICEF" />
              </div>
            </div>
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={riverisland}
                  className="partenaire-logo"
                  alt="River Island"
                />
              </div>
            </div>
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={hellowallet}
                  className="partenaire-logo"
                  alt="Hellow Wallet"
                />
              </div>
            </div>
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={americanredcross}
                  className="partenaire-logo"
                  alt="American Red Cross"
                />
              </div>
            </div>
            <div className="col-md-3 partenaire-logo-col">
              <div className="partenaire-logo-container">
                <img
                  src={pallanmeran}
                  className="partenaire-logo"
                  alt="Pallan Meran"
                />
              </div>
            </div>
          </div>
        </section>
  )
}

export default Partenaire