const CalendrierRemboursementTableData = [
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
    {
        date: 'Juin 2021',
        echeance: '20 000',
        interet: '20 000',
        capital: '20000',
        en_cours: '20 000'
        
    },
];

export default CalendrierRemboursementTableData;
