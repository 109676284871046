// import api from '../Http/global-vars'
import { financements } from './Endpoints'
import axios from 'axios'


export default {
  async getFinancement(id, config) {
      const response = await axios.get(`${financements.onGetFinancement(id)}`, config);
      // console.log(response)
      return response.data;
  },
  async getAllFinancement(config) {
      const response = await axios.get(`${financements.onGetAllFinancement()}`, config);
      return response.data;
  },
  async editFinancement(id, data, config) {
      const response = await axios.put(`${financements.onPutFinancement(id)}`, data, config);
      // console.log(response)
      return response.data;
  },
  async deleteFinancement(id, config) {
      const response = await axios.delete(`${financements.onDeleteFinancement(id)}`, config);
      return response.data;
  },
  async postCreateFinancement(data,config) {
      const response = await axios.post(financements.onPostFinancement(), data,config);
      return response.data;
  },
}