import React, { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import * as IconlyPack from "react-iconly";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderV2 from "../Navs/HeaderV2";
import QuestionnaireForm from "../../../FrontendV2/Questionnaires/QuestionnaireForm";
import RemplireLeFormulaireDuDepot from "../../../FrontendV2/Questionnaires/RemplireLeFormulaireDuDepot";
import InformationsSurLentreprise from "../../../FrontendV2/Questionnaires/InformationsSurLentreprise";
import InformationsSurLaConcurence from "../../../FrontendV2/Questionnaires/InformationsSurLaConcurence";
import CreerUneDatePourLeLancement from "../../../FrontendV2/Questionnaires/CreerUneDatePourLeLancement";
import InformationsSuplementairesScumulativeFoundingentreprise from "../../../FrontendV2/Questionnaires/InformationsSuplementairesSurLentreprise";
import { Link } from "react-router-dom";

const steps = [
  {
    id: "questionnaire-form",
    Component: QuestionnaireForm,
  },
  {
    id: "commencer-remplire-formulaire",
    Component: RemplireLeFormulaireDuDepot,
  },
  { id: "company-information", Component: InformationsSurLentreprise },
  { id: "competition-information", Component: InformationsSurLaConcurence },
  {
    id: "additional-company-information", Component: InformationsSuplementairesScumulativeFoundingentreprise
  },
  { id: "create-launch-date", Component: CreerUneDatePourLeLancement },
];

const defaultData = {
  title: null,
  url_demo: null,
  description: null,
  taille_marche: null,
  fraction_objectif: null,
  quand: null,
  combien: null,
  probleme: null,
  pourquoi_probleme: null,
  sauce_secrete: null,
  valeur: null,
  produit_marche: null,
  revenus: null,
  evolutivite: null,
  concurrents: null,
  positionnement_strategique: null,
  avantage_concurrentiel: null,
  maintenance_avantage_concurrentiel: null,
  autres_commentaires: null,
  financement: null,
  fonds_supplementaires: null,
  date_lieu_societe: null,
  capitale: null,
  repartition_propriete: null,
  nombre_fondateurs: null,
  actionnarat: null,
  bio_fondateur: null,
  nom: null,
  prenom: null,
  telephone: null,
  lieu_connaissance: null,
  date_lancement: null,
  senegal: null,
  description_titre: null,
  site: null,
  objectif_financement: null,
  email: null,
  mode_financement: null,
  taux_entree: null,
  secteur: null,
  user: null,
  modele_intervention: null,
  strategie_sortie: null,
  instrument_financier: null,
  documents: null,
  duree_existance: null,
  produit_duree: null,
  besoin_evolutivite: null,
  fonds_supplementaires_prix: null,
  fonds_supplementaires_usage: null,
  revenu_dernier_mois: null,
  taux_dernier_mois: null,
  taux_croissance_dernier_mois: null,
  position: null,
  courriel: null,
  souscription_pallier: null,
  utilisateurs_actifs: null,
  taux: null,
  revenu_annuel_prevu: null,
  revenu_annuel_prevu_trois_ans: null,
  kpis: null,
  valeur_annuel_kpis: null,
  vitesse_kpis: null,
  besoins_pour_croitre: null,
  vitesse_croissance: null,
  otherSecteur: null,
};

const AjouterProjet = () => {
  const [formData, setFormData] = useState(defaultData);
  const { step, navigation, index } = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { formData, setFormData, navigation };

  useEffect(() => {
    // console.log("data", index);
    if (index !== 5) {
      window.scrollTo(0, 0);
    }
  }, [formData, index]);

	return (
    <div className="add-projet-container">
      <HeaderV2 activeLink={1} />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 active={1} />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content">
            <div className="add-projet-content">
              <div className="add-project-info-stepper row">
                <div className="add-project-info col-md-6">
                  <div className="add-project-info-icon-title-container">
                    {index > 0 ? (
                      <span
                        className="add-project-icon"
                        onClick={navigation?.previous}
                      >
                        <IconlyPack.ArrowLeft />
                      </span>
                    ): (
                      <Link to="/admin/gestion-des-projets">
                        <span
                          className="add-project-icon"
                        >
                          <IconlyPack.ArrowLeft />
                        </span>
                      </Link>
                    )}
                    <h1 className="add-project-general-title">
                      Ajouter un projet{" "}
                    </h1>
                  </div>
                </div>
                <div className="add-project-stepper col-md-6">
                  <div
                    className={`stepper ${index >= 0 && "stepper-active"}`}
                  ></div>
                  <div
                    className={`stepper ${index >= 1 && "stepper-active"}`}
                  ></div>
                  <div
                    className={`stepper ${index >= 2 && "stepper-active"}`}
                  ></div>
                  <div
                    className={`stepper ${index >= 3 && "stepper-active"}`}
                  ></div>
                  <div
                    className={`stepper ${index >= 4 && "stepper-active"}`}
                  ></div>
                  <div
                    className={`stepper ${index === 5 && "stepper-active"}`}
                  ></div>
                </div>
              </div>
              <div className="ajoutprojet">
                <Component {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterProjet;
