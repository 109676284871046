import React from "react";
import "./ParametreAdmin.css";
import { Delete, EditSquare, TickSquare } from "react-iconly";
import "react-responsive-modal/styles.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "./ParametreAdmin.css";
import { useDispatch, useSelector } from "react-redux";
import CRUDSecteurModal from "../../../Modals/CRUDSecteurModal";
import Swal from "sweetalert2";
import { fetchSecteurs } from "../../../../redux/secteurs/secteurAction";

import Axios from "axios";
import api from "../../../../Http/global-vars";

const ListSecteur = () => {
  const secteurs = useSelector((state) => state.secteurs);
  const dispatch = useDispatch();
  const options = {
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };
  // console.log(secteurs);

  const onDelete = (item) => {
    let token = window.sessionStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: `Êtes vous sûr de vouloir supprimer ce secteur?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#115c67",
      cancelButtonColor: "#E53E3E",
      preConfirm: () => {
        return Axios.delete(
          `${api}secteur/${item?.id}/`,
          config
        )
          .then(async (response) => {
            // console.log("res", response);
            await dispatch(fetchSecteurs());
            return response;
          })
          .catch((error) => {
            // console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `Secteur supprimé avec succès.`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="parametre-actions-container">

        <button
          style={{ color: "#ACABAB", backgroundColor: "transparent" }}
          title="Modifier"
        >
          <EditSquare
            set="bold"
            primaryColor="#ACABAB"
            style={{ fontSize: "16px", width: "20px", marginLeft: " 10px" }}
            data-toggle="modal"
            data-target={`#editunSecteur${row?.id}`}
          />
        </button>

          <button
            style={{ color: "#ACABAB", backgroundColor: "transparent" }}
            className="mr-2"
            title="Désactiver"
            onClick={() => onDelete(row)}
          >
            <Delete
              set="light"
              primarycolor="#ACABAB"
              style={{ fontSize: "20px" }}
            />
          </button>

        <div
          className="modal fade"
          id={`editunSecteur${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="editunSecteur"
          aria-hidden="true"
        >
          {" "}
          <CRUDSecteurModal secteur={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="infos-perso-input">
        <BootstrapTable
          data={secteurs.data ? secteurs.data : ""}
          striped
          hover
          condensed
          search={false}
          version="4"
          bordered={false}
          options={options}
        >
          {/* Column for "Secteur d'activité" */}
          <TableHeaderColumn
          bordered={false}
          thStyle={{
            fontWeight: 600,
            fontSize: 18,
            color: "#115C67",
          }}
          tdStyle={{
            fontWeight: 500,
            fontSize: 16,
            color: "rgba(0, 0, 0, 0.7)",
            padding: 20,
          }}
          dataField="name"
          isKey={true} 
          width="200"
        >
          Nom du secteur
        </TableHeaderColumn>

          {/* Column for "Actions" */}
          <TableHeaderColumn
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 18,
              color: "#115C67",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.7)",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
            width="100"
            dataFormat={(cell, row) => actionFormatter(cell, row)}
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>
    </div>
  );
};

export default ListSecteur;
