import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import ListeDocumentsSkeleton from "./ListeDocumentsSkeleton";
import { parseISO, format } from "date-fns";

const ListeDocuments = (props) => {
	const { isLoading, project} = props || {};
	const [isPageLoading, setIsPageLoading] = useState(true);
	const [documents, setDocuments] = useState([]);

	useEffect(() => {
		if (isLoading) {
			const timeoutId = setTimeout(() => {
				setIsPageLoading(false);
			}, 10000);
			return () => clearTimeout(timeoutId);
			
		} else{
			setIsPageLoading(false)
			setDocuments(project.files);
		}
	}, [isLoading, project]);
	// console.log(isPageLoading);

	const options = {
		clearSearch: true,
		noDataText: "Aucune donnée disponible",
	};

	const nameFormatter = (cell, row) => {
		return (
		  <div className="d-flex align-items-center">
			<IconlyPack.Document style={{ color: '#6B92DC' }} set="bold" />
			<h6 className="ml-2">	
			  <a href={`https://api-easy-funding.fewnu.app${row.doc}`} target="_blank" rel="noopener noreferrer">
				{cell}
			  </a>
			</h6>
		  </div>
		);
	  };
	  

	const actionFormatter = (cell, row) => {
		return (
			<div className="table-actions-container">
				<NavLink
					to="/accueil"
					style={{ color: "#2D3748" }}
					className="mr-3"
				>
					<IconlyPack.Edit style={{ fontSize: "20px" }} />
				</NavLink>
				<NavLink
					to="/accueil"
					style={{ color: "#E53E3E" }}
					className=""
				>
					<IconlyPack.Delete style={{ fontSize: "20px" }} set="bold"/>
				</NavLink>
			</div>
		);
	};


	return (
		<div className="child-table-container mt-5">
			<div className="table-body-container-documents">
				<div className="row easypm-table-row">
					<div className="col-md-12 easypm-table-col">
						{isPageLoading && <ListeDocumentsSkeleton  />}
						{!isPageLoading && (
							<div className="table-container table-responsive-sm">
								<BootstrapTable
									data={documents ? documents : ""}
									hover={true}
									condensed={true}
									multiColumnSort={2}
									options={options}
									search={false}
									version="4"
									bordered={false}
									
								>
									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 12,
											color: "#A0AEC0",
											fontFamily:
												"Poppins",
										}}
										tdStyle={{
											fontWeight:
												"normal",
											fontSize: 14,
											color: "#2d3748",
											fontFamily:
												"Poppins",
										}}
										dataField="id"
										width="1"
										isKey
									></TableHeaderColumn>
									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 12,
											color: "#A0AEC0",
											fontFamily:
												"Poppins",
										}}
										tdStyle={{
											fontWeight:
												"normal",
											fontSize: 14,
											color: "#2d3748",
											fontFamily:
												"Poppins",
										}}
										width="100"
										dataField="name"
										dataFormat={(cell, row) => nameFormatter(cell, row)}
									>
										Nom
									</TableHeaderColumn>
									<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 12,
											color: "#A0AEC0",
											fontFamily:
												"Poppins",
										}}
										tdStyle={{
											fontWeight:
												"normal",
											fontSize: 14,
											color: "#2d3748",
											fontFamily:
												"Poppins",
										}}
										width="50"
										dataField="created_at"
										dataFormat={(cell, row) => format(parseISO(cell), "yyyy/MM/dd")}
									>
									Date
									</TableHeaderColumn>
									{/*<TableHeaderColumn
										thStyle={{
											fontWeight: 600,
											fontSize: 12,
											color: "#A0AEC0",
											fontFamily:
												"Poppins",
										}}
										tdStyle={{
											fontWeight:
												"normal",
											fontSize: 14,
											color: "#2d3748",
											fontFamily:
												"Poppins",
										}}
										width="50"
										dataFormat={(cell, row) => actionFormatter(cell, row)}
									>
									Actions
									</TableHeaderColumn>*/}
								</BootstrapTable>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListeDocuments;
