import React, { Component }  from "react";
import "../../../BackendV2/Admin/NavbarV2/NavbarV2.css";
import SidebarContentV2 from "./SidebarContentV2";

  export default class SidebarTableauBord extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
      const {active} = this.props
  
      return (
        <div className="navbar-vertical-customisation-v2 no-view-mobile">
          <SidebarContentV2 active={active}/>
        </div>
      );
    }
  }
