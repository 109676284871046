import React from "react";
import "./Apropos.css";
import frame from '../../../images/icons/Frame 427318888.png';

const CircularProgress = () => {
  return (
      <div className="m-3">
        <div className="dessin-container">
          <div className="dessin-circle">
            <img src={frame} alt="img" className="w-100 PictureFrame"/>
          </div>
        </div>
      </div>
  );
};

export default CircularProgress;
