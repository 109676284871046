import React from "react"
import FrontInscription from "../FrontInscription/FrontInscription";

function DetailProjectInscription() {
  return (
    <div>
        <FrontInscription />
    </div>
  )
}

export default DetailProjectInscription