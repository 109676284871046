import React from "react";
import { useNavigate  } from "react-router-dom";

const ModalConfirmationIdentite = () => {
  const navigate = useNavigate ()

	return (
    <div
      className="modal fade"
      id="modal-de-soumission"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content px-5 pt-4 pb-5">
          <div className="d-flex justify-content-end pt-4">
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              type="button"
              data-dismiss="modal"
            >
              <path
                d="M13.9844 1.92188L8.40625 7.5L13.9844 13.0781L12.5781 14.4844L7 8.90625L1.42188 14.4844L0.015625 13.0781L5.59375 7.5L0.015625 1.92188L1.42188 0.515625L7 6.09375L12.5781 0.515625L13.9844 1.92188Z"
                fill="#666666"
              />
            </svg>
          </div>

          <div className="mt-5">
            <h5 className="title-verification marge-identite">
              Votre identité a été complétée
            </h5>
            <div className="mt-5 pt-3">
              <p className="paragraph-mail-info">
                Maintenant configurez votre portefeuille qui sera utilisée pour
                toutes vos transactions(débits, crédits, remboursements) sur la
                plateforme
              </p>
            </div>

            <div className="row justify-content-end mb-5 pr-2 mt-5">
              <div className="col-xl-3 col-lg-3 col-md-3">
                <button
                  data-dismiss="modal"
                  type="button"
                  className="btn btn-theme-annuler"
                >
                  Annuler
                </button>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3">
                <button
                  data-dismiss="modal"
                  onClick={() => navigate("/documents-inscription")}
                  className="btn btn-theme-inscription"
                >
                  Continuer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmationIdentite;
