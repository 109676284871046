import React from "react";
import Skeleton from "react-loading-skeleton";

const ListeDonnateurSkeleton = () => {
	return (
		<div className="detail-projet-finance-data-donneur-container">
			<div className="detail-projet-finance-data-donneur-avatar-info">
				<div className="detail-projet-finance-data-donneur-image-container">
					<Skeleton
						circle="true"
						style={{
							borderRadius: "100%",
							width: "50px",
							height: "50px",
						}}
					/>
				</div>
				<div className="detail-projet-finance-data-donneur-info">
					<h2 className="detail-projet-finance-data-donneur-name" >
						<Skeleton style={{width: "100px"}}/>
					</h2>
					<h2 className="detail-projet-finance-data-donneur-date-donnation">
						<Skeleton style={{width: "100px"}}/>
					</h2>
				</div>
			</div>
			<div className="detail-projet-finance-data-donneur-montant-donnation">
				<h6 className="detail-projet-finance-data-donneur-montant-donnation">
					<Skeleton style={{width: "100px"}}/>
				</h6>
			</div>
		</div>
	);
};

export default ListeDonnateurSkeleton;
