import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Lock, Password } from "react-iconly";
import { useNavigate , useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import AuthServices from "../../Services/AuthServices";
import "./ForgetPassword.css";
const ResetPassword = () => {
	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
		React.useState(false);
	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				code: yup
					.number("le code est un entier")
					.required("Le code est réquis"),
				new_password: yup
					.string()
					.required("Le mot de passe est requis.")
					.min(
						8,
						"Le mot de passe doit contenir au minimum 8 caractères"
					)
					.matches(
						/[a-z]+/,
						"Le mot de passe doit contenir au minimum une minuscule"
					)
					.matches(
						/[A-Z]+/,
						"Le mot de passe doit contenir au minimum une majuscule"
					)
					.matches(
						/[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
						"Le mot de passe doit contenir au minimum un caractère spécial"
					)
					.matches(
						/\d+/,
						"Le mot de passe doit contenir au minimum un chiffre"
					),
				new_password_confirm: yup
					.string()
					.oneOf(
						[yup.ref("new_password"), null],
						"Veuillez entrer des mots de passe identiques."
					)
					.required(
						"Veuillez confirmer votre mot de passe"
					),
			}),
		[]
	);

	const location = useLocation();
	const navigate  = useNavigate ();

	const { register, handleSubmit, formState, clearErrors, setValue } =
		useForm({
			resolver: yupResolver(validationSchema),
		});

	useEffect(() => {
		if (formState.errors) {
			setTimeout(() => {
				Object.entries(formState.errors).map(([key]) => {
					return clearErrors(key);
				});
			}, 3000);
		}
	}, [clearErrors, formState]);

	useEffect(() => {
		if (location.email) {
			setValue("email", location.email.email);
		}
		else {
			navigate("/request-reset-password")
		}
	});
	const onSubmit = async (data) => {
		setIsSuccessfullySubmitted(true)
		// console.log(data);
		let request = AuthServices.onResetPassword(data);

		await request
			.then(async (response) => {
				// console.log(response);
				setIsSuccessfullySubmitted(false)
				if(response?.data.message ==="item successfully saved" && response?.data.status === "success"){
					await Swal.fire({
						icon: "success",
						html: "<h4>Votre mot de passe a été modifié avec success</h4>",
						timer: 3000,
						showConfirmButton: false,
                       
					}).then(() => navigate("/connexion"))
				}
			})
			.catch((error) => {
				// console.log(error);
				setIsSuccessfullySubmitted(false)
				if(error.status === "failure" && error.message === "invalid data") {
					Swal.fire({
						icon: "error",
						html: "<h4>Données invalides</h4>",
						timer: 3000,
						showConfirmButton: false,
                       
					})
				}
			});
	};
	return (
		<div className="auth-component auth-forget-component">
			<div className="container-cayore auth-page-container">
				<div className="row auth-page-row">
					<div className="col-md-6 auth-section left-side">
						<div className="lefty-empty-content"></div>
					</div>

					<div className="col-md-6 auth-section right-side">
						<div className="auth-form-container">
							<h5 className="auth-form-forget-title">
								Modification de mot de passe
							</h5>
							<div className="container-cayore mb-5">
								<p className="auth-form-text">
									Créer un nouveau mot de
									passe
								</p>
							</div>
							<form
								id="login-form"
								onSubmit={handleSubmit(
									onSubmit
								)}
							>
								<div className="row auth-form-content">
									<div className="col-md-12">
										<div className="input-group auth-form-group">
											<div className="input-group-prepend">
												<span
													className="input-group-text span-input-icon"
													id="basic-addon1"
												>
													<Password
														set="bold"
														primaryColor="#C4C4C4"
														className="auth-icon"
													/>
												</span>
											</div>
											<input
												name="code"
												id="code"
												type="text"
												className="form-control cv-input-with-icon inputField"
												placeholder="Code"
												data-testid="codeId"
												{...register(
													"code"
												)}
											/>
											{formState.errors &&
												formState
													.errors
													.code && (
													<div
														className="alert alert-danger gfa-alert-danger"
														role="alert"
													>
														{
															formState
																.errors
																.code
																?.message
														}
													</div>
												)}
										</div>
										<div className="input-group auth-form-group">
											<div className="input-group-prepend">
												<span
													className="input-group-text span-input-icon"
													id="basic-addon1"
												>
													<Lock
														set="bold"
														primaryColor="#C4C4C4"
														className="auth-icon"
													/>
												</span>
											</div>
											<input
												name="newPassword"
												id="newPassword"
												type="password"
												className="form-control cv-input-with-icon inputField"
												placeholder="Entrer un nouveau mot de passe"
												data-testid="newPasswordId"
												{...register(
													"new_password"
												)}
											/>
											{formState.errors &&
												formState
													.errors
													.new_password && (
													<div
														className="alert alert-danger gfa-alert-danger"
														role="alert"
													>
														{
															formState
																.errors
																.new_password
																?.message
														}
													</div>
												)}
										</div>
										<div className="input-group auth-form-group">
											<div className="input-group-prepend">
												<span
													className="input-group-text span-input-icon"
													id="basic-addon1"
												>
													<Lock
														set="bold"
														primaryColor="#C4C4C4"
														className="auth-icon"
													/>
												</span>
											</div>
											<input
												name="confirmPassword"
												id="confirmPassword"
												type="password"
												className="form-control cv-input-with-icon inputField"
												placeholder="Confirmer le mot de passe"
												data-testid="confirmPasswordId"
												{...register(
													"new_password_confirm"
												)}
											/>
											{formState.errors &&
												formState
													.errors
													.new_password_confirm && (
													<div
														className="alert alert-danger gfa-alert-danger"
														role="alert"
													>
														{
															formState
																.errors
																.new_password_confirm
																?.message
														}
													</div>
												)}
										</div>
									</div>
								</div>
								<div className="row auth-submit-btn-container">
									<div className="col-md-6 offset-md-3 auth-submit-btn-content">
										{!isSuccessfullySubmitted ? (
											<button
												type="submit"
												data-testid="btnId"
												className="auth-submit-btn btn btn-success"
											>
												ENVOYER
											</button>
										) : (
											<button className="auth-submit-btn btn btn-success in-progress-btn">
												En cours
												&nbsp;
												<i className="fas fa-spin fa-spinner"></i>
											</button>
										)}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
