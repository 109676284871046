import React from "react";
import "./Progression.css";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import TabsLinkProjets from "../TabsLinkProjets/TabsLinkProjets";
import LineChartApp from "./LineChartApp/LineChartApp";
import FrontHeaderV2 from "../../FrontHeaderV2/FrontHeaderV2";

const Progression = () => {
  return (
    <div className="component-mes-projet-tableau-de-bord">
      <FrontHeaderV2 />
      <TabsLinkProjets />

      <div className="mt-3 row p-0">
        <div className="col-lg-4 col-xl-3">
          <SidebarTableauDeBord value={2} />
        </div>
        <div className="col-lg-8 col-xl-9">
          <div className="component-front-home">
            <div className="mes-projets">
              <div className="graphText">
                <div className="graphPara">
                  <p>
                    Evolution du nombre de contribution, de tuteurs et de <br />
                    sponsors ( par semaine )
                  </p>
                </div>
                <div className="col-md-4 offset-xl-2 offset-md-1 mb-2 selection-progression">
                  <select
                    className="form-select form-select-line-chart ml-md-auto"
                    aria-label="Default select example"
                  >
                    <option value="">Janvier</option>
                    <option value="1">Semaine du 07/01/2021</option>
                    <option value="2">Semaine du 14/01/2021</option>
                    <option value="3">Semaine du 21/01/2021</option>
                  </select>
                </div>
              </div>
              <LineChartApp />
            </div>
          </div>
        </div>
      </div>
      <FrontFooterV2 />
    </div>
  );
};

export default Progression;
