import { Invest } from "./Endpoints";
import axios from "axios";

export default {
  async onGetListInvest(config) {
    const response = await axios.get(Invest.onGetListInvest(), config);
    console.log(response)
    return response.data;
  },
  async onEditInvest(id, data, config) { 
    const response = await axios.put(Invest.onEditInvest(id), data, config);
    console.log(response)
    return response.data;
  },
  async onPostInvest(data, config) {
    const response = await axios.post(Invest.onPostInvest(), data, config);
    console.log(response)
    return response.data;
  },
  async onDeleteInvest(id, config) {
    const response = await axios.delete(Invest.onDeleteInvest(id), config);
    // console.log(response)
    return response.data;
  },
  
};
