import React from "react";

const DureeExistence = ({ formData, setValue, formState, userType }) => {
	return (
    <div className="quest-pp-block">
      <h3 className="title-questionnaire">
        {userType === "admin" ? "3. La durée d'existance de l'entreprise": "3. Depuis combien de temps existez-vous ?"}
        <span className="text-danger">*</span>
      </h3>
      <div className="form-group">
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input"
            id="moins_1_an"
            value="Moins de 1 an"
            defaultChecked={formData?.duree_existance === "Moins de 1 an"}
            name="duree_existance"
            data-testid="duree_existanceId"
            onChange={(e) => setValue("duree_existance", e.target.value)}
          />
          <label className="quest-label" htmlFor="moins_1_an">
            Moins de 1 an
          </label>
        </div>
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input"
            id="entre_1_et_3"
            value="Entre 1 et 3 ans"
            defaultChecked={formData?.duree_existance === "Entre 1 et 3 ans"}
            name="duree_existance"
            data-testid="duree_existanceId"
            // {...register("duree_existance")}
            onChange={(e) => setValue("duree_existance", e.target.value)}
          />
          <label className="quest-label" htmlFor="entre_1_et_3">
            Entre 1 et 3 ans
          </label>
        </div>
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input"
            id="plus_3"
            value="Plus de 3 ans"
            defaultChecked={formData?.duree_existance === "Plus de 3 ans"}
            name="duree_existance"
            data-testid="duree_existanceId"
            // {...register("duree_existance")}
            onChange={(e) => setValue("duree_existance", e.target.value)}
          />
          <label className="quest-label" htmlFor="plus_3">
            Plus de 3 ans
          </label>
        </div>
      </div>
      {formState.errors && formState.errors.duree_existance && (
        <div className="alert alert-danger gfa-alert-danger" role="alert">
          {formState.errors.duree_existance?.message}
        </div>
      )}
    </div>
  );
};

export default DureeExistence;
