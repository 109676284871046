/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import UseCreateAdminForm from "../BackendV2/Admin/Parametre/request/UseCreateAdminForm";

function Ajouterunadministrateurmodal() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
  } = UseCreateAdminForm();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleReset = () => {
    reset();
  };

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg ">
      <div className="profile-container-photo modal-content">
        <div className="modal-header">
          <h5 className="modal-ajouter" id="ajouterunadministrateur">
            Ajouter un administrateur
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="container-input-address">
            <div className="row">
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Prénom</label>
                  <input
                    type="text"
                    className="form-control-profil"
                    name="prenom"
                    placeholder="Prénom"
                    {...register("prenom")}
                  />
                  {formState?.errors && formState?.errors?.prenom && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.prenom?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Nom</label>
                  <input
                    type="text"
                    className="form-control-profil"
                    name="nom"
                    placeholder="Nom"
                    {...register("nom")}
                  />
                  {formState?.errors && formState?.errors?.nom && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.nom?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Telephone</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control-profil"
                    placeholder="Téléphone"
                    {...register("phone")}
                  />
                  {formState?.errors && formState?.errors?.phone && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.phone?.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="infos-perso-input">
                  <label className="form-label-text">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control-profil"
                    placeholder="Email"
                    {...register("email")}
                  />
                  {formState?.errors && formState?.errors?.email && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.email?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-end flex-column">
            <div className="ms-auto">
              {isSuccessfullySubmitted && (
                <button className="btn-modifier" disabled>
                  En cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isSuccessfullySubmitted && (
                <button className="btn-modifier">Enregistrer</button>
              )}
            </div>
          </div>
        </form>{" "}
      </div>
    </div>
  );
}

export default Ajouterunadministrateurmodal;
