import React from "react";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import "./Projets.css";

import ProjetTableauDeBord from "../../../Tables/ProjetTableauDeBord/ProjetTableauDeBord";
import TabsLinkProjets from "../TabsLinkProjets/TabsLinkProjets";
import FrontHeaderV2 from "../../FrontHeaderV2/FrontHeaderV2";

const Projets = () => {
  return (
    <div className="component-mes-projet-tableau-de-bord">
      <FrontHeaderV2 />

      <TabsLinkProjets />
      <div className="mt-3 row p-0">
        <div className="col-lg-4 col-xl-3">
          <SidebarTableauDeBord value={2} />
        </div>
        <div className="col-lg-8 col-xl-9">
          <div className="projets">
            <ProjetTableauDeBord />
          </div>
        </div>
      </div>
      <FrontFooterV2 />
    </div>
  );
};

export default Projets;
