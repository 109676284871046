import { Utilisateur } from './Endpoints'
import axios from 'axios'

export default {
    async onGetListUtilisateur(config) {
        const response = await axios.get(Utilisateur.onGetListUtilisateur(), config);
        // console.log(response)
        return response.data;
    },
    async onAddUtilisateur(data,config) {
        const response = await axios.post(Utilisateur.onPostUtilisateur(),data,config );
        // console.log(response)
        return response.data;
    },
    async onEditUtilisateur(id,data,config) {
        const response = await axios.put(Utilisateur.onEditUtilisateur(id),data,config );
        // console.log(response)
        return response.data;
    },

    async onDeleteUtilisateur(id,config) {
        const response = await axios.delete(Utilisateur.onDeleteUtilisateur(id),config);
        // console.log(response)
        return response.data;
    },

}