import React from "react";
import "./HeaderTableauDeBord.css";
import { Link, NavLink } from "react-router-dom";
import { User } from "react-iconly";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Profile from "../../../../images/profile.png";

const HeaderTableauDeBord = (props) => {
  var navigate = useNavigate();
  const user = useSelector((state) => state.user);

  var logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("userToken");
    window.sessionStorage.clear();
    if (sessionStorage.removeItem("userType") === "admin") {
      setTimeout(() => {
        //window.location.href = '/admin-login'
        navigate("/");
      }, 1000);
    } else {
      setTimeout(() => {
        // window.location.href = '/app/connexion'
        navigate("/");
      }, 1000);
    }
  };
  var data = window.sessionStorage.getItem("userType");
  //   console.log("daadadada", data);
  return (
    <div className="component-front-header">
      <div className="header-scrolling-container fixed-top" id="frontHeader">
        <nav className="navbar navbar-expand-lg  container-aem navbar-light header py-4">
          <a className="navbar-brand" href="/">
            <div className="logo-item">
              <span className="logo-firsty">Cayor</span>
              <span className="logo-secondy">Ventures</span>
            </div>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            datatoggle="collapse"
            data-target="#navbarHeaderSecondary"
            aria-controls="navbarHeaderSecondary"
            ariaexpanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarHeaderSecondary">
            <ul className="navbar-nav mx-auto py-lg-0 py-4">
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink exact className="nav-link trans-0-2" to="/accueil">
                  Accueil
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink className="nav-link trans-0-2" to="/investir">
                  Investir
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink className="nav-link trans-0-2" to="/se-financer">
                  Se financer
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink className="nav-link trans-0-2" to="/liste-des-projets">
                  Les projets
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink className="nav-link trans-0-2" to="/comment-ca-marche">
                  Comment ça marche ?
                </NavLink>
              </li>
              <li className="nav-item nav-african-hot-jobs px-lg-2">
                <NavLink className="nav-link trans-0-2" to="/a-propos-de-nous">
                  À propos de nous
                </NavLink>
              </li>
            </ul>
            {window.sessionStorage.getItem("userType") ? (
              <div className="">
                {/* A afficher si l'utilisateur n'est pas connecté */}
                {/* <NavLink className="link-header-login trans-0-2 d-block" to="/login" >Connexion</NavLink> */}

                {/* A afficher si l'utilisateur est connecté */}
                <div className="dropdown connexion-dropdown">
                  <button
                    className="dropdown-toggle trans-0-2"
                    // href="user"
                    style={{ background: "transparent" }}
                    id="connexionDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="name-user-connect">
                      {user?.data?.id &&
                        user?.data?.prenom + " " + user?.data?.nom}
                    </span>
                    <span className="icon image-responsive mx-2 p-2 ">
                      <User set="bold" primaryColor="#115c67" />
                    </span>
                  </button>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="connexionDropdown"
                  >
                    <NavLink
                      className="dropdown-item no-bg border-bottom"
                      to="/app/tableau-de-bord-porteur-projet"
                    >
                      Compte
                    </NavLink>
                    {data === "investisseur" ? (
                      <NavLink
                        className="dropdown-item no-bg border-bottom"
                        to="/app/tableau-de-bord"
                      >
                        Mon tableau de board
                      </NavLink>
                    ) : (
                      " "
                    )}
                    <NavLink
                      className="dropdown-item no-bg border-bottom"
                      to="/app/inscription-information-personnelle"
                    >
                      Informations Personnelles
                    </NavLink>
                    <NavLink
                      className="dropdown-item no-bg border-bottom"
                      to="/app/modifier-mot-de-passe-porteur-projet"
                    >
                      Changer Mon Mot de Passe
                    </NavLink>
                    <NavLink
                      className="dropdown-item no-bg"
                      to=""
                      onClick={(e) => logout(e)}
                    >
                      Deconnexion
                    </NavLink>
                  </div>
                </div>

                <div className="d-md-none d-inline-block mt-4 language-selector-mobile"></div>
              </div>
            ) : (
              <div className="d-flex">
                <Link to="/" className="link d-flex align-items-center">
                  <h4 className="profileName mr-3 d-lg-none d-xl-block">
                    Ansou Bodian
                  </h4>
                  <img
                    src={Profile}
                    alt="avatar"
                    style={{ borderRadius: "8px" }}
                    className="mr-1"
                  />
                  <svg
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.77149 4.77116L7.74854 1.79411C7.93017 1.61247 7.93017 1.31789 7.74854 1.13626C7.56686 0.954581 7.27236 0.954581 7.09068 1.13626L4.90773 3.31921C4.90773 3.31921 4.68806 3.56522 4.44434 3.56006C4.20647 3.55502 3.9774 3.31921 3.9774 3.31921L1.79444 1.13633C1.61277 0.954656 1.31826 0.954656 1.13659 1.13633C1.04582 1.22713 1.00035 1.34621 1.00035 1.46526C1.00035 1.5843 1.04582 1.70335 1.13659 1.79418L4.11364 4.77116C4.29531 4.95283 4.58981 4.95283 4.77149 4.77116Z"
                      fill="#0C0B0B"
                      stroke="#0C0B0B"
                      strokeWidth="0.5"
                    />
                  </svg>
                </Link>
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default HeaderTableauDeBord;
