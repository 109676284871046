import React, { useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import HistoriquePretTableData from "./HistoriquePretTableData";
import HistoriquePretTableSkeleton from "./HistoriquePretTableSkeleton";

const HistoriquePretTable = (props) => {
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  // eslint-disable-next-line
  var [projet, setProjet] = React.useState(HistoriquePretTableData);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 7000);
  }, [projet]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

 



  return (
    <div className="child-table-container">
      <div className="table-body-container border-none">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <HistoriquePretTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={projet}
                  striped={true}
                  hover={true}
                  condensed={false}
                  multiColumnSort={2}
                  options={options}
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    dataField="id"
                    isKey
                    hidden="true"
                    className="data-center-table-info"
                  ></TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="projet"
                    className="data-center-table-info"
                  >
                    Projets
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="score"
                    className="data-center-table-info"
                  >
                    Score
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="taux"
                    className="data-center-table-info"
                  >
                    Taux
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="prete"
                  >
                    Prêtés
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="percu"
                    className="data-center-table-info"
                  >
                    Perçus
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="provision"
                    className="data-center-table-info"
                  >
                    Provisions
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="en_cours"
                  >
                    En cours
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoriquePretTable;
