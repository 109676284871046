import React from 'react'
import InfoPorteurProject from '../../../Tables/backend/ListeInfoPorteur/InfoPorteurProject'

function DetailProjectInfo(props) {
  return (
    <div className={`w-100 ${props.fade}`} id='pills-info' role="tabpanel"
    aria-labelledby="pills-info">
        <InfoPorteurProject project={props.project} />
    </div>
  )
}

export default DetailProjectInfo