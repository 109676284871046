import React, { useState, useEffect } from "react";
import ChoisirModePaiement from "./ChoisirModePaiement";
import ChoisirMontant from "./ChoisirMontant";
import { useStep } from "react-hooks-helper";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import ProjectCardPaie from "./ProjectCardPaie";
import { useParams } from "react-router-dom";

const steps = [
  { id: "mode-paiement", Component: ChoisirModePaiement },
  { id: "choisir-montant", Component: ChoisirMontant },
];

const defaultData = {
  montant: null,
  moyen_paiement: null,
};

const AlimenterSonCompte = () => {
  const [formData, setFormData] = useState(defaultData);
  const { step, navigation, index } = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { formData, setFormData, navigation };
  const { slug } = useParams();
  console.log(slug)
  useEffect(() => {
    if (index !== 2) {
      window.scrollTo(0, 0);
    }
  }, [formData, index]);

  return (
    <div>
      <FrontHeaderV2 active={2} />
      <div className="alimenter-son-compte d-flex">
        <ProjectCardPaie {...props} />
        <div className="alimenter-son-compte-element col-md-6">
          <Component {...props} />
        </div>
      </div>
      <FrontFooterV2 />
    </div>
  );
};

export default AlimenterSonCompte;


