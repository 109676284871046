import React, { } from "react";
import "../../../BackendV2/Admin/NavbarContent/NavbarContent.css";
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";

const SidebarContentV2 = ({active}) => {
	return (
		<div className="hoverable" id="scrollbar">
			<div className="contents">
			<ul className="nav nav-items-customs">
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className={`nav-link nav-link-vertival-custom  ${
                active === 1 && "active"
              }`}
              to="/dashboard-investisseur"
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.Chart set="bold" className="icon-navbar-vertical" />
              </span>
              <span className="text-dashbord">Mon tableau de bord</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard-investisseur/profil"
              
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.Paper set="bold" className="icon-navbar-vertical" />
              </span>
              <span className="text-dashbord">Mon profil</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard-investisseur/resume"
              
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.People
                  set="bold"
                  className="icon-navbar-vertical"
                />
              </span>
              <span className="text-dashbord">Projets financés</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard-investisseur/prets"
              
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.People
                  set="bold"
                  className="icon-navbar-vertical"
                />
              </span>
              <span className="text-dashbord">Prêts</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard-investisseur/transactions"
              
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.Chat set="bold" className="icon-navbar-vertical" />
              </span>
              <span className="text-dashbord">Transactions</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard-investisseur/mon-compte"
              
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.Message
                  set="bold"
                  className="icon-navbar-vertical"
                />
              </span>
              <span className="text-dashbord">Mon compte</span>
            </NavLink>
          </li>
          <li className="nav-item nav-item-vertical-custom">
            <NavLink
              className="nav-link nav-link-vertival-custom"
              to="/dashboard-investisseur/mes-actions"
              
            >
              <span className="navbar-vertical-container-icon">
                <IconlyPack.Setting
                  className="icon-navbar-vertical"
                  set="bold"
                />
              </span>
              <span className="text-dashbord">Mes actions</span>
            </NavLink>
          </li>
        </ul>
			</div>
		</div>
	);
};
export default SidebarContentV2;
