import React from "react";
import "./Activite.css";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import FrontHeaderV2 from "../../FrontHeaderV2/FrontHeaderV2";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import TabsLinkActivite from "../TabsLinkActivite/TabsLinkActivite";
import ModalSecuriteSession from "../../../Modals/ModalSecuriteSession";
import LesProjetsEnCoursDeCampagnePorteurProjet from "./LesProjetsEnCoursDeCampagnePorteurProjet";
import LesProjetsTerminesPorteurProjet from "./LesProjetsTerminesPorteurProjet";

const Activite = () => {
	return (
		<div className="component-mes-projet-tableau-de-bord">
			<FrontHeaderV2 />
			<TabsLinkActivite />
			<div className="row activite pr-5">
				<div className="col-xl-3 col-lg-4">
					<SidebarTableauDeBord value={1} />
				</div>
				<div className="col-xl-9 col-lg-8">
					<div className="title-block">
						<h1 className="px-2">Mon activité</h1>
					</div>
					<div className="projets-container">
						<LesProjetsEnCoursDeCampagnePorteurProjet />
						<LesProjetsTerminesPorteurProjet />
					</div>
				</div>
				<ModalSecuriteSession />
			</div>
			<FrontFooterV2 />
		</div>
	);
};

export default Activite;
