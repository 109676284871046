import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import AuthServices from "../Services/AuthServices";
import { fetchMe } from "../../redux/user/userAction";
import { useParams } from "react-router-dom";

function UseLoginForm(saveData) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  // const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("Email invalide!")
          .required("L'email est requis."),
        password: yup.string().required("Le mot de passe est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    if (saveData) {
      saveData(formValues);
    }

    // reset();
    setIsSuccessfullySubmitted(true);
    var request = AuthServices.onLogin(formValues);
    await request
      .then(async (resp) => {
        const config = {
          headers: {
            Authorization: `Bearer ${resp?.token}`,
          },
        };
        console.log("resp", resp);
        let requestMe = AuthServices.onMe(config);
        await requestMe
          .then(async (res) => {
            console.log("me", res);
            if (res?.data?.id && res?.data?.user_type === "standard") {
              window.sessionStorage.setItem("userID", res?.data?.id);
              window.sessionStorage.setItem("userType", res?.data?.user_type);
              window.sessionStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                title: "Connexion réussie!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location = "/dashboard/editer-mon-profile";
              });
            } else if (
              res?.data?.id &&
              res?.data?.user_type === "investisseur"
            ) {
              window.sessionStorage.setItem("userID", res?.data?.id);
              window.sessionStorage.setItem("userType", res?.data?.user_type);
              window.sessionStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                title: "Connexion réussie!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                if (window.location.pathname === `/projet/connexion/${projectId}`) {
                  window.location = `/alimenter-son-compte/${projectId}`;
                } else {
                  window.location = "/dashboard-investisseur";
                }
              });
            } else if (res?.data?.id && res?.data?.user_type === "admin") {
              window.sessionStorage.setItem("userID", res?.data?.id);
              window.sessionStorage.setItem("userType", res?.data?.user_type);
              window.sessionStorage.setItem("userToken", resp?.token);
              await dispatch(fetchMe());
              await Swal.fire({
                icon: "success",
                title: "Connexion réussie!",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location = "/admin/dashboard";
              });
            } else {
              //   toast.error("Connexion non authorisée!");
              Swal.fire({
                icon: "error",
                title: "Connexion non authorisée!",
                showConfirmButton: false,
                timer: 3000,
              });
            }
            setIsSuccessfullySubmitted(false);
          })
          .catch((err) => {
            setIsSuccessfullySubmitted(false);
            console.log("err", err, err?.response);
            //toast.error("This didn't work.")
            if (err?.response === undefined) {
              Swal.fire({
                icon: "error",
                title: "Connexion non authorisée !",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (error?.response?.data?.non_field_errors) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title: "Email ou mot de passe incorrect ou compte inactif.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          //   toast.error("Veuillez vérifier votre connexion internet.");
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseLoginForm;
