import React, { useState } from "react";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import "./InformationsPersonnellesEtEntreprise.css";
import { NavLink } from "react-router-dom";
import ModalConfirmationIdentite from "../../Modals/ModalConfirmationIdentite";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import TabsProfile from "../InvestisseurTableauDeBord/ProfilInvestisseur/TabsProfile/TabsProfile";
import SidebarTableauBord from "../InvestisseurTableauDeBord/SidebarTableauBord/SidebarTableauBord";

const InformationsPersonnellesEtEntreprise = () => {
	const [MrIsActive, setMrIsActive] = useState(false);
	const [MmeIsActive, setMmeIsActive] = useState(false);
	const [menIsActive, setMenIsActive] = useState(false);
	const [womanIsActive, setWomanIsActive] = useState(false);

	const handleChange = (e) => {
		console.log(e.target.value);
		if (e.target.value === "0") {
			setMrIsActive(true);
			setMmeIsActive(false);
		}
		if (e.target.value === "1") {
			setMmeIsActive(true);
			setMrIsActive(false);
		}

		if (e.target.value === "2") {
			setMenIsActive(true);
			setWomanIsActive(false);
		}
		if (e.target.value === "3") {
			setWomanIsActive(true);
			setMenIsActive(false);
		}
	};
	return (
		<div>
			<ModalConfirmationIdentite />
			<div className="component-mes-projet-tableau-de-bord bg-dark">
				<FrontHeaderV2 />

				<TabsProfile value={1} />
				<div className="mt-3 row p-0">
					<div className="col-12 col-lg-4 col-xl-3 p-0">
						<SidebarTableauBord value={0} />
					</div>
					<div className="col-12 col-lg-8 col-xl-9 mt-3 mt-lg-0">
						<div className="title-block">
							<h1>
								Votre identité
							</h1>
						</div>
						<div className="contentpersonnel pt-3 px-5 pb-5">
							<h1 className="titreinfoperson">
								Informations personnelles
							</h1>
							<form className="container-cayore-form">
								<div className="content-choix mb-5">
									<div className="d-flex justify-content-start ">
										<p className="align-self-baseline civilite">
											Civilité
										</p>
										<div className="civilite-btn-radio-container">
											<div
												className={
													menIsActive
														? "civilite-btn-radio-item checked-civilite"
														: "civilite-btn-radio-item"
												}
												htmlFor="mme-civilite"
											>
												<input
													onChange={
														handleChange
													}
													type="radio"
													value="2"
													name="civilite"
													id="mr-civilite"
													className="civilite-btn-radio"
												/>
												<label htmlFor="mr-civilite">
													Mr
												</label>
											</div>
											<div
												className={
													womanIsActive
														? "civilite-btn-radio-item checked-civilite"
														: "civilite-btn-radio-item"
												}
											>
												<input
													onChange={
														handleChange
													}
													type="radio"
													value="3"
													name="civilite"
													id="mme-civilite"
													className="civilite-btn-radio"
												/>
												<label htmlFor="mme-civilite">
													Mme
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Prénom*"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Nom*"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="email"
												className=" inputformedit"
												placeholder="Email*"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="tel"
												className=" inputformedit"
												placeholder="Téléphone*"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Pays"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Ville"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Rue"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Code postal"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="text"
												className="inputformedit"
												placeholder="Date de naissance"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Ville de naissance"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Pays de naissance"
											/>
										</div>
									</div>
								</div>
							</form>
							<h1 className="titreinfoperson">
								Informations générales de
								l’entreprise
							</h1>
							<form className="container-cayore-form">
								<div className="content-choix mb-5 ">
									<div className="d-flex justify-content-start ">
										<p className="mr-5 align-self-baseline civilite">
											Civilité
										</p>
										<div className="civilite-btn-radio-container">
											<div
												className={
													MrIsActive
														? "civilite-btn-radio-item checked-civilite"
														: "civilite-btn-radio-item"
												}
												htmlFor="mme-civilite"
											>
												<input
													onChange={
														handleChange
													}
													type="radio"
													value="0"
													name="civilite"
													id="mr-civilite"
													className="civilite-btn-radio"
												/>
												<label htmlFor="mr-civilite">
													Mr
												</label>
											</div>
											<div
												className={
													MmeIsActive
														? "civilite-btn-radio-item checked-civilite"
														: "civilite-btn-radio-item"
												}
											>
												<input
													onChange={
														handleChange
													}
													type="radio"
													value="1"
													name="civilite"
													id="mme-civilite"
													className="civilite-btn-radio"
												/>
												<label htmlFor="mme-civilite">
													Mme
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Nom de l’intstitution"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Poste du représentant"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="number"
												className=" inputformedit"
												placeholder="Numéro du SIRET"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Ville d’imatriculation"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Forme juridique"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50">
										<div className="d-flex flex-column">
											<input
												type="text"
												className="inputformedit"
												placeholder="Adresse du siège social"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Rue"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="text"
												className="inputformedit"
												placeholder="Ville du siège social"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="number"
												className="inputformedit"
												placeholder="Code postal du siège social"
											/>
										</div>
									</div>
									<div className="col-12 col-md-5 offset-md-2 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="text"
												className=" inputformedit"
												placeholder="Pays du siège social"
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-5 m-b-50 ">
										<div className="d-flex flex-column">
											<input
												type="number"
												className=" inputformedit"
												placeholder="Capital"
											/>
										</div>
									</div>
								</div>
								<h1 className="titreposte">
									Poste du représentant
								</h1>
								<div className=" form-group pb-5 ">
									<div className="custom-control custom-checkbox ">
										<input
											type="radio"
											className="custom-control-input"
											id="president"
											name="posteRepresentant"
										/>
										<span className="checkmark"></span>
										<label
											className="custom-control-label inputdirection"
											htmlFor="president"
										>
											Président
										</label>
									</div>
									<div className="custom-control custom-checkbox">
										<input
											type="radio"
											className="custom-control-input "
											id="directeur"
											name="posteRepresentant"
										/>
										<label
											className="custom-control-label inputdirection"
											htmlFor="directeur"
										>
											Directeur
											général
										</label>
									</div>
									<div className="custom-control custom-checkbox ">
										<input
											type="radio"
											className="custom-control-input"
											id="actionnaire"
											name="posteRepresentant"
										/>
										<label
											className="custom-control-label inputdirection"
											htmlFor="actionnaire"
										>
											Actionnaire de
											plus de 25%
											des parts
										</label>
									</div>
								</div>
								<div className="d-flex justify-content-center align-items-center">
									<NavLink
										to="/representant"
										className="btnrepresentant "
									>
										Ajouter un autre
										représentant
									</NavLink>
								</div>
							</form>
							<div className="d-flex ">
								<button
									type="button"
									className="btnsave"
									data-toggle="modal"
									data-target="#modal-de-soumission"
								>
									ENREGISTRER
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FrontFooterV2 />
		</div>
	);
};

export default InformationsPersonnellesEtEntreprise;
