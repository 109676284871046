import React from "react";
import { NavLink } from "react-router-dom";
import ImgP1 from "../../../images/Frame.png";
import { navbarLogo } from "../../Logo/LogoComponent";

function Investisseur() {
  const Investisseurs = [
    {
      id: 0,
      img: navbarLogo,
      title: "Easy Funding",
      desciption:
        "Basé à Dakar, au cœur de l’écosystème entrepreneurial sénégalais, nous investissons dans des personnes extraordinaires avec des idées exceptionnelles susceptibles de révolutionner leur secteur d’activités et de délivrer un maximum d’impact",
      lien: "https://www.easy-funding.fewnu.app/",
    },
    {
      id: 1,
      img: ImgP1,
      title: "Volkeno",
      desciption:
        "Volkeno est une entreprise numérique et une startup studio basée à Dakar. Créée en 2015. Elle a pour vocation de créer et d'aider à créer des produits et services numériques à forte valeur ajoutée. Nous accompagnons tous les acteurs ( entreprises et entrepreneurs ) dans la matérialisation de leurs projets numériques",
      lien: "https://www.volkeno.sn/",
    },
  ];
  return (
    <div className="p-5 mb-5 container-cayore">
      <h1 className="titre-a-propos-text pb-5">Nos investisseurs</h1>
      <div className="row">
        {Investisseurs.map((index, key) => (
          <div className="col-xl-6 col-lg-6 col-md-6" key={index.id}>
            <div className="m-4 h-100 d-flex flex-column justify-content-between">
              <div>
                <img src={index.img} alt="partenaire" className="img-part"/>
              </div>
              <div>
                <h3 className="propos-sous-titre pb-4">{index.title}</h3>
                <p className="text-ap-propos-text">{index.desciption}</p>
              </div>
              <div>
                <NavLink to={index.lien} className="site-partenaire">
                    {index.lien}
                </NavLink>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Investisseur;
