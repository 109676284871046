import React from "react";
import Skeleton from "react-loading-skeleton";

const ProjetsSkeleton = () => {
    return (
      <div className="col-md-12 as-col-table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th
                scope="col"
                style={{ color: "#A0AEC0" }}
                className="th-table-admin"
                hidden
              >
                N°
              </th>
              <th
                scope="col"
                style={{ color: "#A0AEC0" }}
                className="th-table-admin"
              >
                Nom du projet
              </th>

              <th
                scope="col"
                style={{ color: "#A0AEC0" }}
                className="th-table-admin"
              >
                Nom du porteur
              </th>
              <th
                scope="col"
                style={{ color: "#A0AEC0" }}
                className="th-table-admin"
              >
                Email du porteur
              </th>
              <th
                scope="col"
                style={{ color: "#A0AEC0" }}
                className="th-table-admin"
              >
                Montant demandé
              </th>
              <th
                scope="col"
                style={{ color: "#A0AEC0" }}
                className="th-table-admin"
              >
                Progression
              </th>
              <th
                scope="col"
                style={{ color: "#A0AEC0" }}
                className="th-table-admin"
              >
                Statut
              </th>
              <th
                scope="col"
                style={{ color: "#A0AEC0" }}
                className="th-table-admin"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <Skeleton />
              </th>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
              <td>
                <Skeleton />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
}

export default ProjetsSkeleton;