import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import Contact from "../../../services/Contact";

function UseContactForm() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  //   const dispatch = useDispatch();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        nom_complet: yup.string().required("Ce champ est requis"),
        email: yup
          .string()
          .email("Votre email n'est pas correcte.")
          .required("Ce champ est requis."),
        message: yup.string().required("Ce champ est requis."),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);

    var request = Contact.postCreateMessageContact(formValues);
    await request
      .then(async (resp) => {
        console.log("resp contact add", resp);
        // await dispatch(fetchMe());
        await Swal.fire({
          icon: "success",
          title: "Message envoyé avec succès!",
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        await e.target.reset();
        await reset();
      })
      .catch((error) => {
        console.error("error", error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseContactForm;
