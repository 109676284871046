import React from "react";
import {
	BootstrapTable,
	TableHeaderColumn,
	ClearSearchButton,
} from "react-bootstrap-table";
import * as IconlyPack from "react-iconly";
import { NavLink } from "react-router-dom";
import ProjetTableauDeBordSkeleton from "./ProjetTableauDeBordSkeleton";
import "./ProjetTableauDeBord.css";
import { useSelector } from "react-redux";
import moment from "moment";

const ProjetTableauDeBord = (props) => {
	const projects = useSelector((state) => state.projectsByPorteur);
	// const handleClearButtonClick = (onClick) => {
	// 	console.log(
	// 		"This is my custom function for ClearSearchButton click event"
	// 	);
	// 	onClick();
	// };

	const createCustomClearButton = (onClick) => {
		return (
			<ClearSearchButton
				btnText="Effacer"
				btnContextual="btn-warning"
				className="my-custom-class"
				// onClick={(e) => handleClearButtonClick(onClick)}
			/>
		);
	};

	const options = {
		clearSearch: true,
		clearSearchBtn: createCustomClearButton,
		noDataText: "Aucune donnée disponible",
	};

	const actionFormatter = (cell, row) => {
		return (
			<div className="table-actions-btn-container">
				{/* <button className="action-btn show-btn">
					<IconlyPack.Delete
						set="bold"
						primaryColor="rgba(235, 87, 87, 1)"
						className="action-btn-icon action-delete-icon"
					/>
				</button> */}
				<button className="action-btn edit-btn">
					<IconlyPack.Edit
						set="bold"
						primaryColor="rgb(97, 40, 151)"
						className="action-btn-icon action-edit-icon"
					/>
				</button>
				{/* <button className="action-btn edit-btn">
					<IconlyPack.Send
						set="bold"
						primaryColor="rgba(17, 92, 103, 1)"
						className="action-btn-icon action-send-icon"
					/>
				</button> */}
			</div>
		);
	};

	const redirectionFormatter = (cell, row) => {
		return (
			<div>
				<NavLink
					to={{
						pathname: "/dashboard/details-projet-tableau-de-bord",
						state: {project: row},
					}}
					className="redirection-projet"
				>
					{cell}{" "}
				</NavLink>
			</div>
		);
	};

	const etatFormatter = (cell) => (
		<span
			className={cell === "en_financement" ? "en-ligne" : "termine"}
		>
			{cell === "en_financement" ? "En ligne" : "Terminé"}
		</span>
	);

	const dateFormatter = (cell) => (
		<span>{moment(cell).format("DD/MM/YYYY")}</span>
	);

	return (
		<div className="child-table-container">
			<div className="table-body-container">
				<div className="row easypm-table-row m-t-50 m-b-50">
					<div className="col-md-12 easypm-table-col">
						<h2>Mes projets</h2>
						<hr />
						{projects.isLoading && (
							<ProjetTableauDeBordSkeleton />
						)}
						{!projects.isLoading && (
							<div className="table-container">
								<BootstrapTable
									data={
										projects.filteredData
									}
									striped={true}
									hover={true}
									condensed={false}
									multiColumnSort={2}
									options={options}
									search={false}
									version="4"
									bordered={false}
								>
									<TableHeaderColumn
										dataField="id"
										isKey={true}
										hidden={true}
										className="data-center-table-info"
									>
										id
									</TableHeaderColumn>
									<TableHeaderColumn
										dataField="title"
										dataFormat={(cell, row) => redirectionFormatter(cell, row)}
										className="data-center-table-info"
									>
										Nom
									</TableHeaderColumn>
									<TableHeaderColumn
										dataField="created_at"
										dataFormat={(
											cell
										) =>
											dateFormatter(
												cell
											)
										}
										className="data-center-table-info"
									>
										Date
									</TableHeaderColumn>
									<TableHeaderColumn
										dataField="etat"
										dataFormat={(
											cell
										) =>
											etatFormatter(
												cell
											)
										}
										className={`data-center-table-info`}
									>
										Etat actuel
									</TableHeaderColumn>
									<TableHeaderColumn
										dataField="date_lancement"
										dataFormat={(
											cell
										) =>
											dateFormatter(
												cell
											)
										}
									>
										Date de lancement
									</TableHeaderColumn>

									<TableHeaderColumn
										dataField="id"
										dataFormat={(
											cell,row
										) =>
											actionFormatter(
												cell, row
											)
										}
									>
										Actions
									</TableHeaderColumn>
								</BootstrapTable>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjetTableauDeBord;
