import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
//import FrontFooterV2 from "../../../FrontFooterV2/FrontFooterV2";
import HeaderV2 from "../../../../BackendV2/Admin/Navs/HeaderV2";
import SidebarMobileTableauBord from "../../SidebarTableauBord/SidebarMobileTableauBord";
import SidebarTableauBord from "../../SidebarTableauBord/SidebarTableauBord";
import TabsProfile from "../TabsProfile/TabsProfile";
import InformationsEntreprise from "./InformationsEntreprise";
import InformationsPersonnellesInvestisseur from "./InformationsPersonnellesInvestisseur";
import { useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import moment from "moment";
import Utilisateur from "../../../../Services/Utilisateur";
import { useDispatch } from "react-redux";
import { fetchMe } from "../../../../../redux/user/userAction";
import Swal from "sweetalert2";

const EditerProfilInvestisseur = () => {
	const user = useSelector((state) => state.user);
	const [startDate, setStartDate] = useState();
	const [sexeUser, setSexeUser] = useState("");
	const [avatarProfile, setAvatarProfile] = useState(null);
	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
		React.useState(false);
	const dispatch = useDispatch();

	const config = {
		headers: {
			Authorization: `Bearer  ${sessionStorage.getItem(
				"userToken"
			)}`,
		},
	};

	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				email: yup
					.string()
					.email("Email invalide!")
					.required("L'email est requis."),
				prenom: yup.string().required("Le prenom est requis."),
				nom: yup.string().required("Le nom est requis."),
				phone: yup.string().required("Le Téléphone est requis"),
				sexe: yup.string().nullable(),
				pays: yup.string().nullable(),
				ville: yup.string().nullable(),
				rue: yup.string().nullable(),
				code_pastal: yup.string().nullable(),
				date_naissance: yup.string().nullable(),
			}),
		[]
	);
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const { data } = user;
	useEffect(() => {
		if (data?.id) {
			console.log("En cours", user);
			console.log("En cours", data);
			setValue("prenom", data?.prenom);
			setValue("nom", data?.nom);
			setValue("email", data?.email);
			setValue("phone", data?.phone);
			setValue("sexe", data?.sexe);
			setValue("rue", data?.rue);
			setValue("date_naissance", data?.date_naissance);
			setValue("code_postal", data?.code_postal);
			setValue("ville", data?.ville);
			setValue("pays", data?.pays);
			if (data?.date_naissance) {
				setStartDate(new Date(data.date_naissance));
			}

			if (data?.sexe === "homme") {
				setSexeUser("homme");
			}
			if (data?.sexe === "femme") {
				setSexeUser("femme");
			}
		}
		return () => reset();
	}, [data, setValue, reset]);
	const handleChangeDate = (date) => {
		setValue("date_naissance", moment(date).format("YYYY-MM-DD"));
		setStartDate(date);
	};
	const handleChangeSexe = (e) => {
		setValue("sexe", e.target.value);
		if (e.target.value === "homme") {
			setSexeUser("homme");
		}
		if (e.target.value === "femme") {
			setSexeUser("femme");
		}
	};
	const handleChangeAvatar = (e) => {
		if (e.target.files[0]) {
			setValue("avatar", e.target.files[0]);
			setAvatarProfile(URL.createObjectURL(e.target.files[0]));
		}
	};
	const onSubmit = async (data) => {
		setIsSuccessfullySubmitted(true);
		const formData = new FormData();
		if (data.avatar) {
			formData.append("avatar", data.avatar);
		}
		formData.append("prenom", data.prenom);
		formData.append("nom", data.nom);
		formData.append("email", data.email);
		formData.append("phone", data.phone);
		formData.append("sexe", data.sexe);
		formData.append("rue", data.rue);
		formData.append("ville", data.ville);
		formData.append("pays", data.pays);
		formData.append("date_naissance", data.date_naissance);
		formData.append("code_postal", data.code_postal);
		let request = Utilisateur.onEditUtilisateur(
			user.data.id,
			formData,
			config
		);

		await request
			.then(async (response) => {
				console.log("response", response);
				await setAvatarProfile(null);
				await dispatch(fetchMe());
				await setIsSuccessfullySubmitted(false);
				await Swal.fire({
					icon: "success",
					title: "Modification réussie!",
					showConfirmButton: false,
					timer: 1500,
				});
			})
			.catch((error) => {
				console.log(error?.response);
				setIsSuccessfullySubmitted(false);
				if (
					error?.response?.data?.email &&
					error?.response?.data?.email.length &&
					error?.response?.data?.email[0] ===
						"user with this email already exists."
				) {
					Swal.fire({
						icon: "error",
						title: "Votre e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte ou vous connecter.",
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (
					error?.response?.data?.status === "failure"
				) {
					Swal.fire({
						icon: "error",
						title: error?.response?.data?.message,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					Swal.fire({
						icon: "error",
						title: "Veuillez vérifier votre connexion internet.",
						showConfirmButton: false,
						timer: 3000,
					});
				}
			});
	};
	const props = {
		register,
		errors,
		setValue,
		startDate,
		handleChangeDate,
		sexeUser,
		handleChangeSexe,
		data,
		handleChangeAvatar,
		avatarProfile,
	};

	return (
		<div className="component-investisseur">
			<HeaderV2 />
			<TabsProfile />
			<div className="row">
				<div className="col-lg-4 col-md-6 col-xl-3 no-view-mobile">
					<SidebarTableauBord />
				</div>
				<div className="col-lg-8 col-xl-9 col-md-6  px-0 pr-4">
					<div className="no-see-desktop-sidebar margin-top-sidebar-mobile">
						<SidebarMobileTableauBord />
					</div>
					<div className="mb-5 section-content-page-tb-investisseur bg-white">
						<form
							className="container-cayore-form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<InformationsPersonnellesInvestisseur
								{...props}
							/>
							{user?.data?.statut === "morale" && (
								<InformationsEntreprise />
							)}
							<div className="d-flex pb-5">
							{isSuccessfullySubmitted && (
                      <button className="btn-modifier" disabled>
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!isSuccessfullySubmitted && (
                      <button className="btn-modifier">Enregistrer</button>
                    )}
							</div>
						</form>
					</div>
				</div>
			</div>
			{/* <FrontFooterV2 /> */}
		</div>
	);
};

export default EditerProfilInvestisseur;
