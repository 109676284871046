import React, { useEffect } from "react";
import "./Transactions.css";
import * as IconlyPack from "react-iconly";
import SidebarTableauBord from "../SidebarTableauBord/SidebarTableauBord";
import SidebarMobileTableauBord from "../SidebarTableauBord/SidebarMobileTableauBord";
import HeaderV2 from "../../../BackendV2/Admin/Navs/HeaderV2";
// import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
// import Dashboard from "../MonTableauDeBord/Dashboard";

const Transactions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-investisseur">
      <HeaderV2 />
      <div className="content  my-4 d-flex py-0 content-title-page-tb-investisseur">
				<div className="d-flex align-items-lg-center">
					{/* <Dashboard/> */}
				</div>
			</div>
      <div className="content-view-start">
        <div className="row">
          <div className="col-lg-3 col-md-2 no-view-mobile">
            <SidebarTableauBord />
          </div>
          <div className="col-lg-9 col-md-10 px-0 pr-4">
            <div className="no-see-desktop-sidebar margin-top-sidebar-mobile">
              <SidebarMobileTableauBord />
            </div>
            <section className="mb-5 section-content-page-tb-investisseur ">
              <div className="bg-white p-3">
                <div className="page-first-row-container border-bottom mb-5">
                  <div className="page-title-container pt-3">
                    <h1 className="page-title">Historique de vos transactions</h1>
                  </div>
                </div>
                <div className="bloc-transaction my-5">
                    <form>
                        <div className="row align-items-center">
                            <div className="col-md-4 pb-3">
                                <select className="form-control form-control-transaction" id="exampleFormControlSelect1">
                                    <option>Toutes les transactions</option>
                                    <option>Toutes les transactions</option>
                                </select>
                            </div>
                            <div className="col-md-2 pb-3">
                                <div className="text-center"><p className="text-ou-transaction">De</p></div>
                            </div>
                            <div class="col-md-6">
                                <div class="search-box">
                                    <button class="search-button">
                                        <IconlyPack.Search primaryColor="#000" />
                                    </button>
                                    <input type="text" class="search-input" placeholder="Data Center Mbao"/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="col-md-6">
                        <div className="bloc-transaction-flex">
                            <div className="text-gray-transaction">Janvier 2021:</div>
                            <div className="text-gray-transaction">20 000 FCFA</div>
                        </div>
                    </div>
                </div>
              </div>
             </section>
          </div>
        </div>
      </div>
      {/* <FrontFooterV2 /> */}
    </div>
  );
};

export default Transactions;
