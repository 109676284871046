import React from "react";
import Skeleton from "react-loading-skeleton";

const HistoriquePretTableSkeleton = () => {
    return(
        <div className="col-md-12 as-col-table-container">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col"  style={{ color:'#BB4411' }} className="th-table">Projets</th>
                        <th scope="col" style={{ color:'#BB4411' }} className="th-table">Score</th>
                        <th scope="col" style={{ color:'#BB4411' }} className="th-table">Taux</th>
                        <th scope="col" style={{ color:'#BB4411' }} className="th-table">Prêtés</th>
                        <th scope="col" style={{ color:'#BB4411' }} className="th-table">Perçus</th>
                        <th scope="col" style={{ color:'#BB4411' }} className="th-table">Provisions</th>
                        <th scope="col" style={{ color:'#BB4411' }} className="th-table">En cours</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    <tr>
                        <th scope="row"><Skeleton/></th>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default HistoriquePretTableSkeleton;