import React from "react";
import "./ParametreAdmin.css";
import {Delete, EditSquare, TickSquare } from "react-iconly";
import "react-responsive-modal/styles.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "./ParametreAdmin.css";
import { useDispatch, useSelector } from "react-redux";
import ListeAdminSkeleton from "./ListeAdminSkeleton";
import CRUDAdminModal from "../../../Modals/CRUDAdminModal";
import Swal from "sweetalert2";
import { fetchAdmins } from "../../../../redux/admins/adminsAction";
import Axios from "axios";
import api from "../../../../Http/global-vars";
// import { onDeleteData } from "./DeleteData";

const Listeadmin = () => {
  const admins = useSelector((state) => state.admins);
  const dispatch = useDispatch();
  const options = {
    // searchField: createCustomSearchField,
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };

  const onDelete = (item) => {
    let token = window.sessionStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: `Êtes vous sûr de vouloir ${
        item?.is_active ? "désactiver" : "activer"
      } cet administrateur?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#115c67",
      cancelButtonColor: "#E53E3E",
      preConfirm: () => {
        return Axios.put(
          `${api}user/${item?.id}/`,
          { is_active: !item?.is_active },
          config
        )
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchAdmins());
            return response;
          })
          .catch((error) => {
            // console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `Administrateur ${
            item?.is_active ? "désactivé" : "activé"
          } avec succès.`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const nameFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span className="name">{row.prenom + " " + row?.nom}</span>
      </div>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="parametre-actions-container">
        {/* <button style={{ color: "#ACABAB", backgroundColor: "transparent" }}>
          <Delete
            set="bold"
            primarycolor="#ACABAB"
            style={{ fontSize: "20px" }}
          />
        </button> */}

        <button
          style={{ color: "#ACABAB", backgroundColor: "transparent" }}
          title="Modifier"
        >
          <EditSquare
            set="bold"
            primaryColor="#ACABAB"
            style={{ fontSize: "16px", width: "20px", marginLeft: " 10px" }}
            data-toggle="modal"
            data-target={`#editunadministrateur${row?.id}`}
          />
        </button>
        {!row?.is_active ? (
          <button
            style={{ color: "#115c67", backgroundColor: "transparent" }}
            className="mr-2"
            title="Activer"
            onClick={() => onDelete(row)}
          >
            <TickSquare set="light" style={{ fontSize: "20px" }} />
          </button>
        ) : (
          <button
            style={{ color: "#ACABAB", backgroundColor: "transparent" }}
            className="mr-2"
            title="Désactiver"
            onClick={() => onDelete(row)}
          >
            <Delete
              set="light"
              primarycolor="#ACABAB"
              style={{ fontSize: "20px" }}
            />
          </button>
        )}
        <div
          className="modal fade"
          id={`editunadministrateur${row?.id}`}
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="editunadministrateur"
          aria-hidden="true"
        >
          {" "}
          <CRUDAdminModal admin={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="infos-perso-input">
      {admins?.isLoading && <ListeAdminSkeleton />}
      {!admins?.isLoading && (
        <BootstrapTable
          data={admins?.data}
          striped={true}
          hover={true}
          condensed={false}
          multiColumnSort={2}
          search={false}
          version="4"
          bordered={false}
          options={options}
        >
          <TableHeaderColumn
            dataField="id"
            isKey={true}
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 18,
              color: "#115C67",
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.7)",
              padding: 20,
            }}
            hidden
            width="25"
          >
            N°
          </TableHeaderColumn>

          <TableHeaderColumn
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 18,
              color: "#115C67",
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.7)",
              padding: 20,
            }}
            dataField="prenom"
            width="200"
            dataFormat={(cell, row) => nameFormatter(cell, row)}
          >
            Prénom & Nom
          </TableHeaderColumn>

          <TableHeaderColumn
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 18,
              color: "#115C67",
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.7)",
              padding: 20,
            }}
            width="150"
            dataField="phone"
          >
            Téléphone
          </TableHeaderColumn>
          <TableHeaderColumn
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 18,
              color: "#115C67",
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.7)",
              padding: 20,
            }}
            width="150"
            dataField="email"
          >
            Adresse mail
          </TableHeaderColumn>
          <TableHeaderColumn
            bordered={false}
            thStyle={{
              fontWeight: 600,
              fontSize: 18,
              color: "#115C67",
            }}
            tdStyle={{
              fontWeight: 500,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.7)",
              padding: 20,
            }}
            width="100"
            dataFormat={(cell, row) => actionFormatter(cell, row)}
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>
      )}
    </div>
  );
};

export default Listeadmin;
