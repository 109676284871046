import React, {  useEffect } from "react";
import "./MesActions.css";
import * as IconlyPack from "react-iconly";
import SidebarTableauBord from "../SidebarTableauBord/SidebarTableauBord";
import SidebarMobileTableauBord from "../SidebarTableauBord/SidebarMobileTableauBord";
import HeaderV2 from '../../../BackendV2/Admin/Navs/HeaderV2'
// import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import { Rate } from "rsuite";
import { NavLink } from "react-router-dom";
import ActionsInvestisseurTable from "../../../Tables/TableauDeBordInvestisseur/ActionsInvestisseurTable/ActionsInvestisseurTable";
// import Dashboard from "../MonTableauDeBord/Dashboard";

const MesActions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-investisseur">
      <HeaderV2 />
      <div className="content px-2 my-4 d-flex py-0 content-title-page-tb-investisseur">
				<div className="d-flex align-items-lg-center">
					{/* <Dashboard/> */}
				</div>
			</div>
      <div className="content-view-start">
        <div className="row">
          <div className="col-lg-3 no-view-mobile">
            <SidebarTableauBord />
          </div>
          <div className="col-lg-9 pl-0 pr-4">
            <div className="no-see-desktop-sidebar margin-top-sidebar-mobile">
              <SidebarMobileTableauBord />
            </div>
            <section className="mb-5 section-content-page-tb-investisseur">
              <div className="bg-white repartition-pret">
                <div className="page-first-row-container border-bottom">
                  <div className="page-title-container pt-3">
                    <h1 className="page-title">Synthèse au 1er juin</h1>
                  </div>
                  <div className="d-flex justify-conetent-end">
                        <div className="mr-3">
                          <NavLink
                            to="#"
                            className="btn btn-debiter-compte trans-0-2"
                          >
                            <IconlyPack.ArrowUp set="light" />
                            <span className="pl-2">Alimenter</span>
                          </NavLink>
                        </div>
                        <div>
                          <NavLink
                            to="#"
                            className="btn btn-debiter-compte trans-0-2"
                          >
                            <IconlyPack.ArrowDown set="light" />
                            <span className="pl-2">Débiter</span>
                          </NavLink>
                        </div>
                      </div>
                </div>
                <div className="py-4">
                      <ul>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <p className="titre-synthese-list">
                                Récapitulatif
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="titre-synthese-list">
                                Taux de rentabilité
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="titre-synthese-list">
                                Diversification
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  En cours
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  10 000 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  TRI initial
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  2%
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Niveau
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  <Rate
                                    defaultValue={5}
                                    size="xs"
                                    style={{ color: "rgba(17, 92, 103, 0.5)" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Disponible
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  10 000 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  TRI actuel
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  2%
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Prêts
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  1
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Total
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  20 000 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Exposition max
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  100%
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="pt-5">
                      <div className="page-first-row-container border-bottom">
                        <div className="page-title-container pt-3">
                          <h1 className="page-title">Activités</h1>
                        </div>
                        <div className="d-flex justify-conetent-end">
                          <div className="mr-3">
                            <NavLink
                              to="#"
                              className="btn btn-debiter-compte trans-0-2"
                            >
                              <IconlyPack.Upload set="light" />
                              <span className="pl-2">Exporter</span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <ul>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <p className="titre-synthese-list">Mai 2021</p>
                            </div>
                            <div className="col-md-4">
                              <p className="titre-synthese-list">Juin 2021</p>
                            </div>
                            <div className="col-md-4">
                              <p className="titre-synthese-list">
                                Juillet 2021
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Remboursement{" "}
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  0 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Remboursements
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  0 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Remboursements
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  0 FCFA
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Prêté
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  10 000 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Prêté
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Prêts
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  0 FCFA
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  
              </div>
              <div className="bg-white my-3 repartition-pret">
                <div className="page-first-row-container border-bottom">
                  <div className="page-title-container my-4">
                    <h1 className="page-title">Mes actions</h1>
                  </div>
                </div>
                <div className="pt-3">
                   <ActionsInvestisseurTable />
                </div>
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-center">
                                <p className="type-action-investisseur">Mes investissements en actions</p>
                                <p className="nombre-action-investisseur">4</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-center">
                                <p className="type-action-investisseur">Mes investissements en titres</p>
                                <p className="nombre-action-investisseur">2</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
          </div>
        </div>
      </div>
      {/* <FrontFooterV2 /> */}
    </div>
  );
};

export default MesActions;
