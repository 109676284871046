// import Logo from '../../../images/ahj-logo.png';
import React from "react";
import "./FrontFooterV2.css";
// import Logo from '../../../images/ahj-logo.png';
import Facebook from "../../../assets/images/icons/facebook.png";
import Twitter from "../../../images/icons/twitter.png";
import Instagram from "../../../images/icons/instagram.png";
import LinkedIn from "../../../assets/images/icons/linkedin.png";
import Youtube from "../../../assets/images/icons/youtube.png";
import Google from "../../../assets/images/icons/google.png";
import Wifi from "../../../assets/images/icons/wifi.png";
import Pinterest from "../../../assets/images/icons/pinterest.png";

// import Linkedin from '../../../images/icons/linkedin.png';
import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
// import IconPrint from "../../../assets/images/icons/print.png";

const FrontFooterV2 = (props) => {
  return (
    <footer className="component-front-footer">
      <div className="container-aem">
        <div className="body-footer">
          <div className="row">
            <div className="col-xl-3 pr-lg-5 col-lg-3 mb-5 footer-grid">
              <p className="title-footer mb-4">Besoin d’aide?</p>
              <NavLink className="link-footer trans-0-2 d-block mb-3" to={""}>
                FAQs
              </NavLink>
              <NavLink
                className="link-footer trans-0-2 d-block mb-3"
                to="/nous-contacter"
              >
                Nous contacter
              </NavLink>
              <NavLink className="link-footer trans-0-2 d-block mb-3" to={""}>
                A propos de nous
              </NavLink>
            </div>
            <div className="col-xl-3 col-lg-3 mb-5 col-md-3 footer-grid">
              <p className="title-footer mb-4">Liens utiles</p>
              <NavLink className="link-footer trans-0-2 d-block mb-3" to={"/investir"}>
                Investir
              </NavLink>
              <NavLink className="link-footer trans-0-2 d-block mb-3" to={"/se-financer"}>
                Se Financer
              </NavLink>
              <NavLink className="link-footer trans-0-2 d-block mb-3" to={"comment-ca-marche"}>
                Comment ça marche
              </NavLink>
            </div>
            <div className="col-xl-6 col-lg-6 mb-5 footer-grid">
              <div>
                <div className="footer-reseaux-sociaux-container">
                  <div className="pt-3">
                    <div className="link-footer trans-0-2 d-block mb-3 d-flex" to={""}>
                      <IconlyPack.Location set="bold" primaryColor="var(--primaryColor)" />
                      <div className="pl-2">
                        Hlm Grand Yoff Villa n°241<br /> 
                        près du terminus des bus Dakar<br />  Dem Dikk 
                        qui vont à l'AIBD`<br />  Dakar 11500
                      </div>
                    </div>
                    <p className="link-footer trans-0-2 d-block mb-3" to={""}>
                      <IconlyPack.Call set="bold" primaryColor="var(--primaryColor)" />
                      <span className="pl-2">(+221) 33 827 67 91</span>
                    </p>
                  </div>
                  {/* <div className="d-flex flex-column pt-3 no-padding-footer">
                    <div className="mt-4 no-mt-footer">
                      <p className="link-footer trans-0-2 d-block my-3" to={""}>
                        <img src={IconPrint} alt="Icon" />
                        <span className="pl-2">(123) 456-7890</span>
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="d-flex container-image-sociaux pt-4">
              <div className="footer-social-media-label-container">
                <p className="title-media-sociaux">Medias</p>
              </div>
              <div className="social-icons-container">
                <div className="social-icon-item-container">
                  <NavLink className="mx-2 trans-0-2 social-link-item" to={""}>
                    <img src={Facebook} alt="Facebook" className="social-img" />
                  </NavLink>
                </div>
                <div className="social-icon-item-container">
                  <NavLink className="mx-2 trans-0-2 social-link-item" to={""}>
                    <img src={Twitter} alt="Twitter" className="social-img" />
                  </NavLink>
                </div>
                <div className="social-icon-item-container">
                  <NavLink className="mx-2 trans-0-2 social-link-item" to={""}>
                    <img src={LinkedIn} alt="LinkedIn" className="social-img" />
                  </NavLink>
                </div>
                <div className="social-icon-item-container">
                  <NavLink className="mx-2 trans-0-2 social-link-item" to={""}>
                    <img src={Youtube} alt="Youtube" className="social-img" />
                  </NavLink>
                </div>
                <div className="social-icon-item-container">
                  <NavLink className="mx-2 trans-0-2 social-link-item" to={""}>
                    <img
                      src={Instagram}
                      alt="Instagram"
                      className="social-img"
                    />
                  </NavLink>
                </div>
                <div className="social-icon-item-container">
                  <NavLink className="mx-2 trans-0-2 social-link-item" to={""}>
                    <img src={Google} alt="Google" className="social-img" />
                  </NavLink>
                </div>
                <div className="social-icon-item-container">
                  <NavLink className="mx-2 trans-0-2 social-link-item" to={""}>
                    <img
                      src={Pinterest}
                      alt="Pinterest"
                      className="social-img"
                    />
                  </NavLink>
                </div>
                <div className="social-icon-item-container">
                  <NavLink className="mx-2 trans-0-2 social-link-item" to={""}>
                    <img src={Wifi} alt="Wifi" className="social-img" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0  mt-1 py-3 d-flex justify-content-center align-items-center">
        <div className="col-md-6 offset-md-4 col-sm-12 mt-lg-0 px-0 d-lg-flex">
          <p className="copyright">2024 © Easy Funding</p>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooterV2;
