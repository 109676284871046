import React from "react";
import "./telechargerDocumentInscription.css";

const TelechargerDocumentInscription = ({ navigation }) => {
  const { next } = navigation;

  return (
    <div className="component-front-register-infos-perso-two">
      <div className="card card-body card-telecharger-document">
        <div className="div-contenant">
          <h5 className="title-verification titre-document">
            Choisir un document à telecharger{" "}
          </h5>

          <div className="row mt-5 ml-3">
            <div className="col-md-12 mt-4">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input style-radio"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="option1"
                  defaultChecked
                />
                <label class="form-check-label check-label1" for="inlineRadio1">
                  Piéce d'identité
                </label>
              </div>
            </div>
          </div>
          <div className="row mt-5 ml-3">
            <div className=" col-md-12 my-4">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input style-radio"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="option2"
                />
                <label class="form-check-label check-label1" for="inlineRadio2">
                  Passeport
                </label>
              </div>
            </div>
          </div>
          <div className="mt-1 pt-1">
            <div className="row mt-3 pt-3">
              <div className="col-12">
                <button
                  className="btn boutton-continuer"
                  type="button"
                  onClick={next}
                >
                  Continuer
                </button>
                {/* <NavLink to="telecharger-photo-inscription"  className="btn boutton-continuer">Continuer</NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelechargerDocumentInscription;
