import React from "react";
import Skeleton from "react-loading-skeleton";

const ProjetTerminePorteurSkeleton = () => {
	return (
		<div className="project-teminer-item col-md-8 col-lg-6 itm-center flex-column flex-sm-row">
			<div className="project-img-container mb-2 mb-md-0 mr-3">
				<Skeleton height={100} width={100} />
			</div>
			<div className="project-text">
				<Skeleton height={8} width={300} />
				<div className="progress-group">
					<div className="flex-sb">
						<Skeleton height={5} width={30} />
						<Skeleton height={5} width={100} />
					</div>
					<Skeleton height={8} width={300} />
					<div className="flex-sb">
						<Skeleton height={5} width={80} />
						<Skeleton height={5} width={150} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjetTerminePorteurSkeleton;
