import moment from "moment";
import React from "react";
import baseUrl from "../../../../../Http/backend-base-url";
import avatar from "../../../../../images/others/default-profile-avatar.png";

function UserDetails({ user }) {
  return (
    <div className="modal-dialog modal-dialog-centered  easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Détails de l'utilisateur
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row position-relative">
                <div className="col-md-12 crud-col-item d-flex justify-content-center">
                  <div className="form-group align-items-center bloc-prestataire-profil position-relative text-center">
                    <img
                      src={
                        user?.avatar && !user?.avatar?.includes("default.png")
                          ? baseUrl + user?.avatar
                          : avatar
                      }
                      className="img-utilisateur"
                      alt="user-avatar"
                      height="50"
                      width="50"
                    />
                    <h3 className="profil-nom text-wrap">
                      {user?.prenom + " " + user?.nom}
                    </h3>
                    <h6 className="profil-type">
                      {" "}
                      {user?.user_type === "standard"
                        ? "Porteur de projet"
                        : user?.user_type}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row position-relative p-3 mb-5">
                <h5 className="profil-type" style={{ color: "#115c67" }}>
                  {" "}
                  Informations personnelles
                </h5>
              </div>

              <div className="row position-relative p-3 mb-5">
                <div className="col-md-4">
                  <div>
                    <p className="texte-gray">Prénom et Nom </p>
                    <p className="bloc-text-profil text-wrap">
                      {user?.prenom + " " + user?.nom}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <p className="texte-gray">Date d'inscription</p>
                    <p className="bloc-text-profil">
                      {moment(user?.created_at).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div>
                    <p className="texte-gray">Adresse</p>
                    <p className="bloc-text-profil">
                      {user?.adresse || "Non communiqué"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row position-relative p-3 mb-5">
                <div className="col-md-4">
                  <div>
                    <p className="texte-gray">Télephone</p>
                    <p className="bloc-text-profil">{user?.phone}</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div>
                    <p className="texte-gray">Email</p>
                    <p className="bloc-text-profil">{user?.email}</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
