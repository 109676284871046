import React from "react";
import HistoriquePretTable from "../../../Tables/FrontEnd/HistoriquePretTable/HistoriquePretTable";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import FrontHeaderV2 from "../../FrontHeaderV2/FrontHeaderV2";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import TabsLinkActivite from "../TabsLinkActivite/TabsLinkActivite";

const HistoriquePretsPorteurProjet = () => {
	return (
		<div className="component-mes-projet-tableau-de-bord">
			<FrontHeaderV2 />
			<TabsLinkActivite />
			<div className="row activite pr-5">
				<div className="col-xl-3 col-lg-4">
					<SidebarTableauDeBord value={1} />
				</div>
				<div className="col-xl-9 col-lg-8">
					<div className="title-block">
						<h1 className="px-2">Mon activité</h1>
					</div>
					<div className="historique-des-prets px-5 bg-white pt-3 mt-3">
						<h1 className="activite-table-title">
							Historique de vos prêts
						</h1>
						<div className="">
							<HistoriquePretTable />
						</div>
					</div>
				</div>
			</div>
			<FrontFooterV2 />
		</div>
	);
};

export default HistoriquePretsPorteurProjet;
