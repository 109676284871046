import React, { Component } from "react";
import NavbarContentV2 from "../NavbarContent/NavbarContentV2";
import "./NavbarV2.css";

export default class NavbarV2 extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {active} = this.props

		return (
			<div className="navbar-vertical-customisation-v2 no-view-mobile">
				<NavbarContentV2 active={active}/>
			</div>
		);
	}
}
