import React from "react";
import { useSelector } from "react-redux";
import projectImg from "../../../../images/image2.png";
import ProjetTerminePorteurSkeleton from "./ProjetTerminePorteurSkeleton";

const LesProjetsTerminesPorteurProjet = () => {
	const finishProjects = useSelector(
		(state) => state.finishProjectByPorteur
	);
	console.log(finishProjects);

	return (
		<div className="bg-white p-3 my-5">
			<div className="px-md-5">
				<h1 className="activite-table-title">
					Les projets terminés
				</h1>
				<div className="row mt-5  mb-3">
					{/* <ProjetTermines /> */}
					{finishProjects.isLoading && (
						<ProjetTerminePorteurSkeleton />
					)}
					{!finishProjects.isLoading &&
					finishProjects.filteredData.length ? (
						finishProjects.filteredData.map(
							(project) => (
								<div className="project-teminer-item col-md-8 col-lg-6 itm-center flex-column flex-sm-row">
									<div className="project-img-container mb-2 mb-md-0">
										<img
											src={
												projectImg
											}
											alt="projet-img"
											className="project-img"
										/>
									</div>
									<div className="project-text">
										<h1 className="project-finish-title mb-2 mb-md-0">
											{
												project.title
											}
										</h1>
										<div className="progress-group">
											<div className="flex-sb">
												<div className="project-stat">
													100%
												</div>
												<div className="project-stat">
													2000000FCFA
												</div>
											</div>
											<div className="progress my-1">
												<div
													className="progress-bar w-75"
													role="progressbar"
													aria-valuenow="75"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
											<div className="flex-sb">
												<div className="project-statis color-sec">
													Terminé
												</div>
												<div className="project-statist color-pri">
													{
														project.actionnarat
													}{" "}
													participations
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						)
					) : (
						<div className="gfa-rowy mt-5">
							<div className="alert alert-warning gfa-warning">
								Aucun projet n'est encore
								terminé
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default LesProjetsTerminesPorteurProjet;
