import React, { useState } from "react";
// import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
// import { RiDoubleQuotesR, RiDoubleQuotesL } from "react-icons/ri";
import Evenement1 from "../../../assets/images/evenement1.png";
import Evenement2 from "../../../assets/images/evenement2.png";
import Evenement3 from "../../../assets/images/evenement3.png";
import Evenement4 from "../../../assets/images/evenement4.png";
import Carousel from "react-elastic-carousel";

function EvenementCarousel() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
  ];
  const NosEvenements = [
    {
      id: 0,
      img: Evenement1,
      alt: "Image de Party Events",
      title: "Party Events",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    },
    {
      id: 1,
      img: Evenement2,
      alt: "Image de Music Events",
      title: "Music Events",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing elitLorem ipsum dolor sit amet consectetur adipiscing elit",
    },
    {
      id: 2,
      img: Evenement3,
      alt: "Image de Special Events",
      title: "Special Events",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    },
    {
      id: 3,
      img: Evenement4,
      alt: "Image de Company Events",
      title: "Company Events",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    },
    {
      id: 4,
      img: Evenement1,
      alt: "Image de Party Events",
      title: "Party Events",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    },
    {
      id: 5,
      img: Evenement2,
      alt: "Image de Music Events",
      title: "Music Events",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    },
    {
      id: 6,
      img: Evenement2,
      alt: "Image de Music Events",
      title: "Music Events",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    },
    {
      id: 7,
      img: Evenement2,
      alt: "Image de Music Events",
      title: "Music Events",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    },
  ];
 
  return (
    <section
      id="temoignages"
      className="mb-5 pb-5 pt-0 p-lg-0 p-md-0 m-lg-0 m-md-0"
    >
            <div className="">
            <Carousel breakPoints={breakPoints}>
              {NosEvenements.map((item, idx) => {
                return (
                      <div className="card w-100 shadow m-3 border-0 rounded-5 hauteurCarousel" key={idx.id}>
                          <div className="d-flex flex-column justify-content-between hauteurCarousel">
                                <img
                                  src={item.img}
                                  alt={item.alt}
                                  className="w-100 rounded-top-5"
                                />
                                <div className="px-5 containerTitreCarousel">
                                  <p>{item.title}</p>
                                </div>
                                <div className="px-5 containerTitreCarousel">
                                  <p>{item.description}</p>
                                </div>
                                <div className="pb-5 d-flex justify-content-center">
                                  <button className="btn link-header-login">
                                    Contactez-nous
                                  </button>
                                </div>
                          </div>
                      </div>
                );
              })}
            </Carousel>
            </div>
    </section>
  );
}

export default EvenementCarousel;
