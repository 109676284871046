import React from "react";
import * as IconlyPack from "react-iconly";
import NavbarMobileV2 from "../navbarMobileV2/NavbarMobileV2";
import avatar from "../../../../images/others/default-profile-avatar.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import baseUrl from "../../../../Http/backend-base-url";
import {navbarLogo} from "../../../Logo/LogoComponent";
import '../NavbarV2/NavbarV2.css';

const HeaderV2 = ({ activeLink }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  var logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("userToken");
    window.sessionStorage.clear();
    navigate("/");
  };
  return (
    <header>
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top navbar-custom">
        <div className="headering">
          <div className="logo-item-container">
            <div className="logo-container dashboard-header-logo">
            <a className="navbar-brand" href="/">
						  <img src={navbarLogo} alt="logo" className="w-25" />
					  </a>
            </div>
          </div>

          <div className="header-others-items">
            <div className="search-form-container">
              <div className="search-form-content">
                <input
                  name="search"
                  type="search"
                  className="header-form-control-input-search"
                />
                <label>
                  <IconlyPack.Search
                    set="light"
                    className="search-icony"
                    style={{
                      color: "#BDBDBD",
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="notification-item-container linkable no-view-medium">
              <div className="notification-item-content">
                <IconlyPack.Notification
                  set="bold"
                  style={{
                    color: "#C5C7CD",
                  }}
                />
                <span className="has-notification"></span>
              </div>
            </div>
            <div className="connected-user-container no-view-mobile">
              <div className="connected-user-content ">
                <div className="dropdown dis-flex">
                  <button
                    className="user-acount-dashboard-header"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <h6 className="admin-name">
                      {user?.data?.id &&
                        user?.data?.prenom + " " + user?.data?.nom}
                    </h6>
                    <div className="user-acount-dashboard-header-display">
                      <img
                        src={
                          avatar
                        }
                        alt="user-avatar"
                        height="50"
                        width="50"
                        className="admin-avatar"
                      />
                    </div>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-custom"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(e) => logout(e)}
                      className="dropdown-item item-dropdown-custom"
                    >
                      {" "}
                      Déconnexion
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hiddenable-nav-mobile-container no-view-desktop ">
          {/* Navbar Mobile */}
          <div className="hiddenable-nav-mobile-content ">
            <NavbarMobileV2 activeLink={activeLink} />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HeaderV2;
