import api from "../../Http/global-vars";
import { SECTEUR } from "../types";
import axios from "axios";

export const fetchSecteurs = () => {
  return async (dispatch) => {
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    //   },
    // };
    const url = api + "secteurs/";
    await axios
      .get(url)
      .then(async (res) => {
        dispatch({
          type: SECTEUR,
          payload: res?.data.results.sort((a, b) => b?.name - a?.name),
        });
      })
      .catch((error) => console.log("error secteurs", error?.response));
  };
};
