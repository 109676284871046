import React, { useState } from "react";
import { useEffect } from "react";
import ListeDonnateurSkeleton from "./ListeDonnateurSkeleton";
import { parseISO, format } from "date-fns";

const DetailProjetDonneesFinancieres = ({ data, project, isLoading }) => {
  const [isPageLoading, setisPageLoading] = useState(true);
  const [donateurs, setDonnateurs] = useState([])
  useEffect(() =>{
    if(isLoading){
      const timeoutId = setTimeout(() => {
				setisPageLoading(false);
			}, 1000);
			return () => clearTimeout(timeoutId);
    }else{
      setisPageLoading(false);
      setDonnateurs(data);
    }
  },[isLoading, data]);
  return (
    <div
      className="detail-projet-finance-data col-12"
      style={{ width: "100%" }}
    >
      <h1 className="detail-projet-finance-data-title">
        Liste des donateurs ({donateurs.length})
      </h1>
      {isPageLoading && <ListeDonnateurSkeleton />}
      {!isPageLoading && (
        <>
          {
            donateurs.map((donnateur, index) => (
              <div
                className="detail-projet-finance-data-donneur-container"
                key={index}
              >
                <div className="detail-projet-finance-data-donneur-avatar-info">
                  <div className="detail-projet-finance-data-donneur-image-container">
                    <img
                      src={donnateur.avatar}
                      alt="img"
                      className="detail-projet-finance-data-donneur-image"
                    />
                  </div>
                  <div className="detail-projet-finance-data-donneur-info">
                    <h2 className="detail-projet-finance-data-donneur-name">
                      {donnateur.investisseur.prenom}{" "}
                      {donnateur.investisseur.nom}
                    </h2>
                    <h2 className="detail-projet-finance-data-donneur-date-donnation">
                      {format(parseISO(donnateur.created_at), "yyyy/MM/dd")}
                    </h2>
                  </div>
                </div>
                <div className="detail-projet-finance-data-donneur-montant-donnation">
                  <h6 className="detail-projet-finance-data-donneur-montant-donnation">
                    {donnateur.montant} FCFA
                  </h6>
                </div>
              </div>
            ))
          }
        </>
      )}
    </div>
  );
};

export default DetailProjetDonneesFinancieres;
