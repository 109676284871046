import React, { useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

import "./QuestionaireProjet.css";
import { Paper } from "react-iconly";

const DocumentAJoindre = ({ formData, clearErrors, formState, setValue }) => {
  // specify upload params and url for your files
  // const getUploadParams = () => {
  //   return { url: "https://httpbin.org/post" };
  // };
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    // console.log(status, meta, file);
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text =
      files.length === 0 ? (
        // 'Ajouter  des photos' :
        <div className="start-label-input-choose-file">
          <div className="label-img-container">
            <Paper />
          </div>
          <span className="first_label-input-choose-file text-black-50">
            Déposez les documents (maximum 5 fichiers) ici ou sélectionnez un
            fichier.
          </span>
          <span className="second-label-input-choose-file">
            Format acceptés : Word, Pdf inférieurs à 2Mo.
          </span>
        </div>
      ) : (
        <div
          className="row"
          style={{ cursor: "pointer", float: "right", color: "white" }}
          onClick={(e) => e.preventDefault()}
        >
          Ajouter
        </div>
      );
    let buttonStartStyle = {
      background: "#115c67",
      cursor: "pointer",
      padding: 15,
      borderRadius: 10,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "var(--primaryColor)",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="upload-dropzone-row row">
        <div style={buttonStartStyle} className="col-md-10 offset-md-1">
          {/* {text} */}
          <label htmlFor="dropzoneImage">{text}</label>
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            name="image"
            multiple
            data-testid="documentsId"
            // defaultValue={formData?.documents}
            accept={accept}
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
                // console.log("choose files", chosenFiles);
                setValue("documents", chosenFiles);
              });
            }}
            id="dropzoneImage"
          />
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (formData?.documents?.length > 0) {
      setValue("documents", formData.documents);
    }
  }, [formData, setValue]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [clearErrors, formState]);

  return (
    <div className="component-front-home">
      <div className="container mb-5 marge-questionaire-projet">
        <div className="position-relative">
          <h3>
            14. Joindre des documents (Teaser, BP, Pitch deck…){" "}
            <span className="text-danger">*</span>
          </h3>
        </div>
        <div className="form-group-add-project-group-container row mt-5 pt-5">
          <div className="form-add-project-group form-group-add-project-dropzone-container pl-3">
            <Dropzone
              // getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              // multiple={true}
              accept="application/pdf,.doc,.docx,.ppt"
              maxFiles={5}
              SubmitButtonComponent={null}
              InputComponent={InputChooseFile}
              getFilesFromEvent={getFilesFromEvent}
              initialFiles={formData?.documents || []}
              inputWithFilesContent={(files) => {
                // console.log("files", files);
                // if (files?.length > 0) {
                //   setValue("documents", files);
                // } else {
                //   setValue("documents", "");
                // }
              }}
              styles={{
                dropzone: {
                  minHeight: 150,
                  // maxHeight: 300,
                  border: "2px dashed #d9d9d9",
                  background: "#F3F3F3",
                  overflow: "hidden",
                },
              }}
            />
            {formState.errors && formState.errors.documents && (
              <div className="alert alert-danger gfa-alert-danger" role="alert">
                {formState.errors.documents?.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentAJoindre;
