export function getData() {
    let countries = 'S1,S2,S3,S4,S5,S6,S7,S8,S9,S10,S11,S12'.split(','), data = [];
    //
    for (let i = 0; i < countries.length; i++) {
        data.push({
            country: countries[i],
            contribution: 1000 + Math.random() * 10000,
            sponsors: 1000 + Math.random() * 5000,
            tuteur: Math.round(Math.random() * 100000)
        });
    }
    //
    return data;
}
