import React, { useState } from "react";
import "./ListeDesProjet.css";
// import {Notification } from "react-iconly";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ListProjetSkeleton from "../PageAccueil/ProjetsEnLigne/ListProjetSkeleton";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import ProjetImg from "../../../images/image2.png";
import { useEffect } from "react";
import { fetchProjectsForVisitors, filterProjectsForVisitors } from "../../../redux/projectsForVisitors/ProjectForVisitorAction";
import Risque from "../../Modules/RisqueInvestissement/Risque";

const ListeDesProjet = () => {
  const options = [
    {
      id: "1",
      label: "Tous les projets",
      value: "",
    },
    {
      id: "2",
      label: "Actions",
      value: "action",
    },
    {
      id: "3",
      label: "Prêt / Obligations",
      value: "obligation",
    },
    {
      id: "4",
      label: "Donation",
      value: "don",
    },
  ];
  const optionsStades = [
    {
      id: "1",
      label: "Tous les stades",
      value: "",
    },
    {
      id: "2",
      label: "Amorçage",
      value: "amorçage",
    },
    {
      id: "3",
      label: "Early stage",
      value: "early_stage",
    },
    {
      id: "4",
      label: "Pre-seed",
      value: "pre_seed",
    },
  ];
  const projectsForVisitors = useSelector((state) => state.projectsForVisitors);
  const dispatch = useDispatch();
  const { isLoading, filteredData } = projectsForVisitors;
  const [error, setError] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(true);
  
  const refreshProjectsData = () => {
    dispatch(fetchProjectsForVisitors());
    window.scrollTo(0, 0);
  };
  
  useEffect(() => {
    refreshProjectsData();
  }, []);

  const handleChangeType = (e) => {
    console.log("e", e.target.value);
    dispatch(
      filterProjectsForVisitors(projectsForVisitors?.data, e.target.value)
    );
  };

  return (
    <div className="component-liste-projet">
      <FrontHeaderV2 active={2} />
      <div className="content-view-start">
        <div className="banner-liste-projet text-center">
          
          <h3 className="titre-banner-liste-projet">
            Vous souhaitez <span className="cv-greening">investir</span> ou
            rechercher des <span className="cv-greening">fonds</span> ?
          </h3>
        </div>
        <div className="container">
          {!isLoading && (
            <div
              className="row d-flex flex-column flex-md-row"
              style={{ margin: "5rem" }}
            >
              <label htmlFor="" className="filterLabel mr-5 mt-2 pl-2">
                Filtrer
              </label>
              <div className="d-flex  flex-md-row flex-wrap align-items-Center">
                <div className="project-filter-group mr-4">
                  <label htmlFor="TypeProject" className="label-project-filter">
                    Types de projets
                  </label>
                  <div className="position-relative mr-3 mb-3">
                    <select
                      name="TypeProject"
                      id=""
                      className="selectInput px-3"
                      onChange={handleChangeType}
                    >
                      {options.map((option, index) => (
                        <option value={option?.value} key={index}>
                          {option?.label}
                        </option>
                      ))}
                    </select>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="position-absolute d-block"
                      style={{
                        top: "1.8rem",
                        right: "1rem",
                      }}
                    >
                      <path
                        d="M10.6667 1.66699L6 6.33366L1.33334 1.66699"
                        stroke="#200E32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="project-filter-group">
                  <label
                    htmlFor="stadeOfDevelopment"
                    className="label-project-filter"
                  >
                    Stade de développement
                  </label>
                  <div className="position-relative mr-3">
                    <select
                      name="stadeOfDevelopment"
                      id=""
                      className="selectInput px-3"
                      onChange={handleChangeType}
                    >
                      {optionsStades.map((option, index) => (
                        <option value={option?.value} key={index}>
                          {option?.label}
                        </option>
                      ))}
                    </select>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="position-absolute d-block"
                      style={{
                        top: "1.8rem",
                        right: "1rem",
                      }}
                    >
                      <path
                        d="M10.6667 1.66699L6 6.33366L1.33334 1.66699"
                        stroke="#200E32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {filteredData.length === 0 && (
                <div className="gfa-rowy m-t-40 m-b-40">
                  <div className="alert alert-warning gfa-warning">
                    Aucun projet ne correspond à vos critères de filtrage.
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="">
          <div className="row mt-4 project-list-container">
            {isLoading && <ListProjetSkeleton />}
            {!isLoading &&
              filteredData.map((project) => {
                const projectInvestisseurs = project.projet_invest || [];
                const projectMontantsInvestis = projectInvestisseurs.map(
                  (investisseur) => investisseur.montant || 0
                );
                const projectTotalInvestment = projectMontantsInvestis.reduce(
                  (acc, current) => {
                    const currentNumber = parseFloat(current) || 0;
                    return acc + currentNumber;
                  },
                  0
                );
                const projectObjectifCollecte = project.fonds_supplementaires_prix || 0;
                const projectPourcentage = Math.min(Math.floor(
                  (projectTotalInvestment * 100) / projectObjectifCollecte
                ), 100);
                return(
                <div
                  className="col-md-4 project-item-container"
                  key={project.id}
                >
                  <Link
                    to={{ pathname: `/projet/${project.slug}`, state: project }}
                    key={project.id}
                  >
                    <div className="project-item-global-card">
                      <div className="card bg-white project-item-card">
                        <div className="project-item-card-img-container">
                          <img
                            src={ProjetImg}
                            className="card-img-top project-item-img"
                            alt="Projet Item"
                          />
                        </div>
                        <div className="project-funding-type-label-container">
                          <div
                            className={`project-funding-type ${
                              project?.mode_financement === "obligation"
                                ? "don-item"
                                : project?.mode_financement === "action"
                                ? "equity-item"
                                : project?.mode_financement === "don"
                                ? "dette-item"
                                : "dette-item"
                            }`}
                          >
                            {project?.mode_financement === "obligation"
                              ? "Prêt / Obligations"
                              : project?.mode_financement === "action"
                              ? "Action"
                              : project?.mode_financement === "don"
                              ? "Donation"
                              : "Autre"}
                          </div>
                          <div className="project-funding-label amorcage-item">
                            {project?.stade_development === "pre_seed"
                              ? "Pre Seed"
                              : project?.stade_development === "amorçage"
                              ? "Amorçage"
                              : project?.stade_development === "early_stage"
                              ? "Early Stage"
                              : "Non choisi"}
                          </div>
                        </div>
                        <div className="card-body bg-white project-item-card-body">
                          <div className="d-flex justify-content-between project-statistic-container">
                            <div className="project-funded-pourcentage">
                            <p>{projectPourcentage}%</p>
                            </div>
                            <div className="project-funding-container">
                              <p>
                                { project.fonds_supplementaires_prix ? project.fonds_supplementaires_prix : "Montant non défini"}{" "}
                                FCFA
                              </p>
                            </div>
                          </div>
                          <div className="progress project-progress-container">
                          <div
                          className="progress-bar project-project-bar"
                          role="progressbar"
                          style={{
                            width: `${
                              projectPourcentage || 0
                            }%`, // Set the width dynamically based on the calculated percentage
                          }}
                          aria-valuenow={projectPourcentage || 0}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                          </div>

                          <div className="d-flex justify-content-between">
                            <div className="project-dates-container">
                              <p>
                                {project.created_at
                                  ? moment(project.created_at).format("LL")
                                  : "(Date non communiqué)"}{" "}
                                au &nbsp;
                                {project.date_lancement
                                  ? moment(project.date_lancement).format("LL")
                                  : "(Date non communiqué)"}
                              </p>
                            </div>
                            <div className="project-funding-donor-counting">
                              <p>
                                {projectInvestisseurs.length}
                                &nbsp; participations
                              </p>
                            </div>
                          </div>
                          <h5 className="card-title mt-3 project-item-name">
                            {project.title}
                          </h5>
                          <div className="project-description-container">
                            <p>{project.description.slice(0, 400)}</p>
                          </div>
                          <div className="py-1 text-center mt-1 fund-project-btn-container">
                            <Link
                              to={{
                                pathname: projectPourcentage >=100 ? "" : `/projet/${project.slug}`,
                                state: project,
                              }}
                              className={`btn ${projectPourcentage >= 100 ? 'disabled-btn bg-secondary' : 'fund-project-btn'}`}
                              disabled={projectPourcentage >= 100}
                            >
                              {projectPourcentage >= 100 ? "Déjà financé" : "Financer ce projet"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>)
              })}
          </div>
          </div>
        </div>
        <div className="container"> <Risque/></div>
      </div>
      <FrontFooterV2 />
    </div>
  );
};

export default ListeDesProjet;
