/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import "./ParametreAdmin.css";
import Listeadmin from "./ListeAdmin";
import Listeporteur from "./ListePorteur";
import HeaderV2 from "../Navs/HeaderV2";
import NavbarV2 from "../NavbarV2/NavbarV2";
import Ajouterunadministrateurmodal from "../../../Modals/AjouterAdministrateurModal";
import AjouterSecteurActiviteModal from "../../../Modals/AjouterSecteurActiviteModal";
import Listeporteurprojetadminmodal from "../../../Modals/ListePorteurProjetAdminModal";
import { useSelector } from "react-redux";
import UseEditParam from "./UseEditParam";
import ListSecteur from "./ListSecteur";

const Parametreadmin = () => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    clearErrors,
  } = UseEditParam();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    if (user?.data?.id) {
      setValue("prenom", user?.data?.prenom);
      setValue("nom", user?.data?.nom);
      setValue("email", user?.data?.email);
      setValue("phone", user?.data?.phone);
      setValue("adresse", user?.data?.adresse);
      register("id");
      setValue("id", user?.data?.id);
    }
  }, [user]);

  return (
    <div className="users-container">
      <HeaderV2 />
      <div className="bloc-principal-navbar-container">
        <div className="navbar-container-box">
          <NavbarV2 />
        </div>
        <div className="bloc-principal-body-container">
          <div className="projets-container bloc-principal-body-content-profile">
            <div className="profile-containers">
              <h1 className="mon-profil">Parametre</h1>
              <form className="form-input-profile" onSubmit={onSubmit}>
                {/* <button
                  type="button"
                  className="btn btn-parametre-admin float-right"
                  data-toggle="modal"
                >
                  <EditTwoTone className="btn-parametre-icon" />
                  Editer
                </button> */}

                <div className="container-input-address">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Nom</label>
                        <input
                          type="text"
                          className="form-control-profil"
                          name="nom"
                          {...register("nom")}
                          placeholder="Nom"
                        />
                        {formState?.errors && formState?.errors?.nom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.nom?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Prenom</label>
                        <input
                          type="text"
                          {...register("prenom")}
                          className="form-control-profil"
                          name="prenom"
                          placeholder="Prénom"
                        />
                        {formState?.errors && formState?.errors?.prenom && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.prenom?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Telephone</label>
                        <input
                          type="tel"
                          name="phone"
                          className="form-control-profil"
                          placeholder="Téléphone"
                          {...register("phone")}
                        />
                        {formState?.errors && formState?.errors?.phone && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.phone?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Email</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control-profil"
                          placeholder="email"
                          {...register("email")}
                          readOnly
                        />
                        {formState?.errors && formState?.errors?.email && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.email?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="infos-perso-input">
                        <label className="form-label-text">Addresse</label>
                        <input
                          type="Addresse"
                          className="form-control-profil"
                          placeholder="Adresse"
                          name="adresse"
                          {...register("adresse")}
                        />
                        {formState?.errors && formState?.errors?.adresse && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {formState?.errors?.adresse?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end flex-column">
                  <div className="ms-auto">
                    {/* <button type="button" className="btn-modifier">
                      Modifier
                    </button> */}
                    {isSuccessfullySubmitted && (
                      <button className="btn-modifier" disabled>
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                    {!isSuccessfullySubmitted && (
                      <button className="btn-modifier">Modifier</button>
                    )}
                  </div>
                </div>
              </form>{" "}
            </div>
            {/* -----------------Liste Administrateur---------------------- */}
            <div className="admin-container">
              <h1 className="mon-profil">Administrateur</h1>
              <button
                type="button"
                className="btn btn-parametre-admin float-right"
                data-toggle="modal"
                data-target="#ajouterunadministrateur"
              >
                <AddCircleOutlineIcon className="btn-parametre-icon" />
                Ajouter un administrateur
              </button>

              <Listeadmin />
            </div>
            {/* -----------------Ajout du secteur---------------------- */}
            <div className="admin-container">
              <h1 className="mon-profil">Secteur d'activité</h1>
              <button
                type="button"
                className="btn btn-parametre-admin float-right"
                data-toggle="modal"
                data-target="#ajouterunsecteurActivite"
              >
                <AddCircleOutlineIcon className="btn-parametre-icon" />
                Ajouter un secteur d'activité
              </button>
              <ListSecteur />
            </div>
            {/* -----------------Liste porteur de projet---------------------- */}
            <div className="liste-porteur-projet-container">
              <h1 className="mon-profil">Liste des porteurs de projets</h1>
              <button
                type="button"
                className="btn btn-parametre-admin float-right"
                data-toggle="modal"
                data-target="#ajouterunporteur"
              >
                <AddCircleOutlineIcon className="btn-parametre-icon" />
                Ajouter un porteur de projets
              </button>
              <div
                className="modal fade"
                id="ajouterunporteur"
                tabIndex="-1"
                aria-labelledby="ajouterunporteurdeprojet"
                aria-hidden="true"
              >
                <Listeporteurprojetadminmodal />
              </div>{" "}
              <Listeporteur />
            </div>
          </div>
          <div
            className="modal fade"
            id="ajouterunadministrateur"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ajouterunadministrateur"
            aria-hidden="true"
          >
            {" "}
            <Ajouterunadministrateurmodal />
          </div>
          <div
            className="modal fade"
            id="ajouterunsecteurActivite"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="ajouterunsecteurActivite"
            aria-hidden="true"
          >
            {" "}
            <AjouterSecteurActiviteModal />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Parametreadmin;
