import React from "react";
import Skeleton from "react-loading-skeleton";


const DataCenterData1 = [
    {   id:1,
        nom: "Idy Diop",
        price: "1000",
        date: "02/03/2021"
    },
    {   id:2,
        nom: "Maodo Ndiaye",
        price: "1000",
        date: "02/03/2021"
    },
    {   id:3,
        nom: "Jean P. Sagna",
        price: "1000",
        date: "02/03/2021"
    },
    {   id:4,
        nom: "Daba Seck",
        price: "1000",
        date: "02/03/2021"
    },
    {   id:5,
        nom: "Awa Sagna",
        price: "1000",
        date: "02/03/2021"
    },
    {   id:6,
        nom: "Codou Ndiaye",
        price: "1000",
        date: "02/03/2021"
    },
    {   id:7,
        nom: "Pauline Biaye",
        price: "1000",
        date: "02/03/2021"
    },
    
  ];
  

const DataCenterSkeleton = () => {
    
    return(
        <>
            {
                DataCenterData1.map((element) => (
                    <li className="list-group-item info-donnateur" key={element.id}>
                        <div className="userData">
                            <div className='user-photo'>
                                <Skeleton circle="true" style={{borderRadius: "100%", width: "55px", height: "55px"}}/>
                            </div>
                            <div className="user-data">
                                <p>
                                    <span><Skeleton style={{width: "200px"}}/></span>
                                    <span><Skeleton style={{width: "100px" }}/></span>
                                </p>
                            </div>
                        </div>
                        <div className="price">
                            <p><Skeleton style={{width: "100px"}}/></p>
                        </div>
                    </li>
                ))
            }
        </>
    )
}

export default DataCenterSkeleton;