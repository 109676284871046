const ActionsDuMoisTableData = [
    {   id:1,
        projet: 'DATA CENTER MBAO',
        score: 3,
        taux: '50%',
        prete: '20 000 FCFA',
        percu: '5000 FCFA',
        provision: 3,
        en_cours: 3,
    },
    {   id:2,
        projet: 'DATA CENTER MBAO',
        score: 3,
        taux: '50%',
        prete: '20 000 FCFA',
        percu: '5000 FCFA',
        provision: 3,
        en_cours: 3,
    },
    {   id:3,
        projet: 'DATA CENTER MBAO',
        score: 3,
        taux: '50%',
        prete: '20 000 FCFA',
        percu: '5000 FCFA',
        provision: 3,
        en_cours: 3,
    },
    {   id:4,
        projet: 'DATA CENTER MBAO',
        score: 3,
        taux: '50%',
        prete: '20 000 FCFA',
        percu: '5000 FCFA',
        provision: 3,
        en_cours: 3,
    },
    
];



export default ActionsDuMoisTableData;
