import React from 'react'
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function Contact() {
    const defaultProps = {
        center: {
          lat: 14.7410093,
          lng: -17.444037,
        },
        zoom: 11,
      };
  return (
    <div className="container-cayore">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className='p-5 m-5'>
                  <h1 className="titre-a-propos-text">Nous contacter</h1>
                  <div className="">
                    <h3 className="propos-sous-titre">Adresse</h3>
                    <p className="text-ap-propos-text">
                      Hlm Grand Yoff Villa n°241 près du terminus des bus Dakar
                      Dem Dikk qui vont à l'AIBD Dakar 11500
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div>
                  <div
                    style={{
                      height: "100vh",
                      width: "100%",
                    }}
                  >
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "",
                      }}
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent
                        text="Hlm Grand Yoff"
                      />
                    </GoogleMapReact>
                  </div>
                </div>
              </div>
            </div>
          </div>
  )
}

export default Contact