import React from 'react'
import { useStep } from 'react-hooks-helper';
import ChoixAutrePieceTelechargerInscription from '../choixAutrePieceTelechargerInscription/choixAutrePieceTelechargerInscription';
import ConditionUtilisationInscription from '../conditionUtilisationInscription/conditionUtilisationInscription';
import InscrireNumeroTelephoneInscription from '../inscrireNumeroTelephoneInscription/inscrireNumeroTelephoneInscription';
import TelechargerPhotoIdentiteInscription from '../telechargerPhotoIdentiteInscription/telechargerPhotoIdentiteInscription';
import TelechargerPhotoInscription from '../TelechargerPhotoInscription/telechargerPhotoInscription';
import TelechargerRibInscription from '../telechargerRibInscription/telechargerRibInscription';
import TelechargerDocumentInscription from './telechargerDocumentInscription';

const steps = [
    { id: 'telecharger-document-inscription', Component: TelechargerDocumentInscription },
    { id: 'telecharger-photo-inscription', Component: TelechargerPhotoInscription },
    { id: 'choisir-autre-piece', Component: ChoixAutrePieceTelechargerInscription },
    { id: 'telecharger-photo-identite-inscription', Component: TelechargerPhotoIdentiteInscription },
    { id: 'telecharger-rib-inscription', Component: TelechargerRibInscription },
    { id: 'inscrire-numero-telephone-inscription', Component: InscrireNumeroTelephoneInscription },
    { id: 'document-inscription', Component: ConditionUtilisationInscription },
  ];


function DocumentInscription() {

    const { step, navigation } = useStep({ initialStep: 0, steps });
    const { Component } = step;
    const props = {  navigation };
  
    
    return (
        <div>
            <Component {...props} />
        </div>);
}

export default DocumentInscription
