import { Secteur } from './Endpoints'
import axios from 'axios'

export default {
    async onGetListSecteur(config) {
        const response = await axios.get(Secteur.onGetListSecteur(), config);
        // console.log(response)
        return response.data;
    },
    async onAddSecteur(data, config) {
        const response = await axios.post(Secteur.onPostSecteur(), data, config);
        // console.log(response)
        return response.data;
    },
    async onEditSecteur(id, data, config) {
        const response = await axios.put(Secteur.onEditSecteur(id), data, config );
        // console.log(response)
        return response.data;
    },
    async onDeleteSecteur(id,config) {
        const response = await axios.delete(Secteur.onDeleteSecteur(id),config);
        // console.log(response)
        return response.data;
    },
}