import React from "react";

export const QuestionnaireLocalisation = ({
	formData,
	setValue,
	formState,
}) => {
	return (
    <div className="quest-pp-block">
      <h3 className="title-questionnaire">
        1. Votre projet est-il localisé au Sénégal?
        <span className="text-danger">*</span>
      </h3>
      <div className="form-group">
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input"
            id="oui"
            value={true}
            defaultChecked={formData?.senegal === true}
            name="senegal"
            data-testid="senegalId"
            // {...register("senegal")}
            onChange={(e) => setValue("senegal", e.target.value)}
          />
          <label className="quest-label" htmlFor="oui">
            OUI
          </label>
        </div>
        <div className="form-quest-check">
          <input
            type="radio"
            className="quest-control-input"
            id="non"
            value={false}
            defaultChecked={formData?.senegal === false}
            name="senegal"
            data-testid="senegalId"
            // {...register("senegal")}
            onChange={(e) => setValue("senegal", e.target.value)}
          />
          <label className="quest-label" htmlFor="non">
            NON
          </label>
        </div>
      </div>
      {formState.errors && formState.errors.senegal && (
        <div className="alert alert-danger gfa-alert-danger" role="alert">
          {formState.errors.senegal?.message}
        </div>
      )}
    </div>
  );
};
