import api from "../../Http/global-vars";
import { FETCH_PROJECTS } from "../types";
import axios from "axios";

export const fetchProjects = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    const url = api + "projet/";
    await axios
      .get(url, config)
      .then(async (res) => {
        // const data = await res.json();
        // console.log("data projects", res);
        dispatch({
          type: FETCH_PROJECTS,
          payload: res?.data.results.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};
