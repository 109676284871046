import axios from "axios";
import api from "../../Http/global-vars";
import {
  FETCH_PROJECTS_FOR_VISITORS,
  FILTER_PROJECTS_FOR_VISITORS,
} from "../types";

export const fetchProjectsForVisitors = () => {
  return async (dispatch) => {
    const url = api + "projetbyvisitor/";

    await axios
      .get(url)
      .then(async (res) => {
        dispatch({
          type: FETCH_PROJECTS_FOR_VISITORS,
          payload: res?.data.results.sort((a, b) => b?.id - a?.id),
        });
      })
      .catch((error) => console.log("error", error?.response));
  };
};

export const filterProjectsForVisitors = (projects, item) => {
  let data = [];
  // eslint-disable-next-line
  data = projects?.filter((project) => project?.mode_financement === item);
  // return "";
  return (dispatch) => {
    dispatch({
      type: FILTER_PROJECTS_FOR_VISITORS,
      payload: {
        items: !item?.length
          ? projects?.sort((a, b) => b?.id - a?.id)
          : data?.sort((a, b) => b?.id - a?.id),
      },
    });
  };
};
