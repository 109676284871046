import axios from "axios";
import api from "../../Http/global-vars";
import { FETCHS_FINISH_PROJECTS_BY_PORTEUR } from "../types";

export const fetchFinishProjectsByPorteur = (id) => {
	return async (dispatch) => {
		const config = {
			headers: {
				Authorization: `Bearer ${window.sessionStorage.getItem(
					"userToken"
				)}`,
			},
		};

		let token = window.sessionStorage.getItem("userToken");
		const url = api + "projet_financesbyporteur/" + id + "/";

		if (token) {
			await axios
				.get(url, config) 
				.then(async (res) => {
					dispatch({
						type: FETCHS_FINISH_PROJECTS_BY_PORTEUR,
						payload: res?.data.results.sort((a, b) =>
							a?.id > b?.id
								? 1
								: a?.id === b?.id
								? 0
								: -1
						),
					});
				})
				.catch((err) => {
					console.log("error", err?.response);
				});
		}
	};
};
