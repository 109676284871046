import React from "react";
import ProjetsEnCoursDeCampagne from "../../../Tables/ProjetsEnCoursDeCampagne/ProjetsEnCoursDeCampagne";

const LesProjetsEnCoursDeCampagnePorteurProjet = () => {
	return (
		<div className="bg-white border p-3">
			<div className="border projets-en-cours px-md-5">
				<h1 className="activite-table-title">
					Les projets en cours de campagne
				</h1>
				<div className="">
					<ProjetsEnCoursDeCampagne />
				</div>
			</div>
		</div>
	);
};

export default LesProjetsEnCoursDeCampagnePorteurProjet;
