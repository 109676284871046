import React from "react";
import ProjetSkeleton from "./ProjetSkeleton";

const ListProjetSkeleton = () => {
	return (
		<>
			<ProjetSkeleton />
			<ProjetSkeleton />
			<ProjetSkeleton />
		</>
	);
};

export default ListProjetSkeleton;
