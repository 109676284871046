import React from "react";

function FaqModals() {
  return (
    <div
      className="modal fade modal-faq"
      id="faqModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content faq-modal-content">
          <div className="modal-header modal-faq-header">
            <h5
              className="modal-title titre-question-reponse"
              id="exampleModalLabel"
            >
              Questions/Réponses
            </h5>
            <button
              type="button"
              className="close close-icon"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group mb-5">
                <label
                  htmlFor="recipient-name"
                  className="col-form-label label-question"
                >
                  Questions
                </label>
                <input
                  type="text"
                  className="form-control input-question"
                  id="recipient-name"
                  placeholder="Ajouter une question"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="message-text"
                  className="col-form-label label-reponse"
                >
                  Réponses
                </label>
                <textarea
                  className="form-control input-reponse"
                  id="message-text"
                  rows="12"
                  placeholder="Ajouter une réponse"
                ></textarea>
              </div>
            </form>
          </div>
          <div className="modal-footer faq-modal-footer">
            <button
              type="button"
              className="btn btn-retour-faq-modal"
              data-dismiss="modal"
            >
              Retour
            </button>
            <button type="button" className="btn btn-envoyer-faq-modal">
              Envoyer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqModals;
