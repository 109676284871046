import React from "react";
import "./FrontHeaderV2.css";
import { NavLink, useNavigate  } from "react-router-dom";
import { User } from "react-iconly";
import { useSelector } from "react-redux";
// import {  } from "react-router-dom";
import {navbarLogo} from "../../Logo/LogoComponent";

const FrontHeaderV2 = (props) => {
	var navigate  = useNavigate ();
	const user = useSelector((state) => state.user);
	const userType = sessionStorage.getItem("userType");
	var logout = (e) => {
		e.preventDefault();
		sessionStorage.removeItem("userToken");
		window.sessionStorage.clear();
		if (sessionStorage.removeItem("userType") === "admin") {
			setTimeout(() => {
				navigate("/");
			}, 1000);
		} else {
			setTimeout(() => {
				navigate("/");
			}, 1000);
		}
		var data = window.sessionStorage.getItem("userType");
		console.log("daadadada", data);
	};
	// console.log(userType);
	return (
		<div className="component-front-header">
			<div
				className="header-scrolling-container fixed-top w-100"
				id="frontHeader"
			>
				<nav className="navbar navbar-expand-lg  container-aem navbar-light header py-4 w-100">
					<a className="navbar-brand" href={!userType === "investisseur" ? "/" : ""}>
						<img src={navbarLogo} alt="logo" className="w-25" />
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarHeaderSecondary"
						aria-controls="navbarHeaderSecondary"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div
						className="collapse navbar-collapse"
						id="navbarHeaderSecondary"
					>
						<ul className="navbar-nav mx-auto py-lg-0 py-4">
							{userType === "investisseur" ? 
							"" :
							(<li className="nav-item nav-african-hot-jobs px-lg-2">
								<NavLink
								className={`nav-link trans-0-2 ${props.active === 1 && 'active'}`}
									to="/"
								>
									Accueil
								</NavLink>
							</li>)}
							{userType === "investisseur" ? 
							(<li className="nav-item nav-african-hot-jobs px-lg-2">
								<NavLink
								className={`nav-link trans-0-2 ${props.active === 6 && 'active'}`}
								to="/dashboard-investisseur"
								>
									Mon
									tableau
									de bord
								</NavLink>
							</li>) : ""
							}
							<li className="nav-item nav-african-hot-jobs px-lg-2">
								<NavLink
									className={`nav-link trans-0-2 ${props.active === 2 && 'active'}`}
									to="/liste-des-projets"
								>
									Les projets
								</NavLink>
							</li>
							{/* <li className="nav-item nav-african-hot-jobs px-lg-2">
								<NavLink
									className="nav-link trans-0-2"
									to="/investir"
								>
									Investir
								</NavLink>
							</li> */}
							{userType === "investisseur" ? "" :
							<li className="nav-item nav-african-hot-jobs px-lg-2">
								<NavLink
								className={`nav-link trans-0-2 ${props.active === 3 && 'active'}`}
									to="/se-financer"
								>
									Se financer
								</NavLink>
							</li>}
							<li className="nav-item nav-african-hot-jobs px-lg-2">
								<NavLink
								className={`nav-link trans-0-2 ${props.active === 4 && 'active'}`}
									to="/comment-ca-marche"
								>
									Comment ça marche ?
								</NavLink>
							</li>
							<li className="nav-item nav-african-hot-jobs px-lg-2">
								<NavLink
								className={`nav-link trans-0-2 ${props.active === 5 && 'active'}`}
									to="/a-propos-de-nous"
								>
									À propos de nous
								</NavLink>
							</li>
						</ul>
						{window.sessionStorage.getItem(
							"userType"
						) ? (
							<div className="">
								{/* A afficher si l'utilisateur n'est pas connecté */}
								{/* <NavLink className="link-header-login trans-0-2 d-block" to="/login" >Connexion</NavLink> */}

								{/* A afficher si l'utilisateur est connecté */}
								<div className="dropdown connexion-dropdown">
									<a
										className="dropdown-toggle trans-0-2"
										href="#dropdown-menu"
										role="button"
										id="connexionDropdown"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span className="name-user-connect">
											{user?.data
												?.id &&
												user
													?.data
													?.prenom +
													" " +
													user
														?.data
														?.nom}
										</span>
										<span className="icon image-responsive mx-2 p-2 ">
											<User
												set="bold"
												primaryColor="#115c67"
											/>
										</span>
									</a>

									<div
										className="dropdown-menu"
										aria-labelledby="connexionDropdown"
										id="dropdown-menu"
									>
										{userType ===
										"investisseur" ? (
											<NavLink
												className="dropdown-item no-bg border-bottom"
												to="/dashboard/editer-mon-profile"
											>
												Compte
											</NavLink>
										) : (
											<NavLink
												className="dropdown-item no-bg border-bottom"
												to="/admin/parametre"
											>
												Paramètre
											</NavLink>
										)}
										{ userType === "investisseur" ? (
											<NavLink
												className="dropdown-item no-bg border-bottom"
												to="/dashboard/activite"
											>
												Mon
												tableau
												de bord
											</NavLink>
										) : (
											<NavLink
												className="dropdown-item no-bg border-bottom"
												to="/admin/dashboard"
											>
												Mon
												tableau
												de bord
											</NavLink>
										)}
										<NavLink
											className="dropdown-item no-bg"
											to=""
											onClick={(
												e
											) =>
												logout(
													e
												)
											}
										>
											Deconnexion
										</NavLink>
									</div>
								</div>

								<div className="d-md-none d-inline-block mt-4 language-selector-mobile"></div>
							</div>
						) : (
							<div className="auth-btn-container">
								<div className="auth-item login-btn-container">
									<NavLink
										className="link-header-login trans-0-2 d-block mx-2"
										to="/connexion"
									>
										Connexion
									</NavLink>
								</div>
								<div className="auth-container register-btn-container">
									<NavLink
										className="link-header-register trans-0-2 d-block mx-2"
										to="/inscription"
									>
										Inscription
									</NavLink>
								</div>
							</div>
						)}
					</div>
				</nav>
			</div>
		</div>
	);
};

export default FrontHeaderV2;
