import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import HeaderTableauDeBord from "../HeaderTableauDeBord/HeaderTableauDeBord";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import "./DetailProjetEnCoursDeCampagne.css";
import imageCard from "../../../../images/image2.png";
import { useEffect } from "react";
// import Dashboard from "../../InvestisseurTableauDeBord/MonTableauDeBord/Dashboard";

const data = [
	{
		image: imageCard,
		cardTitle: "Data Center Mbao",
		cardBody: " Lorem ipsum dolor sit amet, consectetur adipiscing elit.Condimentum sed rhoncus odio eget aliquet. Duis nisi quis justo etiam massa vitae.",
		launchDate: "27/11/2021",
		lauchHour: "12:21",
	},
	{
		image: imageCard,
		cardTitle: "Data Center Mbao",
		cardBody: " Lorem ipsum dolor sit amet, consectetur adipiscing elit.Condimentum sed rhoncus odio eget aliquet. Duis nisi quis justo etiam massa vitae.",
		launchDate: "27/11/2021",
		lauchHour: "12:21",
	},
	{
		image: imageCard,
		cardTitle: "Data Center Mbao",
		cardBody: " Lorem ipsum dolor sit amet, consectetur adipiscing elit.Condimentum sed rhoncus odio eget aliquet. Duis nisi quis justo etiam massa vitae.",
		launchDate: "27/11/2021",
		lauchHour: "12:21",
	},
];

export const DetailProjetEnCoursDeCampagne = () => {
	const location = useLocation()
	const [project, setProject] = useState()
	console.log(project)
	useEffect(() => {
		if(location?.state?.project?.id) {
			setProject(location.state.project)
		}
	}, [location])
	return (
		<div className="component-mes-projet-tableau-de-bord">
			<HeaderTableauDeBord />
			<div className="content row px-2 mt-3 mb-3 d-flex py-0">
				<div className="d-flex align-items-lg-center col-12 col-xl-3 col-lg-3">
					{/* <Dashboard/> */}
				</div>
			</div>
			<div className="mt-3 row p-0 bloc-principal-detail-projet">
				<div className="col-lg-4 col-xl-3">
					<SidebarTableauDeBord value={1} />
				</div>
				<div className="col-lg-8 col-xl-9">
					<div className="title-block">
						<h1 className="px-2">Mon activité</h1>
					</div>
					<div className="detail-projet-en-cours-de-campagne">
						{data.map((cardDetailProjet, index) => (
							<div
								className="detail-projet-en-cours-de-campagne-card"
								key={index}
							>
								<div className="detail-projet-en-cours-de-campagne-card-info">
									<div className="detail-projet-en-cours-de-campagne-card-img">
										<img
											src={
												cardDetailProjet.image
											}
											alt="imageprojet"
										/>
									</div>
									<div className="detail-projet-en-cours-de-campagne-card-text">
										<h1 className="detail-projet-en-cours-de-campagne-title m-0">
											{
												cardDetailProjet.cardTitle
											}
										</h1>
										<div className="detail-projet-en-cours-de-campagne-card-body">
											<p className="">
												{
													cardDetailProjet.cardBody
												}
											</p>
										</div>
									</div>
								</div>
								<div className="detail-projet-en-cours-de-campagne-card-date">
									<h6>
										lancé le{" "}
										{
											cardDetailProjet.launchDate
										}{" "}
										à
										{
											cardDetailProjet.lauchHour
										}
									</h6>
								</div>
								<div className="detail-projet-en-cours-de-campagne-card-links">
									<NavLink
										to="/projet/data-center-mbao"
										className="detail-projet-en-cours-de-campagne-card-link"
									>
										Voir la page projet
									</NavLink>
								</div>
							</div>
						))}
					</div>
					{/* <div className="p-3 my-5 bg-white">
						<div className="border projets-en-cours px-5">
							<h1 className="activite-table-title">
								Mes investissements
							</h1>
							<div className="">
								<MesInvestissement />
							</div>
						</div>
					</div> */}
				</div>
			</div>
			<FrontFooterV2 />
		</div>
	);
};
