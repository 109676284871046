import React from 'react'
import { NavLink } from 'react-router-dom'
// import Dashboard from '../../MonTableauDeBord/Dashboard'

const TabsProfile = () => {
  return (
    <div className="content row px-2 mt-3 mb-3 d-flex py-0">
    <div className="d-flex align-items-lg-center col-12 col-xl-3 col-lg-3">
      {/* <Dashboard/> */}
    </div>
    <div className="d-flex align-item-end col-12 col-lg-9 col-xl-9 ">
      <ul className="d-flex flex-column flex-md-row align-items-md-end justify-content-between justify-content-xl-around p-5 col-12 ">
        <NavLink
          to="/dashboard-investisseur/profil"
          className="navLink"
          activeClassName="actived"
        >
          Editer mon profil
        </NavLink>
        <NavLink
          to="/dashboard/validation-identite"
          className="navLink"
          activeClassName="actived"
        >
          Identité
        </NavLink>
        <NavLink
          to="/dashboard-investisseur/changer-mot-de-passe"
          className="navLink"
          activeClassName="actived"
        >
          Modifier mot de passe{" "}
        </NavLink>
      </ul>
    </div>
  </div>
  )
}

export default TabsProfile