const ActionsInvestisseurTableData = [
    {   id:1,
        projet: 'Data center Mbao',
        nombre_action: 10,
    },
    {   id:2,
        projet: 'Data center Mbao',
        nombre_action: 3,
    },
    {   id:3,
        projet: 'Data center Mbao',
        nombre_action: 5,
    },
    {   id:4,
        projet: 'Data center Mbao',
        nombre_action: 10,
    },
    
];



export default ActionsInvestisseurTableData;
