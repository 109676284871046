import React from "react";
import CalendrierRemboursementTable from "../../../Tables/FrontEnd/CalendrierRemboursementTable/CalendrierRemboursementTable";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import FrontHeaderV2 from "../../FrontHeaderV2/FrontHeaderV2";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import TabsLinkActivite from "../TabsLinkActivite/TabsLinkActivite";

const RemboursementPorteurProjet = () => {
	return (
		<div className="component-mes-projet-tableau-de-bord">
			<FrontHeaderV2 />
			<TabsLinkActivite />
			<div className="row activite pr-5">
				<div className="col-xl-3 col-lg-4">
					<SidebarTableauDeBord value={1} />
				</div>
				<div className="col-xl-9 col-lg-8">
					<div className="title-block">
						<h1 className="px-2">Mon activité</h1>
					</div>
					<div className="calendrier-de-remboursement bg-white px-5 mt-3 pt-3">
						<h1 className="activite-table-title">
							Calendrier de remboursement
						</h1>
						<div className="">
							<CalendrierRemboursementTable />
						</div>
					</div>
				</div>
			</div>
			<FrontFooterV2 />
		</div>
	);
};

export default RemboursementPorteurProjet;
