import React from "react";
import { ArrowLeft } from "react-iconly";
import "./QuestionaireProjet.css";

const RemplireLeFormulaireDuDepot = ({ navigation, fillFormulaire }) => {
  return (
    <div className="component-front-home">
      <div className="container mb-5 marge-questionaire-projet">
        <div className="position-relative">
          <button
            className="arow-pos design-boutton"
            onClick={navigation?.previous}
          >
            <ArrowLeft set="light" primaryColor="#115c67" size="medium" />
          </button>
          <div className="pl-5">
          <h3 className="title-questionnaire">
            6. Maintenant que nous avons identifié votre solution ensemble vous
            pouvez remplir le formulaire de dépot de dossier
          </h3>
          </div>
        </div>

        <div className="mt-input-part-project mb-input-part-project">
          <button
            className="btn-se-financer-part a-link"
            onClick={navigation?.next}
            data-testid="btnId"
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemplireLeFormulaireDuDepot;
