import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate , useLocation } from "react-router-dom";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import HeaderTableauDeBord from "../HeaderTableauDeBord/HeaderTableauDeBord";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import TabsLink1 from "../TabsLinkProjets/TabsLinkProjets";
import DataCenter from "./DataCenter/DataCenter"
import "./Projets.css";

const DetailsProjetTableauDeBord = () => {
  const [project, setProject] =  useState()
  console.log(project)
  const location = useLocation()
  const navigate  = useNavigate ()
  useEffect(() => {
    if(location?.state?.project?.id) {
      setProject(location.state.project)
    } else {
      setTimeout(() => {
        navigate()
      }, 100)
    }
  },[location, navigate ])
	return (
        <div className="component-mes-projet-tableau-de-bord">
          <HeaderTableauDeBord />

          <TabsLink1 />
          <div className="mt-3 row p-0">
            <div className="col-12 col-lg-4 col-xl-3 p-0">
              <SidebarTableauDeBord value={2}/>
            </div>
            <div className="col-12 col-lg-8 col-xl-9  mt-3 mt-lg-0">
                <DataCenter project={project}/>
            </div>
          </div>
          <FrontFooterV2 />
        </div>
	);
};

export default DetailsProjetTableauDeBord;
