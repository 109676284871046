import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { projectsReducer } from "./projects/projectReducer";
import { userReducer } from "./user/userreducer";
import { secteurReducer } from "./secteurs/secteurReducer";
import { projectsForVisitorsReducer } from "./projectsForVisitors/ProjectForVisitorReducer";
import { usersReducer } from "./users/usersReducer";
import { contactReducer } from "./contacts/contactReducer";
import { adminsReducer } from "./admins/adminsreducer";
import { projectInCampaignReducer } from "./ProjetsEnCoursDeCampagne/ProjectInCampaignReducer";
import { projectsByPorteurReducer } from "./projectsByPorteur/ProjectForByPorteurReducer";
import { finishProjectByPorteurReducer } from "./ProjectsFinishByPorteur/ProjectsFinishByPorteurReducer";
import {investisseurReducer} from "./investisseurs/investisseurReducer";

const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(
  combineReducers({
    projects: projectsReducer,
    user: userReducer,
    secteurs: secteurReducer,
    projectsForVisitors: projectsForVisitorsReducer,
    users: usersReducer,
    contacts: contactReducer,
    admins: adminsReducer,
    projectsInCampaign: projectInCampaignReducer,
    projectsByPorteur: projectsByPorteurReducer,
    finishProjectByPorteur: finishProjectByPorteurReducer, 
    investisseurs: investisseurReducer,

  }),
  composedEnhancers
);

export default store;
