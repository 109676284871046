import React from "react";
import "./HowItWorks.css";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import VideoPlayer from "react-video-js-player";
import VideoPm from "../../../images/Volkeno.mp4";
import DetailProjetVideo from "../../../images/detail.png";
import iconmarche1 from "../../../assets/images/icons/iconemarche1.png";
import crowdfunnig from "../../../assets/images/crowdfunding.png";
import politiqueDeRisque from "../../../assets/images/politique-de-risque.png";
import barmarche from "../../../assets/images/icons/barmarche.png";
import barmarche1 from "../../../assets/images/icons/barmarche1.png";
import barmarche2 from "../../../assets/images/icons/barmarche2.png";
import TableComparatifGlobal from "./TableComparatifGlobal";
import HowItWorksSteps from "./HowItWorksSteps";
import { useEffect } from "react";
import Risque from "../../Modules/RisqueInvestissement/Risque";

const HowItWorks = () => {
  const onPlayerReady = (player) => {
    // console.log("Player is ready: ", player);
    // player = player;
  };

  const onVideoPlay = (duration) => {
    // console.log("Video played at: ", duration);
  };

  const onVideoPause = (duration) => {
    // console.log("Video paused at: ", duration);
  };

  const onVideoTimeUpdate = (duration) => {
    console.log("Time updated: ", duration);
  };

  const onVideoSeeking = (duration) => {
    // console.log("Video seeking: ", duration);
  };

  const onVideoSeeked = (from, to) => {
    // console.log(`Video seeked from ${from} to ${to}`);
  };

  const onVideoEnd = () => {
    // console.log("Video ended");
  };

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className="component-About-projet">
      <FrontHeaderV2 active={4} />
      <div className="content-view-start">
        <div className="page-title-container banner-liste-projet">
          <h3 className="aprops-page-title">Comment ça marche ?</h3>
        </div>
        <div className="marge-container-description-accueil">
          <p className="nous-sommes-title pt-4">Nous sommes tout à la fois :</p>
          <div className="container-cayore pb-5 pb-5">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="text-center">
                  <div className="text-center">
                    <img
                      src={iconmarche1}
                      alt="Icon"
                      className="img-icon-banniere"
                    />
                  </div>
                  <div>
                    <p className="couverture-description-text-color">
                      Une{" "}
                      <span className="text-bold">
                        plateforme sénégalaise <br />
                        de financement participatif{" "}
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="text-center">
                  <div>
                    <img
                      src={iconmarche1}
                      alt="Icon"
                      className="img-icon-banniere"
                    />
                  </div>
                  <div>
                    <p className="couverture-description-text-color">
                      Un{" "}
                      <span className="text-bold">
                        investisseur minoritaire{" "}
                      </span>
                      <br />
                      dans chacun des projets présentés
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ccm-section-containers">
          <div className="video-ccm-container pt-4">
            <VideoPlayer
              controls={true}
              src={VideoPm}
              poster={DetailProjetVideo}
              height="420"
              autoplay={false}
              onReady={onPlayerReady()}
              onPlay={onVideoPlay()}
              onPause={onVideoPause()}
              onTimeUpdate={onVideoTimeUpdate()}
              onSeeking={onVideoSeeking()}
              onSeeked={onVideoSeeked()}
              onEnd={onVideoEnd()}
              className="w-full ccm-video"
            />
          </div>
        </div>
        <div className="container-detail-comment-ca-marche m-b-100">
          <div className="step-how-it-works-container container-financement-participatif">
            <div className="container-cayore">
              <div className="container-fluid">
                <h1 className="step-how-it-works-title">
                  1. Qu’est-ce que le financement participatif ?
                </h1>
                <div className="row step-how-it-works-content">
                  <div className="col-md-5">
                    <div className="step-how-it-works-text-container">
                      <p className="step-how-it-works-text">
                        Le crowdfunding, ou financement participatif, est un
                        mécanisme de levée de fonds, qui repose sur le recours à
                        une communauté d’investisseurs pour financer le
                        développement d’un projet. Il vise notamment à
                        démocratiser l’accès au financement de l’économie pour
                        les particuliers et représente ainsi l’une des rares
                        alternatives aux circuits de financement traditionnels.
                        Les plateformes de crowdfunding fonctionnent comme des
                        intermédiaires entre porteurs de projets et
                        investisseurs particuliers.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 offset-md-2">
                    <img
                      src={crowdfunnig}
                      alt="politique-de-risque"
                      className="step-how-it-works-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="step-how-it-works-container container-cayore">
          <div className="container-fluid">
            <h1 className="step-how-it-works-title container-cayore">
              2. Les types d’investissement
            </h1>
            <div className="container-cayore">
              <p className="step-how-it-works-text">
                Le financement participatif en investissement consiste en une
                prise de participation financière au sein d'une entreprise, qui
                peut prendre plusieurs formes.
              </p>
            </div>
            <div className="row step-how-it-works-type-content">
              <div className="col-md-10">
                <div className="type-investment-title-container container-cayore">
                  <div className="type-investment-title-number">01</div>
                  <div className="type-investment-title">En Actions</div>
                </div>
                <div className="step-how-it-works-type-text-container">
                  <p className="step-how-it-works-type-text">
                    L'entreprise ouvre son capital à des investisseurs
                    (particuliers ou entreprises) pour une durée déterminé (4 à
                    7 ans environ). Les dirigeants gardent le contrôle de la
                    société et restent majoritaires de leur entreprise. Au terme
                    des 4 à 7 ans, les investisseurs revendent leurs actions et
                    récupèrent une plus ou moins value en fonction de la
                    performance de l'entreprise. L'ensemble des modalités sont
                    prévues par un pacte d'actionnaires rédigé en collaboration
                    avec Cayor. Le pacte d'actionnaires doit être équilibré :
                    attractif pour les investisseurs et juste pour les
                    dirigeants de l'entreprise.
                  </p>
                </div>
              </div>
              <div className="col-md-10">
                <div className="type-investment-title-container container-cayore">
                  <div className="type-investment-title-number">02</div>
                  <div className="type-investment-title">Prêt obligataire</div>
                </div>
                <div className="step-how-it-works-type-text-container">
                  <p className="step-how-it-works-type-text">
                    L'entreprise emprunte auprès d'investisseurs (particuliers
                    ou entreprises) qui deviennent des porteurs d'obligation.
                    Ils jouent ainsi le rôle d'une banque en prêtant une somme
                    d'argent qui sera rémunérée. Il n'y a pas d'ouverture de
                    capital. Le contrat obligataire précise les modalités à
                    savoir : le taux d'intérêt qui est fixe (5% à 10% selon les
                    opportunités) et ne peut varier au cours du contrat la durée
                    (1 à 5 ans) qui est fixe et ne peut varier au cours du
                    contrat le versement des intérêts : ceux-ci peuvent être
                    versés selon une échéance définie (trimestre, semestre,
                    année...) ou bien in fine c'est-à-dire, au terme du contrat.
                    Également, l'échéance peut intégrer une partie du capital.
                    On parle alors d'échéance amortissable (le montant du
                    versement sera le même à chaque fois, les intérêts seront
                    calculés sur la part du capital encore non remboursé).
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="row">
            <div className="col-10">
              <div className="type-investment-title-container container-cayore">
                <div className="type-investment-title-number">03</div>
                <div className="type-investment-title">En Royalties</div>
              </div>
              <div className="container-cayore">
              <h4 className="type-investment-sous-title">
                UN INVESTISSEMENT « HYBRIDE » : PLUS SIMPLE ET MOINS RISQUE
                QUE LE CAPITAL MAIS PLUS AMBITIEUX ET PLUS RISQUE QUE LA DETTE.
              </h4>
              </div>
              <div className="step-how-it-works-type-text-container">
                <p className="step-how-it-works-type-text">
                   L’investissement en échange de
                  royalties est simple et accessible, connecté à l’économie
                  réelle. En échange de votre investissement, vous recevrez une
                  contrepartie financière versée chaque trimestre par
                  l’entreprise financée. Comme les royalties sont directement
                  indexées au chiffre d’affaires, l’investisseur est directement
                  intéressé à la croissance de l’activité de l’entreprise
                  financée ! Les intérêts des parties prenantes sont alignés sur
                  ce même indicateur, ce qui n’est pas le cas du prêt, qui
                  impose à l’entreprise le versement d’une somme fixe
                  indépendamment de son évolution. Vous pouvez par ailleurs
                  avoir un impact direct sur votre rentabilité : un nouveau
                  client trouvé pour l’entreprise financée, c’est un retour sur
                  investissement supplémentaire pour vous dans les 3 mois.
                  Utilisez la puissance de vos réseaux et recommandations. La
                  sortie de ce contrat se fait généralement au bout de cinq ans.
                  À ce moment-là, vous aurez récupéré votre investissement et
                  une plus-value plus ou moins importante selon l’évolution du
                  chiffre d’affaires de l’entreprise. L’investissement peut
                  aussi être perdu si l’entreprise cesse son activité, comme
                  pour un investissement en capital avec lequel votre action
                  peut avoir perdu de sa valeur au moment de la revente. La
                  sortie de l’investissement des royalties se fait
                  automatiquement à l’issue des versements et sans effort pour
                  l’investisseur alors que la revente d’une action en capital
                  nécessite du temps et est parfois complexe du fait de la
                  valorisation de l’entreprise et des négociations nécessaires.
                </p>
              </div>
            </div>
          </div> */}
            <div className="row container-cayore">
              <div className="col-md-10">
                <h3 className="type-investment-title-royal mt-3">
                  CAPITAL : DES SOLUTIONS COMPLÉMENTAIRES
                </h3>
                <ul className="step-how-it-works-type-list-container">
                  <li className="step-how-it-works-type-text-royal">
                    L’investissement en royalties n’est pas incompatible avec un
                    investissement en capital : faire un premier investissement
                    en royalties, potentiellement plus modeste, dès maintenant
                    vous permettra de manifester un premier soutien dans un
                    moment-clé pour l’entreprise, en vue d’une prise de
                    participation ultérieure.
                  </li>
                  <li className="step-how-it-works-type-text-royal">
                    Le ticket minimum est par ailleurs souvent plus faible en
                    royalties. Cela vous permettra également de rejoindre
                    l’aventure en douceur et de suivre la croissance de son
                    activité de plus près (la déclaration de chiffre d’affaires
                    a lieu tous les trimestres).
                  </li>
                </ul>
              </div>
            </div>
          </div>
          </div>
          <div className="step-how-it-works-container container-financement-participatif">
          <div className="container-cayore">
            <div className="container-fluid">
              <h1 className="step-how-it-works-title">
                3. QUELS RENDEMENTS ET RISQUENT REPRÉSENTENT LES DIFFERENTS
                INVESTISSEMENTS ?
              </h1>
              <div className="row step-how-it-works-content">
                <div className="col-md-6">
                  <h2 className="step-how-it-works-detail-title-politique">
                    La politique de risque encouru sur les investissements
                  </h2>
                  <div className="step-how-it-works-text-container">
                    <p className="step-how-it-works-text">
                      En cas de grand succès du projet, le mode d’investissement
                      le plus avantageux reste incontestablement le capital. En
                      effet, plus le projet réussit, plus la valeur de votre
                      participation au capital s’envolera. A l’inverse, elle
                      tendra vers zéro si le projet est un échec.
                      <br />
                      Avec les royalties, en cas d’échec le risque sera plus
                      faible qu’en capital car les versements réguliers vous
                      permettent d’éviter la perte totale de votre
                      investissement.
                      <br />
                      En prêtant à un projet, vous limitez le risque par les
                      remboursements réguliers mais vous limitez également votre
                      retour sur investissement potentiel. En effet, quelque
                      soit le niveau de succès du projet, vous récupérerez le
                      même rendement maximal correspondant au taux d’intérêt.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 offset-md-2">
                  <img
                    src={politiqueDeRisque}
                    alt="politique-de-risque"
                    className="step-how-it-works-img"
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="step-how-it-works-container container-cayore">
            <div className="container-fluid">
            <h2 className="tab-slide-title text-center">
              COMPARATIF GLOBAL : CAPITAL / PRÊT
            </h2>
            <div className="table-comparatif-global-container container-cayore table-responsive">
              <TableComparatifGlobal />
            </div>
            <Risque />
            </div>
          </div>
          <div className="mt-5 container-financement-participatif">
            <div className="container-cayore mb-5">
            <div className="container-fluid">
              <h1 className="step-how-it-works-title">
                4. Les règles fondamentales du financement participatif
              </h1>
              <div className="row">
              <div className="col-md-6">
                <div className="img-icon">
                  <img src={barmarche1} alt="..." className="img-secoure" />
                </div>
                <div className="item1 p-3">
                  <h3 className="sub-title-theme">La règle du tout ou rien</h3>
                  <p className="text-paragraph">
                    Chaque projet mis en ligne doit définir un objectif
                    principal de collecte à atteindre dans le temps imparti.
                    S'il y a plusieurs paliers (ou objectifs), la collecte doit
                    atteindre au moins le premier objectif fixé pour recevoir
                    les fonds collectés. Dans le cas contraire, les participants
                    sont intégralement remboursés, aucun frais n'est appliqué au
                    porteur de projet.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img-icon">
                  <img src={barmarche} alt="..." className="img-secoure" />
                </div>
                <div className="item1 p-3">
                  <h3 className="sub-title-theme">Les objectifs de collecte</h3>
                  <p className="text-paragraph">
                    Vous pouvez fixer plusieurs objectifs : le 1er objectif ou
                    montant plancher est le montant minimum à atteindre pour
                    recevoir les fonds les objectifs intermédiaires permettent
                    de continuer à collecter des fonds en montrant au grand
                    public que vos besoins de financement ne sont pas limités :
                    l'argent collecté en plus pourra financer d'autres besoins.
                    Cela motive les contributeurs à continuer à participer même
                    si le 1er objectif a été atteint. vous pouvez fixer un
                    dernier objectif ou montant plafond. Par exemple, dans le
                    cas d'une collecte via une augmentation de capital en
                    actions : vous ne souhaitez pas ouvrir le capital de votre
                    société au-delà d'un certain seuil.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="img-icon">
                  <img src={barmarche2} alt="..." className="img-secoure" />
                </div>
                <div className="item1">
                  <h3 className="sub-title-theme">Un temps imparti</h3>
                  <p className="text-paragraph">
                    Une collecte de financement participatif est toujours
                    organisée selon un délai déterminé, généralement compris
                    entre 30 et 90 jours
                  </p>
                </div>
              </div>
              </div>
            </div>
            </div>
          </div>
          <div className="container-fluid my-5">
            <div className="step-how-it-works-container py-5">
              <h1 className="step-how-it-works-title">
                5. Quels sont les frais liés à l'investissement ?
              </h1>
              <div className="step-how-it-works-text-container ">
                <p className="step-how-it-works-texts">
                  Investir sur Cayor ne comporte aucun frais pour
                  l'investisseur. Cayor ne se rémunère qu'au travers de frais
                  fixes et d’une commission sur le montant de la levée de fonds
                  supportés par l’entreprise porteuse du projet.
                  <br />
                  Il n’y a donc aucun frais lié à votre prise de participation.
                  Elle est entièrement gratuite.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid raise-money-container-home m-b-50">
          <div className="container-cayore m-b-40">
            <h1 className="step-how-it-works-title">
              6. Les différentes étapes pour investir ou lever des fonds
            </h1>
          </div>
          <HowItWorksSteps />
        </div>
      </div>
      <FrontFooterV2 />
    </div>
  );
};

export default HowItWorks;
