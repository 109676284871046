import React from "react";

function LinkReseauSocial(props) {
  return (
    <li className="nav-item">
      <a
        href="/"
        className="d-block p-3 link-dark text-decoration-none"
        title=""
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        data-bs-original-title="Icon-only"
      >
        <img src={props.icon} alt="Facebook" />
      </a>
    </li>
  );
}

export default LinkReseauSocial;
