import React, {} from "react";
import "./VotreProjet.css";
import { NavLink } from "react-router-dom";
import stroke1 from "../../../assets/images/stroke1.png";
// import axios from 'axios';


const VotreProjet = ({navigation}) => {
  //   const { state, dispatch } = useContext(DataContext)
  //   let { appel_offres_front,offre_emplois_front } = state

  //   useEffect(() => {

  //   })
  //  const [isOpen, setOpen] = useState(false);
  return (
    <div className="component-front-home">
      <div className="content-view-start">
        <div className="banner-liste-projet ">
          <NavLink to="/se-financer" className="retour">
            <img src={stroke1} className="pointretour" alt="icon-back"/> Retour
          </NavLink>
          <h3 className="titre-banner-liste-projet">
            Parlez-nous de votre projet
          </h3>
        </div>

        <div className="container-aem my-5">
          <div className="d-flex">
            <div className="py-5">
              <div className=" border-0">
                <h1 className="titreH1 ml-5">
                  Prenez une minute pour répondre au questionnaire suivant afin
                  de:
                </h1>
              </div>
              <div className="">
                <div>
                  <ol className="ml-4">
                    <li className="liste-ordonnee">
                      Nous Présenter votre projet
                    </li>
                    <li className="liste-ordonnee">
                      Identifier la solution de financement qui vous convient
                    </li>
                    <li className="liste-ordonnee">
                      Découvrir si vous êtes élligible à une collecte sur
                      Easy Funding
                    </li>
                  </ol>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 col-md-2 m-4 paddingNav">
                <button
                  type="submit"
                  onClick={navigation.next}

                  className="btn btn-newLeter color-btn input justify-content-center"
                >
                  COMMENCER
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VotreProjet;
