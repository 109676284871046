import React from 'react'
import FrontConnexion from '../FrontConnexion/FrontConnexion'

function DetailProjectConnexion() {
  return (
    <div>
        <FrontConnexion />
    </div>
  )
}

export default DetailProjectConnexion