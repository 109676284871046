import React, { useEffect } from "react";
import UseEditSecteur from "../BackendV2/Admin/Parametre/request/UseEditSecteur";

const CRUDSecteurModal = ({ secteur }) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    setValue,
  } = UseEditSecteur();
  // let { secteurId } = UseEditSecteur();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState, clearErrors]);

  useEffect(() => {
    if (secteur?.id) {
      setValue("name", secteur?.name);
      setValue("id", secteur?.id);
    }
  }, [secteur]);

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="AddingFamilyModal">
            Modifier un secteur
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={onSubmit}>
              <div className="row crud-row">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group">
                    <label htmlFor="prenom">Nom secteur</label>
                    <input
                      type="text"
                      placeholder="nom secteur"
                      name="name"
                      className="form-control crud-form-control"
                      id="name"
                      {...register("name")}
                    />
                    {formState?.errors && formState?.errors?.name && (
                      <div className="alert alert-danger epm-alert-danger closer m-t-10">
                        {formState?.errors?.name?.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>
                  {isSuccessfullySubmitted && (
                    <button
                      className="btn btn-primary crud-submit-btn"
                      disabled
                    >
                      En cours &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                  {!isSuccessfullySubmitted && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDSecteurModal;
