import React, { useEffect } from "react";
import "./FrontConnexion.css";
import "./Responsive.css";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UseLoginForm from "../../Auth/useLoginForm";
import { Facebook, Twitter } from "@material-ui/icons";
import Google from "../../../assets/images/socials/google.png";
// import TriangleBleu from "../../../assets/images/triangleBleu.png";
import googleClientId from "../../../Http/googleClientId";
import { GoogleLogin } from "react-google-login";
import AuthServices from "../../Services/AuthServices";
import { useDispatch } from "react-redux";
import { fetchMe } from "../../../redux/user/userAction";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import TwitterLogin from "react-twitter-login";
import { Lock, Message } from "react-iconly";
import { logoBlanc } from "../../Logo/LogoComponent";

function FrontConnexion({ saveData }) {
  const dispatch = useDispatch();
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
  } = UseLoginForm(saveData);
  const { projectId } = useParams();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState, clearErrors]);
  // Auth with social medial link function
  const handleResponseFacebook = (res) => {
    console.log(res);
  };
  const handleTwitterResponse = (err, data) => {
    console.log(err, data);
  };
  const handleSuccess = async (googleData) => {
    console.log("google authenticate success", googleData.profileObj);
    const data = {
      email: googleData.profileObj.email,
      nom: googleData.profileObj.familyName,
      prenom: googleData.profileObj.givenName,
    };

    console.log(data);

    let request = AuthServices.onRegisterSocial(data);

    await request.then(async (response) => {
      console.log(response);
      const config = {
        headers: {
          Authorization: `Bearer ${response?.token}`,
        },
      };

      let requestMe = AuthServices.onMe(config);

      await requestMe
        .then(async (res) => {
          console.log("me", res);
          if (res?.data?.id && res?.data?.user_type === "investisseur") {
            window.sessionStorage.setItem("userID", res?.data?.id);
            window.sessionStorage.setItem("userType", res?.data?.user_type);
            window.sessionStorage.setItem("userToken", response?.token);
            await dispatch(fetchMe());
            await Swal.fire({
              icon: "success",
              title: "Connexion réussie!",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              if (
                window.location.pathname === `/projet/connexion/${projectId}`
              ) {
                window.location = `/alimenter-son-compte/${projectId}`;
              } else {
                window.location = "/dashboard-investisseur/profil";
              }
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Connexion non authorisée!",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          console.log("err", err, err?.response);
          if (err?.response.status === 401) {
            Swal.fire({
              icon: "error",
              title:
                "Vous n'êtes pas autorisé à vous connecter. Seuls les investisseurs peuvent se connecter via un réseau social",
              showConfirmButton: false,
              timer: 5000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  const handleFailure = (response) => {
    console.log("error", response);
  };
  return (
    <div className="container-fluid connexion p-0 m-0 d-flex flex-column justify-content-center align-items-center">
      {/* <div className="container-cayore auth-page-container vh-50"> */}
      <div className="auth-page-row col-md-11">
        <div className="col-md-12 auth-section right-side">
          <div className="auth-form-container d-flex">
            <div className="divLogo d-flex flex-column align-items-center justify-content-center">
              <a href="/" className="d-flex justify-content-center">
                <img src={logoBlanc} alt="logo" className="w-50" />
              </a>
              <p className="text-center">
                Le crédit idéal, le financement parfait
              </p>
            </div>
            <div className="col-md-7 d-flex flex-column align-items-center justify-content-center py-5 vh-80">
              <div className="col-8">
                <p className="titreConnexion text-center mb-5">Connectez-vous</p>
                <form id="login-form" onSubmit={onSubmit}>
                  <div className="auth-form-content">
                    <div className="">
                      <div className="input-group auth-form-group mt-5">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <Message
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>

                        <input
                          name="email"
                          id="email"
                          {...register("email")}
                          type="text"
                          className="form-control cv-input-with-icon inputField"
                          placeholder="Adresse email"
                          data-testid="emailId"
                        />
                        {formState.errors && formState.errors.email && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {formState.errors.email?.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      {" "}
                      <div className="input-group auth-form-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text span-input-icon"
                            id="basic-addon1"
                          >
                            <Lock
                              set="bold"
                              primaryColor="#C4C4C4"
                              className="auth-icon"
                            />
                          </span>
                        </div>
                        <input
                          name="password"
                          id="password"
                          type="password"
                          {...register("password")}
                          className="form-control cv-input-with-icon inputField"
                          placeholder="Mot de passe"
                          data-testid="passwordId"
                        />
                        {formState.errors && formState.errors.password && (
                          <div
                            className="alert alert-danger gfa-alert-danger"
                            role="alert"
                          >
                            {formState.errors.password?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="forget-password-link-container ">
                    <Link className="mdpOublie" to="/request-reset-password">
                      Mot de passe oublié ?
                    </Link>
                  </div>

                  <div className="auth-submit-btn-container ">
                    <div className="auth-submit-btn-content d-flex justify-content-center">
                      {!isSuccessfullySubmitted ? (
                        <button
                          type="submit"
                          data-testid="btnId"
                          className="auth-submit-btn btn text-white"
                        >
                          Connexion
                        </button>
                      ) : (
                        <button className="auth-submit-btn btn btn-success in-progress-btn">
                          En cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </form>

                <div className="row other-auth-action-row">
                  <div className="col-md-10 m-auto auth-alternative-action-container">
                    <div className="auth-action">
                      <span className="auth-action-textual">
                        Vous n’avez pas de compte ? &nbsp;
                        <span className="auth-action-link-container">
                          <Link
                            className="color-part2"
                            to={
                              window.location.pathname ===
                              `/projet/connexion/${projectId}`
                                ? `/projet/inscription/${projectId}`
                                : "/inscription"
                            }
                          >
                            Inscrivez-vous ici.
                          </Link>
                        </span>
                      </span>
                    </div>
                    {/* <button
												type="submit"
												data-testid="btnId"
												className="auth-submit-btn2 btn btn-success"
											>
												Connexion
											</button> */}
                  </div>
                </div>
                <div className="row other-auth-action-row">
                  <div className="col-12">
                    <div className="auth-other-action-text-container">
                      <p className="auth-other-action-text">ou</p>
                    </div>
                    <ul className="auth-other-links-subscribe">
                      <GoogleLogin
                        render={(renderProps) => (
                          <button
                            className="auth-social-btn"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            data-testid="authGoogleBtnId"
                            name="authGoogleBtn"
                          >
                            <img
                              src={Google}
                              alt="google-icon"
                              style={{ width: "20px" }}
                            />
                          </button>
                        )}
                        clientId={googleClientId}
                        onSuccess={handleSuccess}
                        onFailure={handleFailure}
                        cookiePolicy={"single_host_origin"}
                      />
                      <FacebookLogin
                        appId="442092307711692"
                        fields="name,email,picture"
                        callback={handleResponseFacebook}
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            className="auth-social-btn"
                          >
                            <Facebook
                              style={{
                                color: "#395185",
                                fontSize: "20",
                              }}
                            />
                          </button>
                        )}
                      />
                      <TwitterLogin
                        consumerKey="2DSGCjfBiEGmRLqvU3kn5MwZU"
                        consumerSecret="LGRSzAhj0XBzFG9zULSH9TRf8wWgWEbn8DkdLWAAusmphPSixh"
                        authCallback={handleTwitterResponse}
                        children={
                          <button className="auth-social-btn">
                            <Twitter
                              style={{ color: "#55ACEE", fontSize: "20" }}
                            />
                          </button>
                        }
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="col-md-12 col-lg-6 col-sm-12 col-xl-6 auth-section left-side">
          <div className="lefty-empty-content">
            <img
              src={TriangleBleu}
              className="positionImageBleu"
              alt="Triangle bleu"
            />
          </div>
        </div>*/}
      </div>
    </div>
  );
}

export default FrontConnexion;
