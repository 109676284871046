import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "react-toastify/dist/ReactToastify.css";
import Investir from "./Components/FrontendV2/Investir/Investir";
import Apropos from "./Components/FrontendV2/About/Apropos";
import Activite from "./Components/FrontendV2/TableauDeBord/MonActivite/Activite";
import FrontConnexion from "./Components/FrontendV2/FrontConnexion/FrontConnexion";
import FrontInscription from "./Components/FrontendV2/FrontInscription/FrontInscription";
import ListeDesProjet from "./Components/FrontendV2/ListeDesProjets/ListeDesProjet";
import PageAccueil from "./Components/FrontendV2/PageAccueil/PageAccueil";
import NousContacter from "./Components/FrontendV2/NousContacter/NousContacter";
import MonProfile from "./Components/FrontendV2/MonProfile/MonProfile";
import HowItWorks from "./Components/FrontendV2/HowItWorksV2/HowItWorks";
import ModificationMotDePasse from "./Components/FrontendV2/TableauDeBord/ModificationMotDePasse/ModificationMotDePasse.js";
import DetailDesProjet from "./Components/FrontendV2/DetailDesProjet/DetailDesProjet";
import ValidationIdentite from "./Components/FrontendV2/MonProfile/ValidationIdentite";
import ChoixTypeInvestisement from "./Components/FrontendV2/MonProfile/ChoixTypeInvestisement";
import InformationsPersonnelles from "./Components/FrontendV2/MonProfile/InformationsPersonnelles";
import InformationsPersonnellesEtEntreprise from "./Components/FrontendV2/MonProfile/InformationsPersonnellesEtEntreprise";
import Questionnaire from "./Components/FrontendV2/Questionnaires/Questionnaire";
import DocumentInscription from "./Components/FrontendV2/telechargerDocumentInscription/DocumentInscription";
import SeFinancer from "./Components/FrontendV2/SeFinancer/SeFinancer";
import Projets from "./Components/FrontendV2/TableauDeBord/Projets/Projets";
import Progression from "./Components/FrontendV2/TableauDeBord/Projets/Progression";
import DetailsProjetTableauDeBord from "./Components/FrontendV2/TableauDeBord/Projets/DetailsProjetTableauDeBord";
import AlimenterSonCompte from "./Components/FrontendV2/SeFinancer/AlimenterSonCompte";
import { DetailProjetEnCoursDeCampagne } from "./Components/FrontendV2/TableauDeBord/DetailProjetEnCoursDeCampagne/DetailProjetEnCoursDeCampagne";
import Dashboard from "./Components/BackendV2/Admin/Dashboard/Dashboard";
import Users from "./Components/BackendV2/Admin/Users/Users";
import Investisseurs from "./Components/BackendV2/Admin/Investissurs/Investisseurs";
import ProjetsAdmin from "./Components/BackendV2/Admin/Projets/Projets";
import AjouterProjet from "./Components/BackendV2/Admin/Projets/AjouterProjet";
import Profiladmin from "./Components/BackendV2/Admin/ProfilAdmin/ProfilAdmin";
import Parametreadmin from "./Components/BackendV2/Admin/Parametre/ParametreAdmin";
import DetailProjet from "./Components/BackendV2/Admin/Projets/DetailProjet";
import Faq from "./Components/BackendV2/Admin/Faq/Faq";
import Contacts from "./Components/BackendV2/Admin/Contacts/Contacts";
import { fetchMe } from "./redux/user/userAction";
import { fetchSecteurs } from "./redux/secteurs/secteurAction";
import Resume from "./Components/FrontendV2/InvestisseurTableauDeBord/Resume/Resume";
import Prets from "./Components/FrontendV2/InvestisseurTableauDeBord/Prets/Prets";
import Transactions from "./Components/FrontendV2/InvestisseurTableauDeBord/Transactions/Transactions";
import MonCompteInvestisseur from "./Components/FrontendV2/InvestisseurTableauDeBord/MonCompte/MonCompteInvestisseur";
import MesActions from "./Components/FrontendV2/InvestisseurTableauDeBord/MesActions/MesActions";
import HistoriquePretsPorteurProjet from "./Components/FrontendV2/TableauDeBord/MonActivite/HistoriquePretsPorteurProjet";
import SoldePorteurProjet from "./Components/FrontendV2/TableauDeBord/MonActivite/SoldePorteurProjet";
import RemboursementPorteurProjet from "./Components/FrontendV2/TableauDeBord/MonActivite/RemboursementPorteurProjet";
import RisquesInvestissement from "./Components/FrontendV2/RisquesInvestissement.js/RisquesInvestissement";
import { fetchProjects } from "./redux/projects/projectAction";
import {fetchInvestisseurs} from "./redux/investisseurs/investisseurAction"
import "moment/locale/fr";
import { fetchProjectsForVisitors } from "./redux/projectsForVisitors/ProjectForVisitorAction";
import EditerProfilInvestisseur from "./Components/FrontendV2/InvestisseurTableauDeBord/ProfilInvestisseur/EditerProfilInvestisseur/EditerProfilInvestisseur";
import ModificationMotDePasseInvestisseur from "./Components/FrontendV2/InvestisseurTableauDeBord/ProfilInvestisseur/ModificationMotDePasseInvestisseur/ModificationMotDePasseInvestisseur";
import { fetchUsers } from "./redux/users/usersAction";
import { fetchMessagesContact } from "./redux/contacts/contactAction";
import { fetchAdmins } from "./redux/admins/adminsAction";
import ForgetPassword from "./Components/FrontendV2/ForgetPassword/ForgetPassword";
import ResetPassword from "./Components/FrontendV2/ForgetPassword/ResetPassword";
import { fetchProjectsByPorteur } from "./redux/projectsByPorteur/ProjectByPorteurAction";
import { fetchProjectsInCampaign } from "./redux/ProjetsEnCoursDeCampagne/ProjectInCampaignAction";
import { fetchFinishProjectsByPorteur } from "./redux/ProjectsFinishByPorteur/ProjectsFinishByPorteurAction";
import ChoisirMontant from "../src/Components/FrontendV2/SeFinancer/ChoisirMontant"
import DetailProjectConnexion from "./Components/FrontendV2/DetailDesProjet/DetailProjectConnexion.js";
import DetailProjectInscription from "./Components/FrontendV2/DetailDesProjet/DetailProjectInscription.js";
import TableauDeBord from "./Components/FrontendV2/InvestisseurTableauDeBord/MonTableauDeBord/TableauDeBord.js";

function App() {
  const dispatch = useDispatch();
  const token = window.sessionStorage.getItem("userToken");
  const userType = window.sessionStorage.getItem("userType");
  const userId = window.sessionStorage.getItem("userID");

  useEffect(() => {
    if (token) {
      dispatch(fetchMe());
      dispatch(fetchProjects());
      dispatch(fetchInvestisseurs());
      dispatch(fetchUsers());
      if (userType === "admin") {
        dispatch(fetchMessagesContact());
        dispatch(fetchAdmins());
      }
    }
    dispatch(fetchProjectsForVisitors());
    dispatch(fetchSecteurs());
    dispatch(fetchProjectsByPorteur(userId));
    dispatch(fetchProjectsInCampaign(userId));
    dispatch(fetchFinishProjectsByPorteur(userId));
  }, [dispatch, token, userType, userId]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/liste-des-projets" element={<ListeDesProjet />} />
        <Route path="/investir" element={<Investir />} />
        <Route path="/a-propos-de-nous" element={<Apropos />} />
        <Route
          path="/dashboard/activite"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/accueil" />
            ) : (
              <Activite />
            )
          }
        />
        <Route
          path="/dashboard/historique"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/accueil" />
            ) : (
              <HistoriquePretsPorteurProjet />
            )
          }
        />
        <Route
          path="/dashboard/solde"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/accueil" />
            ) : (
              <SoldePorteurProjet />
            )
          }
        />
        <Route
          path="/dashboard/remboursements"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/accueil" />
            ) : (
              <RemboursementPorteurProjet />
            )
          }
        />
        <Route
          path="/dashboard/changer-mot-de-passe"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <ModificationMotDePasse />
            )
          }
        />
        <Route path="/connexion" element={<FrontConnexion />} />
        <Route path="/inscription" element={<FrontInscription />} />
        <Route path="/" element={<PageAccueil />} />
        <Route path="/nous-contacter" element={<NousContacter />} />
        <Route
          path="/dashboard/editer-mon-profile"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <MonProfile />
            )
          }
        />
        <Route
          path="/dashboard/validation-identite"
          element={<ValidationIdentite />}
        />
        <Route
          path="/dashboard/choix-type-investisement"
          element={<ChoixTypeInvestisement />}
        />
        <Route
          path="/dashboard/informations-personnelles"
          element={<InformationsPersonnelles />}
        />
        <Route
          path="/dashboard/informations-personnelles-et-entreprise"
          element={<InformationsPersonnellesEtEntreprise />}
        />
        <Route path="/comment-ca-marche" element={<HowItWorks />} />
        <Route path="/projet/:slug" element={<DetailDesProjet />} />
        <Route path="/projet/connexion/:projectId" element={<DetailProjectConnexion />} />
        <Route path="/projet/inscription/:projectId" element={<DetailProjectInscription />} />

        <Route path="/accueil" element={<PageAccueil />} />
        <Route path="/alimenter-son-compte/:projectId" element={<AlimenterSonCompte />} />
        <Route
          path="/documents-inscription"
          element={<DocumentInscription />}
        />
        <Route
          path="/questionnaire-pour-se-financer"
          element={<Questionnaire />}
        />
        <Route path="/se-financer" element={<SeFinancer />} />
        <Route
          path="/dashboard/projets"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <Projets />
            )
          }
        />
        <Route
          path="/dashboard/progression"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <Progression />
            )
          }
        />
        <Route
          path="/dashboard/details-projet-tableau-de-bord"
          element={<DetailsProjetTableauDeBord />}
        />
        <Route
          path="/dashboard/detail-projet-en-cours-de-campagne/"
          element={<DetailProjetEnCoursDeCampagne />}
        />
        <Route
          path="/dashboard-investisseur/resume"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <Resume />
            )
          }
        />
        <Route
          path="/dashboard-investisseur/prets"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <Prets />
            )
          }
        />
        <Route
          path="/dashboard-investisseur/transactions"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <Transactions />
            )
          }
        />
        <Route
          path="/dashboard-investisseur/mon-compte"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <MonCompteInvestisseur />
            )
          }
        />
        <Route
          path="/dashboard-investisseur/mes-actions"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <MesActions />
            )
          }
        />

        <Route
          path="/admin/dashboard/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <Dashboard />
            )
          }
        />
        <Route
          path="/admin/gestion-des-utilisateurs/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <Users />
            )
          }
        />
        <Route
          path="/admin/gestion-des-investisseurs/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <Investisseurs />
            )
          }
        />
        <Route
          path="/admin/gestion-des-projets/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <ProjetsAdmin />
            )
          }
        />
        <Route
          path="/admin/ajouter-un-projet/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <AjouterProjet />
            )
          }
        />
        <Route
          path="/admin/detail-projet/:projectId"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <DetailProjet />
            )
          }
        />
        <Route
          path="/admin/profil/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <Profiladmin />
            )
          }
        />
        <Route
          path="/admin/parametre/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <Parametreadmin />
            )
          }
        />
        <Route
          path="/admin/faq/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <Faq />
            )
          }
        />
        <Route
          path="/admin/contacts/"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "admin" ? (
              <Navigate to="/accueil" />
            ) : (
              <Contacts />
            )
          }
        />
        <Route
          path="/risques-investissement"
          element={<RisquesInvestissement />}
        />
        <Route
          path="/dashboard-investisseur"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <TableauDeBord />
            )
          }
        />
        <Route
          path="/dashboard-investisseur/profil"
          element={<EditerProfilInvestisseur />}
        />
        <Route
          path="/dashboard-investisseur/changer-mot-de-passe"
          element={
            !token ? (
              <Navigate to="/connexion" />
            ) : userType !== "investisseur" ? (
              <Navigate to="/" />
            ) : (
              <ModificationMotDePasseInvestisseur />
            )
          }
        />
         <Route path="/projet/:slug/choisir-montant" element={<ChoisirMontant />} />
        <Route path="/request-reset-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
