import React from "react";
import "../../FrontendV2/HowItWorksV2/HowItWorks.css";

function Risque() {
  return (
    <div className="mb-3">
      <div className="p-5 container-investissement-risque container-cayore">
      <h5 className="tout-investissement">
        Tout investissement comporte des risques
      </h5>
      <div className="">
        <div className="risque-text-container">
          <p className="text-risque">
            Investir comporte des risques d’illiquidité et de perte totale ou
            partielle du montant investi. Avant d’investir, nous vous invitons à
            lire attentivement les documents d’information de l’entreprise.
          </p>
        </div>
        <div className="risque-text-container">
          <p className="text-risque">
            Nous vous recommandons de n’investir que :
          </p>
          <ul className="step-how-it-works-type-list-container ml-2">
            <li className="works-type-text">
              Dans les projets que vous comprenez parfaitement
            </li>
            <li className="works-type-text">
              Dans les projets que vous comprenez parfaitement Et pour des
              montants que vous pouvez vous permettre de perdre donc que vous
              considérez ‘marginaux’ au regard de l’ensemble de votre
              patrimoine.
            </li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Risque;
