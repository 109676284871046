import React, { useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// import { NavLink } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import { Delete, EditTwoTone } from "@material-ui/icons/";
// import InvestisseursData from "./InvestisseursData";
import InvestisseursSkeleton from "./InvestisseursSkeleton";
import { fetchInvestisseurs } from "../../../../redux/investisseurs/investisseurAction";
import { useSelector, useDispatch } from "react-redux";
import "./InvestisseursTable.css";
import InvestisseurDetails from "./modalInvestisseur/InvestisseurDetails";
import InvestisseurEditModal from "./modalInvestisseur/InvestisseurEditModal";
import Swal from "sweetalert2";
import Axios from "axios";
import api from "../../../../Http/global-vars";

const InvestisseursTable = (props) => {
  const dispatch = useDispatch();
  const invests = useSelector((state) => state.investisseurs);

  //filtre
  const [valeurFiltre, setValeurFiltre] = useState("");
  const handleChangementFiltre = (e) => {
    setValeurFiltre(e.target.value);
  };

  const investisseursFiltres = invests?.data?.filter((investisseur) => {
    const nomComplet =
      investisseur.investisseur.prenom +
      " " +
      investisseur.investisseur.nom +
      " " +
      investisseur.investisseur.email;
    const telephone = investisseur.investisseur.phone;

    return (
      nomComplet.toLowerCase().includes(valeurFiltre.toLowerCase()) ||
      telephone.includes(valeurFiltre)
    );
  });

  useEffect(() => {
    // Dispatch fetchInvestisseurs
    dispatch(fetchInvestisseurs());
  }, [dispatch]);

  if (invests.isLoading) {
    // console.log("Loading...");
  } else {
    // console.log("Data loaded");
    // console.log({ invests });
  }


  const options = {
    // searchField: createCustomSearchField,
    clearSearch: false,
    noDataText: "Aucune donnée disponible",
  };

  const onDelete = (item) => {
    let token = window.sessionStorage.getItem("userToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    Swal.fire({
      title: `Êtes vous sûr de vouloir ${
        item?.is_active ? "désactiver" : "activer"
      } cet utilisateur?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#115c67",
      cancelButtonColor: "#E53E3E",
      preConfirm: () => {
        return Axios.put(
          `${api}user/${item?.id}/`,
          { is_active: !item?.is_active },
          config
        )
          .then(async (response) => {
            console.log("res", response);
            await dispatch(fetchInvestisseurs());
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `Utilisateur ${
            item?.is_active ? "désactivé" : "activé"
          } avec succès.`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  // const photoFormatter = (cell) => {
  //   return <img src={cell} alt="avatar" className="user-img" />;
  // };

  const nameFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span className="name">{row.investisseur.nom}</span>
        <span className="email">{row.investisseur.email}</span>
      </div>
    );
  };
  const phoneFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span className="name">{row.investisseur.phone}</span>
      </div>
    );
  };
  const projetFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span className="name">{row.projet.title}</span>
      </div>
    );
  };
  const modeFinancementFormatter = (cell, row) => {
    return (
      <div className="name-email-group">
        <span className="name">{row.projet.mode_financement}</span>
      </div>
    );
  };
  const montantFormatter = (cell, row) => {
    return `${cell} FCFA`;
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-container">
        <button
          data-toggle="modal"
          data-target={"#DetailsUserModal" + row.id}
          style={{ color: "#9B51E0", backgroundColor: "transparent" }}
          className="mr-2"
        >
          <IconlyPack.Show />
        </button>
        <button
          data-toggle="modal"
          data-target={`#EditUserModal${row?.id}`}
          style={{ color: "#2D3748", backgroundColor: "transparent" }}
          className="mr-2"
          title="Modifier"
        >
          <EditTwoTone set="light" style={{ fontSize: "20px" }} />
        </button>
        {!row?.is_active ? (
          <button
            style={{ color: "#115c67", backgroundColor: "transparent" }}
            className="mr-2"
            title="Activer"
            onClick={() => onDelete(row)}
          >
            <IconlyPack.TickSquare set="light" style={{ fontSize: "20px" }} />
          </button>
        ) : (
          <button
            style={{ color: "#E53E3E", backgroundColor: "transparent" }}
            className="mr-2"
            title="Désactiver"
            onClick={() => onDelete(row)}
          >
            <Delete set="light" style={{ fontSize: "20px" }} />
          </button>
        )}

        <div
          className="modal fade in"
          id={"DetailsUserModal" + row.id}
          tabIndex="-1"
          aria-labelledby="CreerDateLancementLabel"
          aria-hidden="true"
        >
          <InvestisseurDetails invest={row} />
        </div>
        <div
          className="modal fade in"
          id={`EditUserModal${row?.id}`}
          tabIndex="-1"
          aria-labelledby="EditUserModalLabel"
          aria-hidden="true"
        >
          <InvestisseurEditModal invest={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container-activite">
        {/* Filtres */}
        <div className="filtres-container pb-5">
          <h6 className="filter-label">Filter</h6>
          <div className="filter-input-group">
            <input
              type="text"
              className="filter-input"
              placeholder="Entrer un nom"
              value={valeurFiltre}
              onChange={handleChangementFiltre}
            />
          </div>
        </div>
        <div className="row easypm-table-row">
          <div className="col-md-12 easypm-table-col">
            {/* {investisseursData.length ? ( */}
            <>
              {invests?.isPageLoading && <InvestisseursSkeleton />}
              {!invests?.isLoading && (
                <div className="table-container-activite">
                  <BootstrapTable
                    data={investisseursFiltres}
                    hover={true}
                    condensed={true}
                    multiColumnSort={2}
                    options={options}
                    search={false}
                    version="4"
                    bordered={false}
                  >
                    {/* <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Poppins",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2d3748",
                      }}
                      width="50"
                      dataField="image"
                      isKey={true}
                      dataFormat={(cell, row) => photoFormatter(cell, row)}
                    >
                      Photos
                    </TableHeaderColumn> */}
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Poppins",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2d3748",
                        fontFamily: "Poppins",
                      }}
                      isKey={true}
                      width="100"
                      dataField="investisseur"
                      dataFormat={(cell, row) => nameFormatter(cell, row)}
                    >
                      Noms + Email
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Poppins",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2d3748",
                        fontFamily: "Poppins",
                      }}
                      width="100"
                      dataFormat={(cell, row) => phoneFormatter(cell, row)}
                      dataField="investisseur.phone"
                    >
                      Téléphone
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Poppins",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2d3748",
                        fontFamily: "Poppins",
                      }}
                      width="100"
                      dataFormat={(cell, row) => modeFinancementFormatter(cell, row)}
                      dataField="projet.mode_financement"
                    >
                      Type d'investissement
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Poppins",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2d3748",
                        fontFamily: "Poppins",
                      }}
                      width="100"
                      dataField="montant"
                      dataFormat={(cell, row) => montantFormatter(cell, row)}
                    >
                      Montant Investi
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Poppins",
                      }}
                      tdStyle={{
                        fontWeight: "normal",
                        fontSize: 14,
                        color: "#2d3748",
                        fontFamily: "Poppins",
                      }}
                      width="100"
                      dataFormat={(cell, row) => projetFormatter(cell, row)}
                      dataField="projet.title"
                    >
                      Titre de projets
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 12,
                        color: "#A0AEC0",
                        fontFamily: "Poppins",
                      }}
                      dataField="id"
                      width="100"
                      dataFormat={(cell, row) => actionFormatter(cell, row)}
                    >
                      Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </>
            {/* ) : (
              <>
                {isPageLoading && <InvestisseursSkeleton />}
                {!isPageLoading && (
                  <h2 className="activite-table-title-no-finish">
                    Aucun projet Terminés
                  </h2>
                )}
              </>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestisseursTable;
