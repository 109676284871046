import React from 'react'
import EvenementCarousel from './EvenementCarousel'

function Evenement() {

  return (
    <div>
        <div className='container pb-5'>
            <div>
                <h1>Nos événements</h1>
            </div>
            <p>Enregistrez vos événements avec nous</p>
            <div className='containerPartieCarousel'>
                <EvenementCarousel/>
            </div>
        </div>
    </div>
  )
}

export default Evenement