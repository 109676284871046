import React from "react";

const DonneesFinancieresTab = ({project}) => {
	return (
		<div
			className="tab-pane fade"
			id="pills-donnee"
			role="tabpanel"
			aria-labelledby="pills-donnee-tab"
		>
			<div>
				<div className="pt-5">
					{" "}
					<h3 className="title-detail-h3 pb-3">
						Caractéristiques du prêt
					</h3>
				</div>
				<div className="bloc-list1 pb-5">
					<ul className="list-group list-group-flush">
						<li className="list-group-item">
							<div className="row">
								<div className="col-md-6">
									<span className="text-li-details">
										Coût total
									</span>
								</div>
								<div className="col-md-6 d-flex justify-content-end">
									<span className="text-li-details">
										{" "}
										{project?.fonds_supplementaires_prix || "Montant non défini"}
									</span>
								</div>
							</div>
						</li>
						<li className="list-group-item li-bg">
							<div className="row">
								<div className="col-md-6">
									<span className="text-li-details">
										Frais de
										remboursement
										anticipé
									</span>
								</div>
								<div className="col-md-6 d-flex justify-content-end">
									<span className="text-li-details">
										{" "}
										?
									</span>
								</div>
							</div>
						</li>
						<li className="list-group-item">
							<div className="row">
								<div className="col-md-6">
									<span className="text-li-details">
										Frais de retard de
										paiement
									</span>
								</div>
								<div className="col-md-6 d-flex justify-content-end">
									<span className="text-li-details">
										{" "}
										?
									</span>
								</div>
							</div>
						</li>
						<li className="list-group-item li-bg">
							<div className="row">
								<div className="col-md-6">
									<span className="text-li-details">
										Frais de
										recouvrement
									</span>
								</div>
								<div className="col-md-6 d-flex justify-content-end">
									<span className="text-li-details">
										{" "}
										?
									</span>
								</div>
							</div>
						</li>
						<li className="list-group-item">
							<div className="row">
								<div className="col-md-6">
									<span className="text-li-details">
										Frais initiaux
									</span>
								</div>
								<div className="col-md-6 d-flex justify-content-end">
									<span className="text-li-details">
										{" "}
										?
									</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DonneesFinancieresTab;
