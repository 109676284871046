import React from "react";

const TableComparatifGlobal = () => {
  return (
    <table className="table table-striped table-comparatif">
      <thead className="table-thead table-background-color">
        <tr>
          <th scope="col"></th>
          <th scope="col">
            CAPITAL <br />
            (FONDS PROPRES)
          </th>
          <th scope="col">
          OBLIGATIONS
           <br />
            (DETTE)
          </th>
          <th scope="col">ROYALTIES (‘HYBRIDE’ : NI 
            <br />FONDS PRPORES NI DETTE)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <span>Perpective de l’investisseur</span>
          </th>
          <td>
            Conserver des parts de capital pendant 5-7 ans ou indéfiniment, dans
            l’attente d’une opportunité de sortie
          </td>
          <td>
            Commencer à recevoir un retour sur investissement quelques mois
            après le début de l’activité financée
          </td>
          <td>Récupérer des paiements à un taux d’intérêt fixe</td>
        </tr>
        <tr>
          <th scope="row">Retour sur investissement</th>
          <td>
            En cas de sortie réussie, une plus value conséquente peut être
            réalisée
          </td>
          <td>
            Objectif de 5-15% de rendement annuel moyen ; investissement
            remboursé dans les dernières année
          </td>
          <td>
            Le capital est récupéré avec les intérêts sur la durée du prêt
          </td>
        </tr>
        <tr>
          <th scope="row">Risque pour l’investisseur</th>
          <td>
            Risque de perte intégrale du capital investi, dans l’attente d’une
            sortie permise par la liquidité des parts
          </td>
          <td>
            Le risque est progressivement atténué à mesure que les paiements de
            royalties s’accumulent
          </td>
          <td>
            Risque de crédit classique pour une entreprise, diminuant à mesure
            que les échéances sont payées
          </td>
        </tr>
        <tr>
          <th scope="row">Contrôle des financeurs</th>
          <td>
            Induit des rôles d’actionnaire, et potentiellement de membre du
            conseil d’administration ou de comités clé
          </td>
          <td>
            Pas de détention de parts de capital ; pas de statut d’actionnaire
            ou de membre du conseil d’administration ; pas de volonté de jouer
            un rôle dans la gouvernance de l’entreprise
          </td>
          <td>
            Pas d’ingérence directe dans a gouvernance de l’entreprise, mais
            possibilité d’exercer des clauses de contrôle sur celle-ci en cas de
            défaut de remboursement
          </td>
        </tr>
        <tr>
          <th scope="row">Garanties prises par les financeurs</th>
          <td>
            Garantie prise sur la société et parfois sur le patrimoine des
            fondateurs, management package fondé sur des objectifs
          </td>
          <td>
            Aucune garanties tangibles. L’investissement est consentie sur la
            seule foi en la capacité des managers de développer l’entreprise
          </td>
          <td>
            stricts covenants (certains décisions majeures de l’entreprise
            doivent être validées par les créanciers) Les actifs de la société
            et souvent les actifs des principaux actionnaires sont pris en
            garantie (gage, hypothèque, nantissement + caution)
          </td>
        </tr>
        <tr>
          <th scope="row">Sortie</th>
          <td>
            La sortie est aléatoire et dépend de l’état du marché et de la
            perception du marché des capitaux et de l’issue des négociations
            avec les acquéreurs potentiels
          </td>
          <td>
            La sortie des investisseurs commence immédiatement avec le paiement
            des premiers royalties et s’accélère à mesure que les versement
            augmentent jusqu’à la fin du contrat
          </td>
          <td>
            La sortie a lieu au remboursement du capital prêté ou en exerçant
            les clauses des covenants, ce qui signifie faire jouer les garanties
            ou céder les actifs
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableComparatifGlobal;
