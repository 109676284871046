import React from "react";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import FrontHeaderV2 from "../../FrontHeaderV2/FrontHeaderV2";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import TabsLinkActivite from "../TabsLinkActivite/TabsLinkActivite";

const SoldePorteurProjet = () => {
	return (
		<div className="component-mes-projet-tableau-de-bord">
			<FrontHeaderV2 />
			<TabsLinkActivite />
			<div className="row activite pr-5">
				<div className="col-xl-3 col-lg-4">
					<SidebarTableauDeBord value={1} />
				</div>
				<div className="col-xl-9 col-lg-8">
					<div className="title-block">
						<h1 className="px-2">Mon activité</h1>
					</div>
					<div className="solde px-5 bg-white mt-3">
						<h2>Solde</h2>
						<div className="mt-4 soldeContent">
							<h3>20 000 FCFA</h3>
							<h4>Soldes des abondements</h4>
						</div>
						<h6>
							20 000 FCFA abondés + 0 FCFA bonus - 0
							FCFA débité
						</h6>
					</div>
					<div className="bg-white p-5 statistiques">
						<div className="dettes px-5 d-flex flex-column align-items-center flex-md-row flex-fill justify-content-between">
							<div className="statistiques-prets">
								<div className="statistiques-prets-content">
									<h6>0 FCFA</h6>
									<h4>Prêts</h4>
								</div>
								<div className="">
									<h6>
										dont 0 terminés et 0
										en défaut
									</h6>
								</div>
							</div>
							<div className="statistiques-prets mt-3 mt-md-0">
								<div className="statistiques-prets-content">
									<h6>0 FCFA</h6>
									<h4>Prêté</h4>
								</div>
								<div className="">
									<h6>
										dont 0 terminés et 0
										en défaut
									</h6>
								</div>
							</div>
						</div>
						<div className="gains px-5 mt-5 d-flex flex-column  flex-md-row flex-fill justify-content-between">
							<div className="statistiques-capital">
								<div className="statistiques-gains-content">
									<h6>0 FCFA</h6>
									<h4>
										Remboursements nets
									</h4>
								</div>
								<div className="">
									<h6>Capital 0 FCFA</h6>
									<h6>Interêts 0 FCFA</h6>
								</div>
							</div>
							<div className="statistiques-gains">
								<div className="statistiques-gains-content">
									<h6>0 FCFA</h6>
									<h4>Gains</h4>
								</div>
								<div className="">
									<h6>
										dont 0 FCFA
										d’interêt perçus et
										0 FCFA
										pertes/provisions au
										01er Juin
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FrontFooterV2 />
		</div>
	);
};

export default SoldePorteurProjet;
