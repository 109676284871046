import React from "react";
import { NavLink } from "react-router-dom";
import "./Banniere.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ChevronDownCircle } from "react-iconly";
import plateforme from "../../../images/icons/Group 427319027.png";
import Icon from '../../../images/icons/Group 427319028.png';

// requires a loader
// import { DataContext } from "../../../redux/store/GlobalState";
var Banniere = () => {
	// const { state, dispatch } = useContext(DataContext);
	// let { list_projects_front } = state;

	return (
		<div className="component-home-banner">
			<div className="container couverture-container">
				<div className="couverture-content-container">
					<h1 className="couverture-title">
						Ensemble construisons <br /> les champions
						de demain
					</h1>
					<div className="couverture-description-container marge-container-description-accueil">
                        <p className="couverture-description-text">
                        Nous sommes tout à la fois :
                        </p>
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6">
                                <div>
                                    <div className="text-center"><img src={plateforme} alt="Icon" className="img-icon-banniere" /></div>
                                    <div>
                                        <p className="couverture-description-text">
                                            Une <span className="text-bold">plateforme sénégalaise <br/>de financement participatif </span><br/>
                                            {/* <span className="text-small-banniere"><i>(en obligations, fonds propres ou royalties)</i></span> */}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-6 pt-5">
                                <p className="text-et-banniere text-center">&</p>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6">
                                <div className="text-center"><img src={Icon} alt="Icon" className="img-icon-banniere" /></div>
                                    <div>
                                        <p className="couverture-description-text">
                                            Un <span className="text-bold">investisseur minoritaire  </span><br/>dans chacun des projets présentés
                                        </p>
                                    </div>
                            </div>
                        </div>
                    </div>
				</div>

				<div className="couverture-actions-btn-container mb-5">
					<div className="funding-btn-container">
						<NavLink
							className="btn btn-success funding-btn"
							to="/investir"
						>
							Investir
						</NavLink>
					</div>
					<div className="funding-video-description-container">
						<NavLink
							to="/se-financer"
							className="btn btn-default funding-video-btn"
						>
							Se financer
						</NavLink>
					</div>
				</div>
			</div>
			<div className="banner-icon-down-container">
				<div className="banner-icon-container">
					<ChevronDownCircle size="medium" primaryColor="var(--primaryColor)"/>
				</div>
			</div>
		</div>
	);
};

export default Banniere;
