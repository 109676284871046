import React from "react";
import InvestisseursTable from "../../../Tables/backend/Investisseurs/InvestisseursTable";
import NavbarV2 from "../NavbarV2/NavbarV2";
import HeaderV2 from "../Navs/HeaderV2";
import "./Investisseurs.css";

const Investisseurs = () => {
	return (
		<div className="users-container">
			<HeaderV2 />
			<div className="bloc-principal-navbar-container">
				<div className="navbar-container-box">
					<NavbarV2 />
				</div>
				<div className="bloc-principal-body-container">
					<div className="projets-container bloc-principal-body-content">
						<div className="liste-utilisateurs">
							<h1 className="liste-utilisateurs-title">
								Liste des investisseurs
							</h1>
							<div className="users-table pt-5">
								<InvestisseursTable />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Investisseurs;
