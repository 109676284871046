import api from "../../Http/global-vars";
import { FETCH_USERS } from "../types";
import axios from "axios";

export const fetchUsers = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    let token = window.sessionStorage.getItem("userToken");
    const url = api + "user/";
    if (token) {
      await axios
        .get(url, config)
        .then(async (res) => {
          // const data = await res.json();
          // console.log("data user", res);
          dispatch({
            type: FETCH_USERS,
            payload: res?.data?.results
              ?.filter((item) => item?.user_type !== "admin")
              ?.sort((a, b) => b?.id - a?.id),
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};
