import React from "react";
import "./Apropos.css";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import Facebook from "../../../images/icons/facebook black.1.png";
import Pinterest from "../../../assets/images/socials/pinterest.png";
import Instagram from "../../../images/icons/instagram black.1.png";
import linkedIn from "../../../images/icons/linkedin black.1.png";
import youtube from "../../../images/icons/youtube color.1.png";
import google from "../../../images/icons/googleplus black.1.png";
import wifi from "../../../assets/images/socials/wifi.png";
import twitter from "../../../assets/images/socials/twitterr.png";
import CircularProgress from "./CircularProgress";
import VideoPlayer from "react-video-js-player";
import VideoPm from "../../../images/Volkeno.mp4";
import DetailProjetVideo from "../../../images/detail.png";
import LinkReseauSocial from "./LinkReseauSocial";
import AproposText from "./AproposText";
import Partenaire from "../Partenaire/Partenaire";
import Investisseur from "./Investisseur";
import Contact from "./Contact";

var Apropos = (props) => {
  
  const onPlayerReady = () => {
  };
  const onVideoPlay = () => {
  };
  const onVideoPause = () => {
  };
  const onVideoTimeUpdate = () => {
  };
  const onVideoSeeking = () => {
  };
  const onVideoSeeked = () => {
  };
  const onVideoEnd = () => {
  };

  return (
    <div className="container-fluid col-12  col-md-6 col-lg-12  mx-0 px-0">
      <FrontHeaderV2 />
      <div className="couverture-container">
        <div className="div-bloc-a-propos">
          <div className="bg-white bloc-icon-a-propos desktop-display">
            <div className="d-flex flex-sm-column flex-row flex-nowrap bg-white align-items-center ">
              <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center">
                <LinkReseauSocial icon={Facebook} />
                <LinkReseauSocial icon={google} />
                <LinkReseauSocial icon={Instagram} />
                <LinkReseauSocial icon={youtube} />
                <LinkReseauSocial icon={linkedIn} />
                <LinkReseauSocial icon={wifi} Pinterest />
                <LinkReseauSocial icon={Pinterest} />
                <LinkReseauSocial icon={twitter} />
              </ul>
            </div>
          </div>
          <div className="bloc-image-a-propos">
            <div className="w-full py-5">
              <VideoPlayer
                controls={true}
                src={VideoPm}
                poster={DetailProjetVideo}
                height="420"
                autoplay={false}
                onReady={onPlayerReady()}
                onPlay={onVideoPlay()}
                onPause={onVideoPause()}
                onTimeUpdate={onVideoTimeUpdate()}
                onSeeking={onVideoSeeking()}
                onSeeked={onVideoSeeked()}
                onEnd={onVideoEnd()}
                className="w-full ccm-video"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="cards">
		<div className="container">
			<AproposText/>
			<CircularProgress />
		</div>
      </div>
	  <div className="container">
		<Partenaire stylePartenaire="titre-a-propos-text"/>
	  </div>
	  	<div className="container">
			<Investisseur/>
		</div>
        <div className="cards p-0">
          <Contact/>
        </div>
      <FrontFooterV2 />
    </div>
  );
};

export default Apropos;
