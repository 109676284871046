import React, { useState } from "react";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import "./InformationsPersonnelles.css";
import ModalConfirmationIdentite from "../../Modals/ModalConfirmationIdentite";
import TabsProfile from "../InvestisseurTableauDeBord/ProfilInvestisseur/TabsProfile/TabsProfile";
import SidebarTableauBord from "../InvestisseurTableauDeBord/SidebarTableauBord/SidebarTableauBord";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
const InformationsPersonnelles = () => {
  const [MrIsActive, setMrIsActive] = useState(false);
  const [MmeIsActive, setMmeIsActive] = useState(false);

  const handleChange = (e) => {
    if (e.target.value === "0") {
      setMrIsActive(true);
      setMmeIsActive(false);
    }
    if (e.target.value === "1") {
      setMmeIsActive(true);
      setMrIsActive(false);
    }
  };
  return (
    <div>
      <ModalConfirmationIdentite />
      <div className="component-mes-projet-tableau-de-bord">
        <FrontHeaderV2 />
        <TabsProfile value={1} />
        <div className="mt-3 row ">
          <div className="col-12 col-lg-4 col-xl-3 p-0">
            <SidebarTableauBord value={0} />
          </div>
          <div className="col-12 col-lg-8 col-xl-9  mt-3 mt-lg-0">
            <div className="title-block">
              <h1>Votre identité</h1>
            </div>
            <div className="contentpersonnel px-5 pt-3 pb-5">
              <h1 className="titreinfoperson">Informations personnelles</h1>
              <form>
                <div className="container-cayore-form">
                  <div className="content-choix mb-5">
                    <div className="d-flex justify-content-start m-b-50">
                      <p className="mr-5 align-self-baseline civilite">
                        Civilité
                      </p>
                      <div className="civilite-btn-radio-container">
                        <div
                          className={
                            MrIsActive
                              ? "civilite-btn-radio-item checked-civilite"
                              : "civilite-btn-radio-item"
                          }
                          htmlFor="mme-civilite"
                        >
                          <input
                            onChange={handleChange}
                            type="radio"
                            value="0"
                            name="civilite"
                            id="mr-civilite"
                            className="civilite-btn-radio"
                          />
                          <label htmlFor="mr-civilite">Mr</label>
                        </div>
                        <div
                          className={
                            MmeIsActive
                              ? "civilite-btn-radio-item checked-civilite"
                              : "civilite-btn-radio-item"
                          }
                        >
                          <input
                            onChange={handleChange}
                            type="radio"
                            value="1"
                            name="civilite"
                            id="mme-civilite"
                            className="civilite-btn-radio"
                          />
                          <label htmlFor="mme-civilite">Mme</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 m-b-50">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Prénom*"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-5 offset-md-2 m-b-50 ">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Nom*"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 m-b-50">
                      <div className=" d-flex flex-column">
                        <input
                          type="email"
                          className=" inputformedit"
                          placeholder="Email*"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-5 offset-md-2 m-b-50 ">
                      <div className=" d-flex flex-column">
                        <input
                          type="tel"
                          className=" inputformedit"
                          placeholder="Téléphone*"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 m-b-50">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Pays"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-5 offset-md-2 m-b-50 ">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Ville"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12 col-md-5 m-b-50 ">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Rue"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-5 offset-md-2 m-b-50  ">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Code postal"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12 col-md-5 m-b-50 ">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Date de naissance"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-5 offset-md-2 m-b-50  ">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Ville de naissance"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 m-b-50 ">
                      <div className=" d-flex flex-column">
                        <input
                          type="text"
                          className=" inputformedit"
                          placeholder="Pays de naissance"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    type="button"
                    className="btnsave"
                    data-toggle="modal"
                    data-target="#modal-de-soumission"
                  >
                    ENREGISTRER
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <FrontFooterV2 />
      </div>
    </div>
  );
};

export default InformationsPersonnelles;
