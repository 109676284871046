import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import DataCenterSkeleton from "./DataCenterSkeleton";
import "./DataCenter.css";

import user1 from "../../../../../assets/images/user1.png";
import user2 from "../../../../../assets/images/user2.png";
import user3 from "../../../../../assets/images/user3.png";
import user4 from "../../../../../assets/images/user4.png";
import user5 from "../../../../../assets/images/user5.png";
import user6 from "../../../../../assets/images/user6.png";
import user7 from "../../../../../assets/images/user7.png";
import moment from "moment";

const DataCenterData1 = [
	{
		id: 1,
		nom: "Idy Diop",
		image: user1,
		price: "1000",
		date: "02/03/2021",
	},
	{
		id: 2,
		nom: "Maodo Ndiaye",
		image: user2,
		price: "1000",
		date: "02/03/2021",
	},
	{
		id: 3,
		nom: "Jean P. Sagna",
		image: user3,
		price: "1000",
		date: "02/03/2021",
	},
	{
		id: 4,
		nom: "Daba Seck",
		image: user4,
		price: "1000",
		date: "02/03/2021",
	},
	{
		id: 5,
		nom: "Awa Sagna",
		image: user5,
		price: "1000",
		date: "02/03/2021",
	},
	{
		id: 6,
		nom: "Codou Ndiaye",
		image: user6,
		price: "1000",
		date: "02/03/2021",
	},
	{
		id: 7,
		nom: "Pauline Biaye",
		image: user7,
		price: "1000",
		date: "02/03/2021",
	},
];

const progressBar = {
	width: "259px",
	backgroundColor: "#E1E1E1",
	borderRadius: "10px",
};

const progressBarChild = {
	width: "60%",
	backgroundColor: "#115C67",
	borderRadius: "10px",
};

const DataCenter = ({ project }) => {
	var [isPageLoading, setIsPageLoading] = React.useState(true);

	useEffect(() => {
		setTimeout(() => {
			setIsPageLoading(false);
		}, 3000);
	}, []);

	return (
		<div>
			<div className="data-center bg-white">
				<div className="data-center-header">
					<div className="data-center-head">
						<div className="data-center-title">
							<NavLink to="/dashboard/projets">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M4.25 12.2754L19.25 12.2754"
										stroke="#200E32"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M10.2998 18.299L4.2498 12.275L10.2998 6.25"
										stroke="#200E32"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</NavLink>
							<h2 className="m-l-20">
								{project?.title}
							</h2>
						</div>

						<p>{project?.description}</p>
					</div>

					<hr />
					<div className="data-center-body">
						<div className="details">
							{/* {id: 3, deleted: null, name: 'Commerce de proximite', created_at: '2022-02-08T15:19:07.802530Z'} */}
							<p>
								Secteurs :{" "}
								<span className="detail-projet-info-val text-uppercase">
									{project?.secteur?.map(
										(item, index) =>
											item?.name +
											`${
												index <
												project
													?.secteur
													?.length -
													1
													? " - "	
													: " "
											}`
									)}
								</span>
							</p>
							<p>
								Date de création :{" "}
								<span>le {moment(project?.created_at).format("DD MMMM YYYY")}</span>
							</p>
						</div>
						<div className="progressBar">
							<p>Résumé de la campagne</p>
							<div className="progressBarChild">
								<p>
									<span>(65%)</span>{" "}
									<span>
										2 000 000 FCFA
										(collectés)
									</span>
								</p>
								<div
									style={progressBar}
									className="progress"
								>
									<div
										style={
											progressBarChild
										}
									></div>
								</div>
								<p className="objectif">
									sur un objectif de 3 000
									000 FCFA
								</p>
							</div>
						</div>
					</div>

					<hr />
					{isPageLoading && (
						<div className="data-center-table bg-white">
							<ul className="list-group list-group-flush">
								<li className="list-group-item liste-des-donnateurs">
									Listes des donnateurs
								</li>
								<DataCenterSkeleton />
							</ul>
						</div>
					)}
					{!isPageLoading && (
						<div className="data-center-table bg-white">
							<ul className="list-group list-group-flush">
								<li className="list-group-item liste-des-donnateurs">
									Listes des donateurs
								</li>

								{DataCenterData1.map(
									(element, index) => (
										<li
											className="list-group-item info-donnateur"
											key={index}
										>
											<div className="userData">
												<div className="user-photo">
													<img
														src={
															element.image
														}
														alt="Element"
													/>
												</div>
												<div className="user-data">
													<p>
														<span>
															{
																element.nom
															}
														</span>
														<span>
															{
																element.date
															}
														</span>
													</p>
												</div>
											</div>
											<div className="price">
												<p>
													{
														element.price
													}{" "}
													CFA
												</p>
											</div>
										</li>
									)
								)}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default DataCenter;
