import api from "../../Http/global-vars";

export const authenticate = {
  onLogin() {
    return `${api}auth/get-token/`;
  },
  onRegesister() {
    return `${api}auth/register/`;
  },
  onMe() {
    return `${api}me/`;
  },
  onUpdatePassword($id) {
    return `${api}changepassword/`
  },
  onRegisterSocial() {
    return `${api}auth/registersocial/`
  },
  onRequestResetPassword() {
    return `${api}request-password-reset/`
  },
  onResetPassword() {
    return `${api}reset-password/`
  }
};

export const Utilisateur = {
  onGetListUtilisateur() {
    return `${api}user/`;
  },
  onPostUtilisateur() {
    return `${api}user/`;
  },
  onEditUtilisateur($id) {
    return `${api}user/${$id}/`;
  },
  onDeleteUtilisateur($id) {
    return `${api}user/${$id}/`;
  },
};

export const Admin = {
  onGetListAdmin() {
    return `${api}admin/`;
  },
  onPostAdmin() {
    return `${api}admin/`;
  },
  onEditAdmin($id) {
    return `${api}admin/${$id}/`;
  },
  onDeleteAdmin($id) {
    return `${api}admin/${$id}/`;
  },
};

export const Secteur = {
  onGetListSecteur() {
    return `${api}secteur/`;
  },
  onPostSecteur() {
    return `${api}secteur/`;
  },
  onEditSecteur($id) {
    return `${api}secteur/${$id}/`;
  },
  onDeleteSecteur($id) {
    return `${api}secteur/${$id}/`;
  },
};



export const SousAdmin = {
  onGetListSousAdmin() {
    return `${api}sousadmin/`;
  },
  onPostSousAdmin() {
    return `${api}sousadmin/`;
  },
  onEditSousAdmin($id) {
    return `${api}sousadmin/${$id}/`;
  },
  onDeleteSousAdmin($id) {
    return `${api}sousadmin/${$id}/`;
  },
};

export const Notification = {
  onGetListNotification() {
    return `${api}notification/`;
  },
  onPostNotification() {
    return `${api}notification/`;
  },
  onEditNotification($id) {
    return `${api}notification/${$id}/`;
  },
  onDeleteNotification($id) {
    return `${api}notification/${$id}/`;
  },
};

export const Donation = {
  onGetListDonation() {
    return `${api}donation/`;
  },
  onPostDonation() {
    return `${api}donation/`;
  },
  onEditDonation($id) {
    return `${api}donation/${$id}/`;
  },
  onDeleteDonation($id) {
    return `${api}donation/${$id}/`;
  },
};

export const Projet = {
  onGetListProjet() {
    return `${api}projet/`;
  },
  onGetListProjetByUser($id) {
    return `${api}projetbyuser/${$id}/`;
  },
  onPostProjet() {
    return `${api}projet/`;
  },
  onEditProjet($id) {
    return `${api}projet/${$id}/`;
  },
  onDeleteProjet($id) {
    return `${api}projet/${$id}/`;
  },
  onPostCandidature() {
    return `${api}projetbyvisitor/`;
  },
  onEditCandidature($id) {
    return `${api}projetupdate/${$id}/`;
  },
  postProjectDocs() {
    return `${api}document/`;
  },
  onValidProject(id) {
    return `${api}valid_projet/${id}/`;
  },
  onSubscriber() {
    return `${api}subscriber/`
  },
  onFollow(){
    return `${api}follower/`
  },
};

export const Invest = {
  onGetListInvest() {
    return `${api}invest/`;
  },
  onPostInvest() {
    return `${api}invest/`;
  },
  onEditInvest($id) {
    return `${api}invest/${$id}/`;
  },
  onDeleteInvest($id) {
    return `${api}invest/${$id}/`;
  },
};