const HistoriquePretTableData = [
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
    {
        projet: 'DATA CENTER MBAO',
        score: '3',
        taux: '50%',
        preter: '20.000',
        percu: '5000',
        provision: '3',
        en_cours: '3'
        
    },
];

export default HistoriquePretTableData;
