// DEV BACKEND BASE URL
// var baseUrl = "https://back.cayor.withvolkeno.com";

// PROD BACKEND BASE URL
// var baseUrl = "https://prod.back.cayorfunding.com"

 var baseUrl = "https://api-easy-funding.fewnu.app/api/";

export default baseUrl;


