import React from "react";
import "./choixAutrePieceTelechargerInscription.css";

var ChoixAutrePieceTelechargerInscription = ({ navigation }) => {
  const { next } = navigation;

  return (
    <div className="component-front-register-infos-perso-two">
      <div className="card card-body card-telecharger-document">
        <div className="div-contenant">
          <h5 className="title-verification titre-document">
            Choisissez la photo d’une seconde pièce d’identité{" "}
          </h5>

          <div className="row mt-5">
            <div className="col-12">
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input style-radio"
                  name="choix-piece"
                  type="radio"
                  value=""
                  id="defaultCheck1"
                  defaultChecked
                />
                <label
                  className="form-check-label style-prev "
                  for="defaultCheck1"
                >
                  Permis de conduire
                </label>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 m-auto">
              <div class="form-check d-flex align-items-center">
                <input
                  class="form-check-input style-radio"
                  name="choix-piece"
                  type="radio"
                  value=""
                  id="defaultCheck2"
                />
                <label class="form-check-label style-prev" for="defaultCheck2">
                  Piéce d'identité
                </label>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 m-auto">
              <div class="form-check">
                <input
                  class="form-check-input style-radio"
                  name="choix-piece"
                  type="radio"
                  value=""
                  id="defaultCheck3"
                />
                <label class="form-check-label style-prev" for="defaultCheck3">
                  Titre de sejour
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="mt-5 mx-3 mx-md-0">
              <div className="boutton-div">
                <button
                  className="btn boutton-continuer"
                  type="button"
                  onClick={next}
                >
                  Continuer
                </button>
                {/* <NavLink to="/telecharger-photo-identite-inscription" className="btn boutton-continuer">Continuer</NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoixAutrePieceTelechargerInscription;
