import axios from "axios";
import { authenticate } from "./Endpoints";
// import axios from "axios";

export default {
  async onLogin(data) {
    const response = await axios.post(authenticate.onLogin(), data);
    return response.data;
  },
  async onRegister(data) {
    const response = await axios.post(authenticate.onRegesister(), data);
    return response.data;
  },
  async onMe(config) {
    const response = await axios.get(authenticate.onMe(), config);
    return response.data;
  },

  async onUpdatePassword(data, config) {
    const response =  await axios.put(authenticate.onUpdatePassword(), data, config)
    return response.data;
  },
  
  async onRegisterSocial(data) {
    const response = await axios.post(authenticate.onRegisterSocial(), data)
    return response.data;
  },

  async onRequestResetPassword(data) {
    const response = await axios.post(authenticate.onRequestResetPassword(), data)
    return response;
  },

  async onResetPassword(data) {
    const response = await axios.post(authenticate.onResetPassword(), data)
    return response;
  }
};
