import React, { useEffect } from "react";
import UseCreateSecteur from "../BackendV2/Admin/Parametre/request/UseCreateSecteur";

function AjouterSecteurActiviteModal() {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    clearErrors,
    reset,
  } = UseCreateSecteur();

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState, clearErrors]);

  const handleReset = () => {
    reset();
  };

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg ">
      <div className="profile-container-photo modal-content">
        <div className="modal-header">
          <h5 className="modal-ajouter" id="ajouterunadministrateur">
            Ajouter un secteur d'activité
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={onSubmit}>
          <div className="container-input-address">
            <div className="row">
              <div className="col-md-12">
                <div className="infos-perso-input">
                  <label className="form-label-text">Nom secteur d'activité</label>
                  <input
                    type="text"
                    className="form-control-profil"
                    name="nomSecteur"
                    placeholder="Nom secteur d'activité"
                    {...register("name")}
                  />
                  {formState?.errors && formState?.errors?.name && (
                    <div className="alert alert-danger epm-alert-danger closer m-t-10">
                      {formState?.errors?.name?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-end flex-column">
            <div className="ms-auto">
              {isSuccessfullySubmitted && (
                <button className="btn-modifier" disabled>
                  En cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
              {!isSuccessfullySubmitted && (
                <button className="btn-modifier">Enregistrer</button>
              )}
            </div>
          </div>
        </form>{" "}
      </div>
    </div>
  );
}

export default AjouterSecteurActiviteModal;
