import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProjetImg from "../../../images/image2.png";
import {} from "react-scroll/modules/mixins/scroller";
import DetailsTab from "../DetailDesProjet/DetailsTab";
import { useSelector } from "react-redux";

const ProjectCardPaie = () => {
  const projectsState = useSelector((state) => state.projectsForVisitors);
  const { data: projects, isLoading } = projectsState;
  const { projectId } = useParams();
  const [selectedProject, setSelectedProject] = useState();

  useEffect(() => {
    if (!isLoading && projects && projects.length > 0) {
      const projectFound = projects.find((p) => p.id == projectId);
      if (projectFound) {
        console.log("Projet trouvé:", projectFound);
        setSelectedProject(projectFound);
      } else {
        console.error("Projet non trouvé");
      }
    }
  }, [projectId, projects, isLoading]);

  return (
    <div className="component-details-projet col-md-6">
      <div className="content-view-start pb-5">
        <div className="container-cayore pt-3">
          <div className="row p-3">
            <div className="project-presentation-col">
              <div className="projet-title py-3">
                <h1 className="text-center">{selectedProject?.title}</h1>
              </div>
              <div className="image-card-container">
                <div className="image-detail">
                  <img
                    src={ProjetImg}
                    className="img-details"
                    alt="imagedetail-projet"
                  />
                </div>
                <div className="">
                    <DetailsTab project={selectedProject} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCardPaie;
