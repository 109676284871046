import { SousAdmin } from './Endpoints'
import axios from 'axios'

export default {
    async onGetListSousAdmin(config) {
        const response = await axios.get(SousAdmin.onGetListSousAdmin(), config);
        // console.log(response)
        return response.data;
    },
    async onAddSousAdmin(data,config) {
        const response = await axios.post(SousAdmin.onPostSousAdmin(),data,config );
        // console.log(response)
        return response.data;
    },
    async onEditSousAdmin(id,data,config) {
        const response = await axios.put(SousAdmin.onEditSousAdmin(id),data,config );
        // console.log(response)
        return response.data;
    },

    async onDeleteSousAdmin(id,config) {
        const response = await axios.delete(SousAdmin.onDeleteSousAdmin(id),config);
        // console.log(response)
        return response.data;
    },
    async validatedProject(id,config) {
        const response = axios.put(SousAdmin.onValidProject(id),config );
        return response.data;
    }

}