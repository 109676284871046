import React from "react";

const InformationsEntreprise = () => {
	return (
		<div className="containeredite pb-4">
			<h1 className="titreinfoperson">
				Informations générales de l’entreprise
			</h1>
			<div className="container-cayore-form mt-5">
				<div className="row">
					<div className="col-12 col-md-5 m-b-50">
						<div className="d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Nom de l’intstitution"
							/>
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50">
						<div className="d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Poste du représentant"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-5 m-b-50">
						<div className="d-flex flex-column">
							<input
								type="number"
								className=" inputformedit"
								placeholder="Numéro du SIRET"
							/>
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50">
						<div className="d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Ville d’imatriculation"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-5 m-b-50">
						<div className="d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Forme juridique"
							/>
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50">
						<div className="d-flex flex-column">
							<input
								type="text"
								className="inputformedit"
								placeholder="Adresse du siège social"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-5 m-b-50 ">
						<div className="d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Rue"
							/>
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50 ">
						<div className="d-flex flex-column">
							<input
								type="text"
								className="inputformedit"
								placeholder="Ville du siège social"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-5 m-b-50 ">
						<div className="d-flex flex-column">
							<input
								type="number"
								className="inputformedit"
								placeholder="Code postal du siège social"
							/>
						</div>
					</div>
					<div className="col-12 col-md-5 offset-md-2 m-b-50 ">
						<div className="d-flex flex-column">
							<input
								type="text"
								className=" inputformedit"
								placeholder="Pays du siège social"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-5 m-b-50 ">
						<div className="d-flex flex-column">
							<input
								type="number"
								className=" inputformedit"
								placeholder="Capital"
							/>
						</div>
					</div>
				</div>
				<h1 className="titreposte">Poste du représentant</h1>
				<div className=" form-group pb-5 ">
					<div className="form-quest-check ">
						<input
							type="radio"
							className="quest-control-input"
							id="president"
							name="posteRepresentant"
						/>
						<span className="checkmark"></span>
						<label
							className="quest-label"
							htmlFor="president"
						>
							Président
						</label>
					</div>
					<div className="form-quest-check">
						<input
							type="radio"
							className="quest-control-input"
							id="directeur"
							name="posteRepresentant"
						/>
						<label
							className="quest-label"
							htmlFor="directeur"
						>
							Directeur général
						</label>
					</div>
					<div className="form-quest-check">
						<input
							type="radio"
							className="quest-control-input"
							id="actionnaire"
							name="posteRepresentant"
						/>
						<label
							className="quest-label"
							htmlFor="actionnaire"
						>
							Actionnaire de plus de 25% des parts
						</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InformationsEntreprise;
