// import api from "../../http/global-vars";
import api from "../../Http/global-vars";
import { ME } from "../types";
import axios from "axios";

export const fetchMe = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
      },
    };
    let token = window.sessionStorage.getItem("userToken");
    const url = api + "me/";
    if (token) {
      await axios
        .get(url, config)
        .then(async (res) => {
          // const data = await res.json();
          // console.log("data me", res);
          window.sessionStorage.setItem("userID", res?.data?.data?.id);
          dispatch({
            type: ME,
            payload: res?.data?.data,
          });
        })
        .catch((error) => console.log("error", error?.response));
    }
  };
};
