import React from "react";
import * as IconlyPack from "react-iconly";

const DocumentsTab = ({ project }) => {
	return (
    <div
      className="tab-pane fade"
      id="pills-actu"
      role="tabpanel"
      aria-labelledby="pills-actu-tab"
    >
     <div className="d-flex justify-content-start">
          {project?.files.length ? (
            project.files.map((file,index) => (
              <div className="" key={index}>
                <a href={`https://api-easy-funding.fewnu.app${file?.doc}`} target="_blank" rel="noopener noreferrer" className="text-dark pt-3">
                  <div className="card p-5">
                    <div className="d-flex align-items-baseline">
                      <p><IconlyPack.Document style={{ color: '#6B92DC' }} set="bold" /></p>&ensp;
                      <p className="carte-text">{file?.name || "Nom indéfini"}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))
          ) : (
            <div className="gfa-rowy mt-5">
              <div className="alert alert-warning gfa-warning">
                Aucun document pour ce projet
              </div>
            </div>
          )}
        </div>
    </div>
  );
};

export default DocumentsTab;
