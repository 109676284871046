import React, { useEffect, useState } from "react";
import HeaderV2 from "../../../BackendV2/Admin/Navs/HeaderV2";
// import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
// import Dashboard from "./Dashboard";
import SidebarMobileTableauBord from "../SidebarTableauBord/SidebarMobileTableauBord";
import SidebarTableauBord from "../SidebarTableauBord/SidebarTableauBord";
import { useSelector, useDispatch } from "react-redux";
import {fetchInvestisseurs} from '../../../../../src/redux/investisseurs/investisseurAction'
import CardTableauDeBord from "./CardTableauDeBord";

const TableauDeBord = () => {
  const current_user = useSelector((state) => state.user).data;
  // console.log(current_user)
  const InvestiProject = useSelector((state) => state.investisseurs);
  const dispatch = useDispatch();
  const { filteredData } = InvestiProject;
  dispatch(fetchInvestisseurs());

  const [numberOfProjectInvest, setNumberOfProjectInvest] = useState(0);

  useEffect(() => {
    if (filteredData) {
      // Map through all projects and count those invested by the current user
      const countInvestedProjects = filteredData.reduce((count, project) => {
        if (project.id === current_user.id) {
          return count + 1;
        }
        return count;
        
      }, 0);
      // Set the count of invested projects
      setNumberOfProjectInvest(countInvestedProjects);
    }
  }, [filteredData, current_user.id]);

  const Table = [
    {
      id: 0,
      title: "Nombre de projets investis",
      nbr: numberOfProjectInvest,
    },
    {
      id: 1,
      title: "Chiffre investi",
      nbr: 350000,
    },
    {
      id: 2,
      title: "Montant encaissé",
      nbr: 500000,
    },
  ];
 
  return (
    <div>
      <div className="component-investisseur">
        <HeaderV2 />
        <div className="content px-2 my-4 d-flex py-0 content-title-page-tb-investisseur">
          <div className="d-flex align-items-lg-center">
            {/* <Dashboard /> */}
          </div>
        </div>
        <div className="content-view-start">
          <div className="row">
            <div className="col-xl-3 col-lg-4 no-view-mobile">
              <SidebarTableauBord />
            </div>
            <div className="col-xl-9 col-lg-8 px-0 pr-4">
              <div className="no-see-desktop-sidebar margin-top-sidebar-mobile">
                <SidebarMobileTableauBord />
              </div>
              <section className="mb-5 section-content-page-tb-investisseur">
                <div className="p-5">
                  <div className="row">
                    {Table.map((item, id) => (
                      <div className="col-md-4" key={id}>
                        <CardTableauDeBord
                          data={{
                            title: item.title,
                            nbr: item.nbr,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        {/* <FrontFooterV2 /> */}
      </div>
    </div>
  );
};

export default TableauDeBord;
