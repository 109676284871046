import React, {  useEffect, useState } from "react";
import "./Resume.css";
import SidebarTableauBord from "../SidebarTableauBord/SidebarTableauBord";
import SidebarMobileTableauBord from "../SidebarTableauBord/SidebarMobileTableauBord";
import HeaderV2 from "../../../BackendV2/Admin/Navs/HeaderV2";
// import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import ActionsDuMoisTable from "../../../Tables/TableauDeBordInvestisseur/ActionsDuMoisTable/ActionsDuMoisTable";
import financement from "../../../../services/financement";
import { useSelector } from "react-redux";
// import Dashboard from "../MonTableauDeBord/Dashboard";

const Resume = () => {
	const [investissements, setInvestissements] = useState([]);
	const [projectInvestis, setProjectInvestis] = useState([]);
	const users = useSelector((state) => state.user);
  	const userData = users.data ? users.data : [];
	const config = {
		headers: {
		  Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
		},
	};

	const request = financement.getAllFinancement(config);
	request.then((res) => {
		setInvestissements(res.results)
	})

	useEffect(() => {
		if (investissements.length > 0 && userData.slug) {
		  filterInvests();
		}
	  }, [investissements, userData.slug]);
	  
	  const filterInvests = () => {
		const filteredInvestissements = investissements.filter(investissement => {
		  return investissement.investisseur.slug === userData.slug;
		});
		setProjectInvestis(filteredInvestissements);
	  }
	  
	return (
		<div className="component-investisseur">
			<HeaderV2 />
			<div className="content px-2 my-4 d-flex py-0 content-title-page-tb-investisseur">
				<div className="d-flex align-items-lg-center">
					{/* <Dashboard/> */}
				</div>
			</div>
			<div className="content-view-start">
				<div className="row">
					<div className="col-xl-3 col-lg-4 no-view-mobile">
						<SidebarTableauBord />
					</div>
					<div className="col-xl-9 col-lg-8 px-0 pr-4">
						<div className="no-see-desktop-sidebar margin-top-sidebar-mobile">
							<SidebarMobileTableauBord />
						</div>
						<section className="mb-5 section-content-page-tb-investisseur">
							<div className="bg-white p-3">
								<div className="page-first-row-container border-bottom">
									<div className="page-title-container pt-3">
										<h1 className="page-title">
											Les projets financés
										</h1>
									</div>
								</div>
								<ActionsDuMoisTable project={projectInvestis ? projectInvestis : ""} />
							</div>
							{/* <div className="bg-white my-3 repartition-pret">
								<div className="page-first-row-container border-bottom">
									<div className="page-title-container my-4">
										<h1 className="page-title">
											Répartitions
											de vos prêts
											du mois
										</h1>
									</div>
								</div>
								<div className="pt-3">
									<div className="row">
										<div className="col-xl-6 col-lg-6 col-md-6">
											<p className="titre-repartition-detail pb-3">
												Durée
											</p>
											<div className="width-bloc">
												<div className="pb-3">
													<p className="text-progress-investisseur">
														0-35
														mois{" "}
														<span className="color-theme">
															40
															000FCFA
														</span>
													</p>
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: "100%",
															}}
															aria-valuenow="100"
															aria-valuemin="0"
															aria-valuemax="100"
														></div>
													</div>
												</div>
												<div className="pb-3">
													<p className="text-progress-investisseur">
														30
														-
														59mois{" "}
														<span className="color-theme">
															0FCFA
														</span>
													</p>
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: "0",
															}}
															aria-valuenow="100"
															aria-valuemin="0"
															aria-valuemax="100"
														></div>
													</div>
												</div>
												<div className="pb-3">
													<p className="text-progress-investisseur">
														60mois
														et
														plus{" "}
														<span className="color-theme">
															0FCFA
														</span>
													</p>
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: "0",
															}}
															aria-valuenow="100"
															aria-valuemin="0"
															aria-valuemax="100"
														></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6">
											<p className="titre-repartition-detail pb-3">
												Pays
											</p>
											<div className="width-bloc">
												<div className="pb-3">
													<p className="text-progress-investisseur">
														Sénégal{" "}
														<span className="color-theme">
															40
															000FCFA
														</span>
													</p>
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: "100%",
															}}
															aria-valuenow="100"
															aria-valuemin="0"
															aria-valuemax="100"
														></div>
													</div>
												</div>
												<div className="pb-3">
													<p className="text-progress-investisseur">
														France{" "}
														<span className="color-theme">
															0FCFA
														</span>
													</p>
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: "0",
															}}
															aria-valuenow="100"
															aria-valuemin="0"
															aria-valuemax="100"
														></div>
													</div>
												</div>
												<div className="pb-3">
													<p className="text-progress-investisseur">
														Gabon{" "}
														<span className="color-theme">
															0FCFA
														</span>
													</p>
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: "0",
															}}
															aria-valuenow="100"
															aria-valuemin="0"
															aria-valuemax="100"
														></div>
													</div>
												</div>
												<div className="pb-3">
													<p className="text-progress-investisseur">
														Tunisie{" "}
														<span className="color-theme">
															0FCFA
														</span>
													</p>
													<div className="progress">
														<div
															className="progress-bar"
															role="progressbar"
															style={{
																width: "0",
															}}
															aria-valuenow="100"
															aria-valuemin="0"
															aria-valuemax="100"
														></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="pt-5">
									<div className="page-first-row-container border-bottom">
										<div className="page-title-container pt-3">
											<h1 className="page-title">
												Secteurs
												d’activités
											</h1>
										</div>
									</div>
									<div className="pt-5">
										<div className="row">
											<div className="col-xl-6 col-lg-6 col-md-6">
												<div className="width-bloc">
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Agriculture{" "}
															<span className="color-theme">
																20
																000
																FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "60%",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															BTP{" "}
															<span className="color-theme">
																80
																000
																FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "80%",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Communication{" "}
															<span className="color-theme">
																100
																000
																FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "100%",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Enseignement{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Etudes
															&
															conseils{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Finance{" "}
															<span className="color-theme">
																30
																000
																FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "50%",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Immobilier{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Information
															&
															communication{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Matières
															premières{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Secteurs
															pubics{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Services
															&
															particuliers{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-6">
												<div className="width-bloc">
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Assurance
															0
															FCFA{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Commerce
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Energie{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Environnement{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Evènementiel{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Hôtelerie/Restauration{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Industrie{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Loisirs{" "}
															<span className="color-theme">
																40
																000FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Santé{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Services
															aux
															entreprises{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "0",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
													<div className="pb-3">
														<p className="text-progress-investisseur">
															Transport{" "}
															<span className="color-theme">
																0FCFA
															</span>
														</p>
														<div className="progress">
															<div
																className="progress-bar"
																role="progressbar"
																style={{
																	width: "100%",
																}}
																aria-valuenow="100"
																aria-valuemin="0"
																aria-valuemax="100"
															></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						</section>
					</div>
				</div>
			</div>
			{/* <FrontFooterV2 /> */}
		</div>
	);
};

export default Resume;
