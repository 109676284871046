import React from "react";
import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import SidebarTableauDeBord from "../SidebarTableauDeBord/SidebarTableauDeBord";
import TabsLink from "../TabsLink/TabsLink";
import "./ModificationMotDePasse.css";
import FrontHeaderV2 from "../../FrontHeaderV2/FrontHeaderV2";
import ModificationMotDePasseComponent from "./ModificationMotDePasseComponent";

const ModificationMotDePasse = ({ saveData }) => {
	
	return (
		<div className="component-mes-projet-tableau-de-bord">
			<FrontHeaderV2 />
			<TabsLink />
			<div className="mt-3 row p-0 bloc-principal-changer-motdepasse">
				<div className="col-lg-4 col-xl-3">
					<SidebarTableauDeBord value={0} />
				</div>
				<div className="col-lg-8 col-xl-9">
					<ModificationMotDePasseComponent />
				</div>
			</div>
			<FrontFooterV2 />
		</div>
	);
};

export default ModificationMotDePasse;
