import React, { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../Http/global-vars";
import axios from "axios";
import "./inscrireNumeroTelephoneInscription.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const InscrireNumeroTelephoneInscription = ({ navigation }) => {
  const { next } = navigation;
  const user_id = window.sessionStorage.getItem("userID");
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitPhone = async (data) => {
    let phone = value;
    //  let data = {date_naissance,sexe,adresse}
    console.warn("mamam", value);
    var fd = new FormData();
    fd.append("phone", phone);
    axios
      .put(`${api}user/${user_id}/`, fd, requestConfig)
      .then(async (response) => {
        console.log("responsnsnsns", response);
        Swal.fire({
          position: "center",
          icon: "success",
          title:
            "Un code vient de vous être envoyé.Veuillez de ne pas le communiquer s'il vous plait.",
          showConfirmButton: true,
          iconColor: "#BB4411",
          confirmButtonColor: "#BB4411",
        });
        setTimeout(() => {
          // window.location = '/app/inscription-verification-mail'
          navigate("/condition-utilisation");
        }, 3000);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  return (
    <div className="component-front-register-infos-perso-two">
      <div className="card card-body card-telecharger-document">
        <div className="div-contenant">
          <h5 className="title-verification titre-document">
            Inscrivez votre numéro de téléphone
          </h5>
          <div class="champ-texte-tel style-text-RIB p-2">
            <p className="style-text-RIB2">
              Votre portefeuille est l’endroit de transfert de votre argent
              (fonds,crédits, débits, remboursements, reçus, taxes....). Afin de
              le protéger, vous devez le sécuriser avec un code{" "}
              <font className="color-sms">SMS</font>
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmitPhone)}>
            <div className="">
              <div className=" justify-content-center mt-5">
                <div class="">
                  <PhoneInput
                    value={value}
                    onChange={setValue}
                    name="phone"
                    rules={{ required: true }}
                  />
                </div>
                <br></br>
                {errors?.phone && (
                  <div className="alert alert-danger easypm-alert-danger">
                    {""}
                    {errors?.phone?.message}
                    {""}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="mx-3 mx-md-0 mt-5">
                <div className="boutton-div">
                  <button
                    className="btn boutton-continuer"
                    type="submit"
                    onClick={next}
                  >
                    ENREGISTRER
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InscrireNumeroTelephoneInscription;
