import React, {useEffect} from 'react'
import { BootstrapTable, TableHeaderColumn, ClearSearchButton } from 'react-bootstrap-table';
import CalendrierRemboursementTableData from './CalendrierRemboursementTableData'
import CalendrierRemboursementTableSkeleton from './CalendrierRemboursementTableSkeleton'
// import * as IconlyPack from 'react-iconly';
// import { NavLink } from 'react-router-dom'

const CalendrierRemboursementTable = (props) => {
    var [isPageLoading, setIsPageLoading] = React.useState(true);
    // eslint-disable-next-line
    var [remboursement, setRemboursement] = React.useState(CalendrierRemboursementTableData)

    useEffect(() => {
        setTimeout(() => {
            setIsPageLoading(false)
        }, 5000);
    }, [remboursement])

    var handleClearButtonClick = (onClick) => {
        console.log('This is my custom function for ClearSearchButton click event');
        onClick();
    }

    var createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => handleClearButtonClick(onClick)} />
        );
    }

    const options = {
        clearSearch: true,
        clearSearchBtn: createCustomClearButton,
        noDataText: 'Aucune donnée disponible'
    }

    // const actionFormatter = (cell, row) => {
    //     return(
    //         <div className="table-actions-btn-container">
    //             <NavLink to={`/admin/profil-client/${row.id}`} className="action-btn show-btn">
    //                 <IconlyPack.Show set="light" primaryColor="#C8C8C8" className="action-btn-icon action-show-icon"/>
    //             </NavLink>
    //             <button className="action-btn edit-btn">
    //                 <IconlyPack.Edit set="light" primaryColor="#C8C8C8" className="action-btn-icon action-edit-icon"/>
    //             </button>
    //         </div>
    //     )
    // }

    const currencyFormatter = (cell) => {
        // const num = 1234567890.1234;
        const formatConfig = {
          style: "currency",
          currency: "XOF",
          minimumFractionDigits: 0,
          currencyDisplay: "symbol",
          currencySign: "accounting",
        };
    
        // setup formatters
        const xofNumberFormatter = new Intl.NumberFormat("sn-SN", formatConfig);
        return xofNumberFormatter.format(cell);
    };
    



    return(
        <div className="child-table-container">
            <div className="table-body-container-activite">

                <div className="row easypm-table-row mb-5">
                    <div className="col-md-12 easypm-table-col">
                        {isPageLoading &&
                            <CalendrierRemboursementTableSkeleton />
                        }
                        {!isPageLoading &&
                            <div className="table-container">
                                <BootstrapTable data={remboursement}
                                    striped={true} hover={true} condensed={true}
                                    multiColumnSort={2} options={options}
                                    search={false} version='4'
                                    bordered={false}>
                                    <TableHeaderColumn
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#BB4411' }}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                                        width="100" dataField='date' isKey={true} >Date</TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#BB4411' }}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                                        width="100" dataField='echeance'>Echéance</TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#BB4411' }}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                                        width="100" dataField='interet'>Interêt</TableHeaderColumn>
                                    <TableHeaderColumn
                                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#BB4411' }}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)' }}
                                        width="100" dataField='capital' dataFormat={(cell) => currencyFormatter(cell)}>Capital</TableHeaderColumn>
                                    
                                    <TableHeaderColumn 
                                        thStyle={{fontWeight: 600, fontSize: 16, color: '#BB4411'}}
                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)'}} 
                                        width="100" dataField='en_cours'>En cours</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalendrierRemboursementTable;