import React from "react";

const AproposText = () => {
  return (
    <div className="p-5 apropos-container">
      <div className="">
        <h1 className="pb-5 titre-a-propos-text">À propos de nous</h1>
      </div>
      <div>
        <p className="text-ap-propos-text">
          Nous sommes des passionnés de création de valeur en mission pour
          dynamiser l’écosystème entrepreneurial en facilitant le financement
          des entrepreneurs par une large communauté d’investisseurs engagés
        </p>
      </div>
    </div>
  );
};

export default AproposText;
