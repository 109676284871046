import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import Secteur from "../../../../Services/Secteur";
import { fetchSecteurs } from "../../../../../redux/secteurs/secteurAction";

function UseEditSecteur() {
  const dispatch = useDispatch();
  //   const user = useSelector((state) => state.user);
  const [isDone, setIsDone] = React.useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required("Le nom est requis."),
        id: yup.number(),
      }),
    []
  );
  

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };

  // console.log(secteurId)
  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    
    var request = Secteur.onEditSecteur(formValues?.id, formValues, config);

    await request
    .then(async (resp) => {
      await dispatch(fetchSecteurs());
      await Swal.fire({
        icon: "success",
        title: "Secteur d'activité modifié avec succès",
        showConfirmButton: false,
        timer: 1500,
      });
      await setIsSuccessfullySubmitted(false);
      await window.$("#editunadministrateur").modal("hide");
      await setIsDone(true);
      await e.target.reset();
      await reset();
      setTimeout(() => {
        setIsDone(false);
      }, 1000);
    })
    .catch((error) => {
      // console.error("error", error, error?.response);
      if (
        error?.response?.data?.message
      ) {
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (
        (error?.response?.data?.error?.nomSecteur ===
            "name is already exists.")
      ) {
        //   toast.error("Email ou mot de passe incorrect.");
        Swal.fire({
          icon: "error",
          title:
            "Un secteur d'activité déjà sur ce nom",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (error?.response?.data?.status === "failure") {
        //   toast.error(error?.response?.data);
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (error?.response?.data?.message) {
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (error?.response?.status === 500) {
        Swal.fire({
          icon: "error",
          title:
            "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
          showConfirmButton: false,
          timer: 2000,
        });
      } else if (error?.response?.status === 400) {
        Swal.fire({
          icon: "error",
          title:
            "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      setIsSuccessfullySubmitted(false);
    });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
    isDone,
  };
}

export default UseEditSecteur;
