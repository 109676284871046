import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import editimg from "../../../../../images/others/default-profile-avatar.png";
import baseUrl from "../../../../../Http/backend-base-url";

const InformationsPersonnellesInvestisseur = ({
	register,
	errors,
	startDate,
	handleChangeDate,
	sexeUser,
	handleChangeSexe,
	handleChangeAvatar,
	data,
	avatarProfile,
}) => {
	console.log('avatarProfile:', avatarProfile);
	console.log('donne', data);
	return (
		<div className="containeredite pb-4">
			{/* <div className="imgeditcontent">
				<div className="user-avatar-img-container">
					<img
						src={
							avatarProfile
								? avatarProfile
								: data?.avatar &&
								  !data.avatar.includes(
									"default.png"
								  )
								? baseUrl + data.avatar
								: editimg
						}
						className="user-avatar-img "
						alt="avatar-user"
						id="avatar"
					/>
					<div className="overload">
						<label
							className="user-avatar-label"
							htmlFor="avatar"
						>
							Changer votre avatar
						</label>
						<input
							type="file"
							className="edit-avatar-btn"
							name="avatar"
							onChange={handleChangeAvatar}
							title=""
							accept="image/*"
						/>
					</div>
				</div>
			</div> */}
			<div className="contentpersonnel px-5 pt-3 pb-5">
				<h1 className="titreinfoperson">
					Informations personnelles
				</h1>
				<div className="container-cayore-form mt-5">
					<div className="content-choix m-b-50">
						<div className="d-flex justify-content-start ">
							<p className="mr-5 align-self-baseline civilite">
								Civilité
							</p>
							<div className="civilite-btn-radio-container">
								<div
									className={`civilite-btn-radio-item ${
										sexeUser ===
											"homme" &&
										" checked-civilite"
									}`}
									htmlFor="mme-civilite"
								>
									<input
										type="radio"
										value="homme"
										name="sexe"
										id="mr-civilite"
										className="civilite-btn-radio"
										onChange={
											handleChangeSexe
										}
									/>
									<label htmlFor="mr-civilite">
										Mr
									</label>
								</div>
								<div
									className={`civilite-btn-radio-item ${
										sexeUser ===
											"femme" &&
										" checked-civilite"
									}`}
								>
									<input
										type="radio"
										value="femme"
										name="sexe"
										id="mme-civilite"
										className="civilite-btn-radio"
										onChange={
											handleChangeSexe
										}
									/>
									<label htmlFor="mme-civilite">
										Mme
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-5 m-b-50">
							<div className=" d-flex flex-column">
								<input
									type="text"
									className=" inputformedit"
									placeholder="Prénom*"
									{...register("prenom")}
								/>
								{errors?.prenom && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors?.prenom
												?.message
										}
									</div>
								)}
							</div>
						</div>
						<div className="col-12 col-md-5 offset-md-2 m-b-50">
							<div className=" d-flex flex-column">
								<input
									type="text"
									className=" inputformedit"
									placeholder="Nom*"
									{...register("nom")}
								/>
								{errors?.nom && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors?.nom
												?.message
										}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-5 m-b-50">
							<div className=" d-flex flex-column">
								<input
									type="email"
									className=" inputformedit"
									placeholder="Email*"
									{...register("email")}
								/>
								{errors?.email && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors?.email
												?.message
										}
									</div>
								)}
							</div>
						</div>
						<div className="col-12 col-md-5 offset-md-2 m-b-50">
							<div className=" d-flex flex-column">
								<input
									type="tel"
									className=" inputformedit"
									placeholder="Téléphone*"
									{...register("phone")}
								/>
								{errors?.phone && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors?.phone
												?.message
										}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-5 m-b-50">
							<div className=" d-flex flex-column">
								<input
									type="text"
									className=" inputformedit"
									placeholder="Pays"
									{...register("pays")}
								/>
								{errors?.pays && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors?.pays
												?.message
										}
									</div>
								)}
							</div>
						</div>
						<div className="col-12 col-md-5 offset-md-2 m-b-50">
							<div className=" d-flex flex-column">
								<input
									type="text"
									className=" inputformedit"
									placeholder="Ville"
									{...register("ville")}
								/>
								{errors?.ville && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors?.ville
												?.message
										}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="row ">
						<div className="col-12 col-md-5 m-b-50 ">
							<div className=" d-flex flex-column">
								<input
									type="text"
									className=" inputformedit"
									placeholder="Rue"
									{...register("rue")}
								/>
								{errors?.rue && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors?.rue
												?.message
										}
									</div>
								)}
							</div>
						</div>
						<div className="col-12 col-md-5 offset-md-2 m-b-50 ">
							<div className=" d-flex flex-column">
								<input
									type="text"
									className=" inputformedit"
									placeholder="Code postal"
									{...register(
										"code_postal"
									)}
								/>
								{errors?.code_postal && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors
												?.code_postal
												?.message
										}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="row ">
						<div className="col-12 col-md-5 m-b-50 ">
							<div className=" d-flex flex-column">
								<DatePicker
									className=" inputformedit"
									locale="fr"
									showYearDropdown
									dateFormat={"dd/MM/yyyy"}
									selected={startDate}
									onChange={(date) =>
										handleChangeDate(
											date
										)
									}
									placeholderText="Date de naissance"
								/>
								{errors?.date_naissance && (
									<div
										className="alert alert-danger gfa-alert-danger"
										role="alert"
									>
										{
											errors
												?.date_naissance
												?.message
										}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InformationsPersonnellesInvestisseur;
