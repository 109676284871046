import React from "react";
import "./SeFinancer.css";
import { Link } from "react-router-dom";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import unsplash_image1 from "../../../assets/images/unsplash_image 1.png";
import unsplash_image2 from "../../../assets/images/unsplash_image2.png";
import unsplash_image3 from "../../../assets/images/unsplash_image3.png";
import unsplash_image4 from "../../../assets/images/unsplash_image4.png";
import amico from "../../../assets/images/amico.png";
import Num1 from "../../../images/icons/number1.png";
import Illust1 from "../../../images/rafiki.png";
import Num2 from "../../../images/icons/number2.png";
import Illust2 from "../../../images/anno.png";
import Num3 from "../../../images/icons/number3.png";
import Illust3 from "../../../images/pana.png";
import Num4 from "../../../images/icons/number4.png";
import Illust4 from "../../../images/task.png";
import Num5 from "../../../images/icons/number5.png";
import Illust5 from "../../../images/email.png";
import Num6 from "../../../images/icons/number6.png";
import Illust6 from "../../../images/product.png";
import Num7 from "../../../images/icons/number7.png";
import Illust7 from "../../../images/cuate.png";
import { useEffect } from "react";

const SeFinancer = (props) => {
  const userType = window.sessionStorage.getItem("userType")
  useEffect(() => {
    window.scrollTo(0,0)
  })
  return (
    <div className="component-front-home">
      <FrontHeaderV2 />
      <div className="investir-page-couverture-container">
        <div className="container-cayore investir-intro-container">
          <div className="row investir-couverture-row">
            <div className="col-md-8 investir-couverture-left-side">
              <div className="funding-couverture-textual-container">
                <p>
                  Financez <span className="color-span"> simplement </span>{" "}
                  <br />
                  et <span className="color-span"> rapidement</span> votre
                  entreprise
                </p>
              </div>
              <div className="funding-couverture-btn-container">
                <div className="test-link-btn-container">
                  <Link
                    className="btn btn-success test-link-btn"
                    to={`${userType === "investisseur" ? "" : "/questionnaire-pour-se-financer"}`}
                    disabled={userType === "investisseur"}
                  >
                    Déposer votre projet
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4 investir-couverture-image-right-side">
              <div className="investir-couverture-image-container">
                <img
                  src={unsplash_image1}
                  className="investir-couverture-image"
                  alt="Choisir votre épargne"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="how-funding-section-container">
        <div className="container-cayore how-funding-container">
          <div className="how-funding-section-title-container">
            <h1 className="how-funding-section-title">
              Comment financer votre entreprise ?
            </h1>
          </div>
          <div className="container-cayore funding-description-container">
          <div className="row funding-description-item-row">
            <div className="col-md-6 funding-description-illustration-col">
              <div className="funding-description-illustration-container">
                <img
                  src={unsplash_image3}
                  className="funding-description-illustration-img"
                  alt="finance-description-img"
                />
              </div>
            </div>
            <div className="col-md-6 funding-description-textual-content-col">
              <div className="funding-description-textual-content-container">
                <h3 className="funding-description-textual-title">
                  Financez la création ou la croissance de votre entreprise
                </h3>
                <div className="funding-description-textual-container">
                  <p className="funding-description-textual">
                    Nous accompagnons des entrepreneurs ambitieux et passionnés
                    à tout stade de développement. Nous réalisons des levées de
                    fonds en actions (fonds propres), en obligations (dette) ou
                    en vous mettant en relation avec des investisseurs engagés.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row funding-description-item-row d-flex flex-md-row-reverse">
            <div className="col-md-6 funding-description-illustration-col">
              <div className="funding-description-illustration-container">
                <img
                  src={unsplash_image4}
                  className="funding-description-illustration-img"
                  alt="levee-fond-img"
                />
              </div>
            </div>
            <div className="col-md-6 funding-description-textual-content-col">
              <div className="funding-description-textual-content-container">
                <h3 className="funding-description-textual-title">
                  Une levée de fonds simple et rapide
                </h3>
                <div className="funding-description-textual-container">
                  <p className="funding-description-textual">
                    Une fois votre projet accepté, nous vous accompagnons à
                    chaque étape : la création de votre page projet et de votre
                    plan de communication, la mise en ligne de votre collecte,
                    ainsi que la gestion de vos investisseurs et le versement
                    des fonds. En moyenne, une collecte dure entre 2 et 3 mois
                    selon la solution de financement définie.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row funding-description-item-row">
            <div className="col-md-6 funding-description-illustration-col">
              <div className="funding-description-illustration-container">
                <img
                  src={unsplash_image2}
                  className="funding-description-illustration-img"
                  alt="plus-un-financement-img"
                />
              </div>
            </div>
            <div className="col-md-6 funding-description-textual-content-col">
              <div className="funding-description-textual-content-container">
                <h3 className="funding-description-textual-title">
                  Plus qu'un financement, un levier de croissance fort
                </h3>
                <div className="funding-description-textual-container">
                  <p className="funding-description-textual">
                    Le financement participatif est un véritable tremplin pour
                    votre entreprise. Vos investisseurs deviennent de réels
                    ambassadeurs. Ils sont vos premiers clients, vos testeurs
                    sur de nouveaux concepts, mais surtout un moyen de
                    communication puissant pour faire résonner votre projet. Une
                    collecte vous permettra également de faire effet de levier
                    auprès de partenaires bancaires.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>

      <section className="funding-description-section-container">
      <p className="how-funding-section-label">
              Trois modalités de financement (en mode public ou semi-privé)
            </p>
          <div className="funding-process-row row">
            <div className="col-md-6 funding-process-illustration-col">
              <div className="funding-process-illustration-col-content">
                <div className="funding-process-illustration-container">
                  <img
                    src={amico}
                    className="funding-process-illustration-img"
                    alt="Comment financer votre entreprise"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 funding-process-textual-col">
              <div className="funding-process-textual-row row">
                <div className="col-md-12 funding-process-item-col">
                  <h2 className="funding-process-item-title">1. En Actions</h2>
                  <div className="funding-process-item-content">
                    <p>
                      Ouvrez le capital de votre entreprise.
                      <br />
                      De 10% à 49%.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 funding-process-item-col">
                  <h2 className="funding-process-item-title">
                    2. En Obligations
                  </h2>
                  <div className="funding-process-item-content">
                    <p>
                      Empruntez avec interêts. <br />
                      De 1 million à 100 millions de Fcfa
                    </p>
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end pt-5">
                  <Link
                    className="btn btn-success test-link-btn"
                    to={`${userType === "investisseur" ? "se-financer" : "/questionnaire-pour-se-financer"}`}
                    disabled={userType === "investisseur"}
                  >
                  Déposer votre projet
                  </Link>
                </div>
              </div>
            </div>
          </div>
      </section>

      <section className="raise-money-container container-cayore">
        <div className="row">
          <div className="raise-money-title-bulle-container col-12">
            <h1 className="raise-money-title">
              Comment lever des fonds pour pour <br /> votre entreprise?
            </h1>
          </div>
        </div>
        <div className="row raise-money-step-container">
          <div className="py-4">
            <div className="d-flex align-items-center">
              <img src={Num1} alt="numero" className="img-num" />
              <span className="text-num-ccm pl-2">Déposer son dossier</span>
            </div>
            <div className="py-3 d-flex">
              <img src={Illust1} alt="illustration" className="img-illust" />
              <div className="pl-4">
                <p className="texte-ccm-home">
                  Une démarche simple et sécurisée. Compléter le questionnaire
                  en ligne « Test d’éligibilité » et soumettre les pièces
                  nécessaires à l’analyse de votre dossier.
                </p>
              </div>
            </div>
          </div>
          <div className="pb-4">
            <div className="d-flex align-items-center">
              <img src={Num2} alt="numero" className="img-num" />
              <span className="text-num-ccm pl-2">
                Analyse de votre dossier
              </span>
            </div>
            <div className="py-3 d-flex">
              <div className="pr-4">
                <p className="texte-ccm-home">
                  Dans un délai de 72h, un de nos analystes prendra contact avec
                  vous pour confirmer l’éligibilité de principe de votre dossier
                  et dès lors, initier le travail de Due Diligence de votre
                  entreprise.
                </p>
              </div>
              <img src={Illust2} alt="illustration" className="img-illust" />
            </div>
          </div>
          <div className="pb-4">
            <div className="d-flex align-items-center">
              <img src={Num3} alt="numero" className="img-num" />
              <span className="text-num-ccm pl-2">
                Comité de validation du dossier
              </span>
            </div>
            <div className="py-3 d-flex">
              <img src={Illust3} alt="illustration" className="img-illust" />

              <div className="pl-4">
                <p className="texte-ccm-home">
                  Une fois les analyses et Due Diligences terminées, un comité
                  interne d’investissement se réunit pour statuer sur votre
                  projet. En cas de validation, le Comité confirmera les
                  éléments clés du financement participatif envisagé (selon
                  l’instrument financier : valorisation de l’entreprise, profil
                  de la dette (taux d’intérêt, durée), % ...).
                </p>
              </div>
            </div>
          </div>
          <div className="pb-4">
            <div className="d-flex align-items-center">
              <img src={Num4} alt="numero" className="img-num" />
              <span className="text-num-ccm pl-2">
                Création de la fiche projet
              </span>
            </div>
            <div className="py-3 d-flex">
              <div className="pr-4">
                <p className="texte-ccm-home">
                  Nos équipes travailleront avec vous pour parfaire les éléments
                  de présentation de votre projet (Teaser, Pitch Deck…).
                </p>
              </div>
              <img src={Illust4} alt="illustration" className="img-illust" />
            </div>
          </div>
          <div className="pb-4">
            <div className="d-flex align-items-center">
              <img src={Num5} alt="numero" className="img-num" />
              <span className="text-num-ccm pl-2">
                Lancement de la campagne
              </span>
            </div>
            <div className="py-3 d-flex">
              <img src={Illust5} alt="illustration" className="img-illust" />
              <div className="pl-4">
                <p className="texte-ccm-home">
                  Votre projet est mis en ligne sur la plateforme. Nous vous
                  accompagnons dans vos efforts de communication. Nous gérons
                  les souscriptions en ligne et les versements.
                </p>
              </div>
            </div>
          </div>
          <div className="pb-4">
            <div className="d-flex align-items-center">
              <img src={Num6} alt="numero" className="img-num" />
              <span className="text-num-ccm pl-2">
                Clôture de la levée de fonds
              </span>
            </div>
            <div className="py-3 d-flex">
              <div className="pr-4">
                <p className="texte-ccm-home">
                  Nous clôturons la campagne dès le montant maximal atteint ou
                  la date de fin arrivée. Dans le premier cas, nous versons les
                  fonds à l’entreprise sous cinq jours ouvrés et dans le second
                  cas, nous restitutions les fonds collectés à date aux
                  contributeurs.
                </p>
              </div>
              <img src={Illust6} alt="illustration" className="img-illust" />
            </div>
          </div>
          <div className="pb-4">
            <div className="d-flex align-items-center">
              <img src={Num7} alt="numero" className="img-num" />
              <span className="text-num-ccm pl-2">SUIVI</span>
            </div>
            <div className="py-3 d-flex">
              <img src={Illust7} alt="illustration" className="img-illust" />
              <div className="pl-4">
                <p className="texte-ccm-home">
                  Nous assurons le suivi et gérons la relation avec vos
                  contributeurs. Vous aurez accès à un ensemble d’outils pour
                  échanger régulièrement avec eux.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center py-5">
          <Link
            className="btn btn-success test-link-btn"
            to={`${userType === "investisseur" ? "se-financer" : "/questionnaire-pour-se-financer"}`}
            disabled={userType === "investisseur"}
          >
          Déposer votre projet
          </Link>
        </div>
      </section>
      <FrontFooterV2 />
    </div>
  );
};

export default SeFinancer;
