import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import Utilisateur from "../../../Services/Utilisateur";
import { fetchMe } from "../../../../redux/user/userAction";
import { useDispatch } from "react-redux";

function UseEditParam() {
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const dispatch = useDispatch();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        prenom: yup.string().required("Le prénom est requis"),
        nom: yup.string().required("Le nom est requis"),
        phone: yup.string().required("Le numéro de téléphone est requis."),
        email: yup
          .string()
          .email("Votre email n'est pas correcte.")
          .required("L'email est requis."),
        adresse: yup.string().nullable(),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);

    var request = Utilisateur.onEditUtilisateur(
      formValues?.id,
      formValues,
      requestConfig
    );
    await request
      .then(async (resp) => {
        // console.log("resp user add", resp);
        await dispatch(fetchMe());
        await Swal.fire({
          icon: "success",
          title: "Modification réussie!",
          showConfirmButton: false,
          timer: 1500,
        });
        await setIsSuccessfullySubmitted(false);
        // e.target.reset();
        // reset();
      })
      .catch((error) => {
        // console.error("error", error?.response);
        if (
          error?.response?.data?.email?.length &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          Swal.fire({
            icon: "error",
            title: "Cet email est déja associé à un compte.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 500) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée au serveur est survenue veuillez réessayer plus tard",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (error?.response?.data?.status === 400) {
          Swal.fire({
            icon: "error",
            title:
              "Oups! une erreur liée à la requête est survenue, veuillez contacter l'administrateur.",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseEditParam;
