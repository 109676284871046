import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams  } from "react-router-dom";
import Swal from "sweetalert2";
import AuthServices from "../Services/AuthServices";

function UseRegisterForm(saveData) {
  const navigate  = useNavigate ();
  const { projectId } = useParams();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("Email incorrect!")
          .required("L'email est requis."),
        password: yup
          .string()
          .required("Le mot de passe est requis.")
          .min(8, "Le mot de passe doit contenir au minimum 8 caractères")
          .matches(
            /[a-z]+/,
            "Le mot de passe doit contenir au minimum une minuscule"
          )
          .matches(
            /[A-Z]+/,
            "Le mot de passe doit contenir au minimum une majuscule"
          )
          .matches(
            /[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
            "Le mot de passe doit contenir au minimum un caractère spécial"
          )
          .matches(
            /\d+/,
            "Le mot de passe doit contenir au minimum un chiffre"
          ),
        passwordConfirmation: yup
          .string()
          .oneOf(
            [yup.ref("password"), null],
            "Veuillez entrer des mots de passe identiques."
          )
          .required("Veuillez confirmer votre mot de passe"),
        prenom: yup.string().required("Le prénom est requis."),
        nom: yup.string().required("Le nom est requis."),
        phone: yup.string().required("Le numéro de téléphone est requis."),
        risques: yup
          .bool()
          .oneOf(
            [true],
            "Veuillez accepter d'avoir pris connaissance des risques liés à l'investissement"
          ),
        conditions: yup
          .bool()
          .oneOf(
            [true],
            "Veuillez attester que les fonds de vos futurs investissement sur la plateforme ne proviennent pas d'activités illégales."
          ),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitForm = async (formValues, e) => {
    if (saveData) {
      saveData(formValues);
    }
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    var request = AuthServices.onRegister(formValues);

    await request
      .then(async (resp) => {
        // console.log("resp", resp);
        // window.sessionStorage.setItem("userToken", resp?.token);
        if (resp?.status === "success") {
          await Swal.fire({
            icon: "success",
            title:
              "Merci pour votre inscription. Veuillez confirmer votre email pour finaliser votre inscription !",
            showConfirmButton: true,
            iconColor: "#115c67",
            confirmButtonColor: "#115c67",
            allowOutsideClick: false,
            // timer: 1500,
          }).then(() => {
            if (window.location.pathname === `/projet/inscription/${projectId}`) {
              navigate(`/projet/connexion/${projectId}`)
            } else {
              navigate("/connexion");
            }
          });
        } else {
          await Swal.fire({
            icon: "error",
            title: resp?.message,
            showConfirmButton: false,
            timer: 5000,
          });
        }

        setIsSuccessfullySubmitted(false);
      })
      .catch((error) => {
        console.error("error", error, error?.response);
        if (
          error?.response?.data?.message &&
          !error?.response?.data?.error?.email?.length
        ) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (
          (error?.response?.data?.email?.length ||
            error?.response?.data?.error?.email?.length) &&
          (error?.response?.data?.email[0] ===
            "user with this email already exists." ||
            error?.response?.data?.error?.email[0] ===
              "user with this email already exists.")
        ) {
          //   toast.error("Email ou mot de passe incorrect.");
          Swal.fire({
            icon: "error",
            title:
              "Votre e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte ou vous connecter.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (error?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          //   toast.error("Veuillez vérifier votre connexion internet.");admin
          Swal.fire({
            icon: "error",
            title: "Veuillez vérifier votre connexion internet.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
        setIsSuccessfullySubmitted(false);
      });
  };
  return {
    register,
    errors,
    formState,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    watch,
    reset,
    setValue,
    onSubmit: handleSubmit(onSubmitForm),
  };
}

export default UseRegisterForm;
