/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import map from "../../../assets/images/map.png";
import "./NousContacter.css";
import UseContactForm from "./UseContactForm";
import { useSelector } from "react-redux";

const NousContacter = ({ props }) => {
  const {
    register,
    onSubmit,
    formState,
    isSuccessfullySubmitted,
    setValue,
    clearErrors,
  } = UseContactForm();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user?.data?.id) {
      setValue("nom_complet", user?.data?.prenom + " " + user?.data?.nom);
      setValue("email", user?.data?.email);
      setValue("user", user?.data?.id);
    }
  }, [user]);
  return (
    <div>
      <FrontHeaderV2 />

      <div className="banniereContacter">
        <h1 className="titrebanniereContacter ">
          Nous pouvons être plus proche!
        </h1>
        <div className="justify-content-center d-flex">
          <p className="textebanniereContacter pt-3">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum
            sed rhoncus
            <br />
            odio eget aliquet. Duis nisi quis justo etiam massa vitae.
          </p>
        </div>
      </div>
      <section className="row container d-flex justify-content-around main-contact  mx-auto">
        <div className="  col-md-8 col-lg-6  col-12 mx-1 w-100">
          <h1 className="titreContacter pb-5">CONTACTEZ-NOUS</h1>
          <p className="texteContacter1 pb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing
            <br /> elit. Non ut habitant ullamcorper nunc turpis iaculis. Quam.
          </p>
          <p className="texteContacter2">
            Inscrivez-vous pour être rapplé par notre équipe et
            <br /> discuter d’une stratégie adaptée à vos besoins. <br />
            <br />
            <br />
            Cet appel n’engage en rien. Demandez une consultaion et une
            <br /> présentation plus personalisée de
            <br />
            l’investissement/ financement qui vous intéresse.
            <br />
            <br /> Ou alors dites nous en détails ce que vous voulez, en <br />
            nous écrivant directement un message.
          </p>
        </div>
        <div className=" col-md-6 col-lg-4 col-12 mx-1">
          <form onSubmit={onSubmit}>
            <div className="form-group d-flex flex-column pb-5">
              <label className="labelform">Nom et prénom</label>
              <input
                type="text"
                name="nom_complet"
                className="inputform px-4"
                {...register("nom_complet")}
                placeholder="Nom et Prénom"
              />
              {formState?.errors && formState?.errors?.nom_complet && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.nom_complet?.message}
                </div>
              )}
            </div>
            <div className="form-group d-flex flex-column pb-5">
              <label className="labelform">Email</label>
              <input
                type="email"
                name="email"
                className=" inputform px-4"
                placeholder="Email"
                {...register("email")}
              />
              {formState?.errors && formState?.errors?.email && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.email?.message}
                </div>
              )}
            </div>
            <div className="form-group d-flex flex-column">
              <label className="labelform">Message</label>
              <textarea
                className="textareaform"
                name="message"
                id="message"
                cols="30"
                rows="10"
                {...register("message")}
              ></textarea>
              {formState?.errors && formState?.errors?.message && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.message?.message}
                </div>
              )}
            </div>
            {isSuccessfullySubmitted && (
              <button className="btnform" disabled>
                En cours &nbsp;
                <i className="fas fa-spin fa-spinner"></i>
              </button>
            )}
            {!isSuccessfullySubmitted && (
              <button className="btnform">ENVOYER</button>
            )}
          </form>
        </div>
      </section>
      <section>
        <h1 className="titreContacter">L O C A L I S A T I O N</h1>
        <div className="w-100">
          <img
            src={map}
            style={{ width: "100%", height: "auto" }}
            alt="notre emplacement"
          />
        </div>
      </section>

      <FrontFooterV2 />
    </div>
  );
};

export default NousContacter;
