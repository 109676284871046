import React, { useState, useEffect } from "react";
import { useStep } from "react-hooks-helper";
import VotreProjet from "./VotreProjet";
import RemplireLeFormulaireDuDepot from "./RemplireLeFormulaireDuDepot";
import InformationsSuplementairesSurLentreprise from "./InformationsSuplementairesSurLentreprise";
import InformationsSurLaConcurence from "./InformationsSurLaConcurence";
import InformationsSurLentreprise from "./InformationsSurLentreprise";
import CreerUneDatePourLeLancement from "./CreerUneDatePourLeLancement";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import QuestionnaireForm from "./QuestionnaireForm";

const steps = [
  { id: "questionnaire-votre-projet", Component: VotreProjet },
  {
    id: "questionnaire-form",
    Component: QuestionnaireForm,
  },
  {
    id: "commencer-remplire-formulaire",
    Component: RemplireLeFormulaireDuDepot,
  },
  { id: "company-information", Component: InformationsSurLentreprise },
  { id: "competition-information", Component: InformationsSurLaConcurence },
  {
    id: "additional-company-information",
    Component: InformationsSuplementairesSurLentreprise,
  },
  { id: "create-launch-date", Component: CreerUneDatePourLeLancement },
];

const defaultData = {
  title: null,
  url_demo: null,
  description: null,
  taille_marche: null,
  fraction_objectif: null,
  quand: null,
  combien: null,
  probleme: null,
  pourquoi_probleme: null,
  sauce_secrete: null,
  valeur: null,
  produit_marche: null,
  revenus: null,
  evolutivite: null,
  concurrents: null,
  positionnement_strategique: null,
  avantage_concurrentiel: null,
  maintenance_avantage_concurrentiel: null,
  autres_commentaires: null,
  financement: null,
  fonds_supplementaires: null,
  date_lieu_societe: null,
  capitale: null,
  repartition_propriete: null,
  nombre_fondateurs: null,
  actionnarat: null,
  bio_fondateur: null,
  nom: null,
  prenom: null,
  telephone: null,
  lieu_connaissance: null,
  date_lancement: null,
  senegal: null,
  description_titre: null,
  site: null,
  objectif_financement: null,
  email: null,
  mode_financement: null,
  taux_entree: null,
  secteur: null,
  user: null,
  modele_intervention: null,
  strategie_sortie: null,
  instrument_financier: null,
  documents: null,
  duree_existance: null,
  produit_duree: null,
  besoin_evolutivite: null,
  fonds_supplementaires_prix: null,
  fonds_supplementaires_usage: null,
  revenu_dernier_mois: null,
  taux_dernier_mois: null,
  taux_croissance_dernier_mois: null,
  position: null,
  courriel: null,
  souscription_pallier: null,
  utilisateurs_actifs: null,
  taux: null,
  revenu_annuel_prevu: null,
  revenu_annuel_prevu_trois_ans: null,
  kpis: null,
  valeur_annuel_kpis: null,
  vitesse_kpis: null,
  besoins_pour_croitre: null,
  vitesse_croissance: null,
  otherSecteur: null,
};

const Questionnaire = () => {
  const [formData, setFormData] = useState(defaultData);
  const { step, navigation, index } = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { formData, setFormData, navigation };

  useEffect(() => {
    // console.log("data", index);
    if (index !== 5) {
      window.scrollTo(0, 0);
    }
  }, [formData, index]);

  return (
    <section className="questionnaire-container">
      {index !== 10 && index !== 11 && index !== 12 && <FrontHeaderV2 active={3} />}
      <div className="questionnaire">
        <Component {...props} />
      </div>
      <FrontFooterV2 />
    </section>
  );
};

export default Questionnaire;
