import axios from "axios";
import api from "../../Http/global-vars";
import { FETCH_PROJECTS_IN_CAMPAIGN } from "../types";

export const fetchProjectsInCampaign = (id) => {
	return async (dispatch) => {
		const config = {
			headers: {
				Authorization: `Bearer ${window.sessionStorage.getItem(
					"userToken"
				)}`,
			},
		};
		let token = window.sessionStorage.getItem("userToken");
		const url = api + "projet_enfinancementbyporteur/" + id + "/";
		if (token) {
			await axios
				.get(url, config)
				.then(async (res) => {
					dispatch({
						type: FETCH_PROJECTS_IN_CAMPAIGN,
						payload: res?.data.results.sort((a, b) =>
							a?.id > b?.id
								? 1
								: a?.id === b?.id
								? 0
								: -1
						),
					});
				})
				.catch((err) => console.log("error", err?.response));
		}
	};
};
