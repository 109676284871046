import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './conditionUtilisationInscription.css'
import api from '../../../Http/global-vars'
import axios from 'axios'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2'



var ConditionUtilisationInscription = ({navigation}) => {
  const user_id = window.sessionStorage.getItem('userID');
  const navigate = useNavigate()
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem('userToken')}`,
    },
  }
  const {
    handleSubmit,
  } = useForm()
  const onSubmitCGU = async (data) => {
    //  let data = {date_naissance,sexe,adresse}
    // console.warn('mamam', value)
    var fd = new FormData();
    fd.append('conditions', data.conditions)
    axios
      .put(`${api}user/${user_id}/`, fd, requestConfig)
      .then(async (response) => {
        console.log('responsnsnsns', response);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Félicitation votre profil vient d\'être completé',
          showConfirmButton: true,
          iconColor: '#BB4411',
          confirmButtonColor: '#BB4411'
        })
        setTimeout(() => {
          // window.location = '/app/inscription-verification-mail'
          navigate('/Validation-identite')

        }, 3000)
      })
      .catch ((e) => {
        console.log('error', e);

      })

  }

return (
  <div className="component-front-register-infos-perso-two">
    <div className='card card-body card-telecharger-document'>
      <div className="div-contenant">
          <div className="row">
            <div className="">
            <form onSubmit={handleSubmit(onSubmitCGU)}  >
              <div className="paragraph-mail-scroll mt-4 p-3">
                <h5 className="title-verification titre-document">Conditions générales d’utilisation</h5>
                <p className="mt-5 paragraph-mail-scroll2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iaculis pretium sed accumsan nibh praesent. Amet ullamcorper facilisis tristique mauris, quam posuere cras tellus. Sed adipiscing egestas quis eget vel pulvinar integer proin. Eu eleifend turpis diam nec mauris, fermentum. Aliquam, proin malesuada nulla venenatis semper tortor. Ut massa porttitor massa vestibulum.
                  Tortor augue quam sed commodo. Nibh ipsum in egestas integer. Facilisi varius turpis libero nibh sed interdum dapibus turpis malesuada. Dolor aliquam tristique diam aenean enim sed volutpat, mattis morbi. Morbi lorem donec dictum lacus sit sem morbi suspendisse. Ultricies id suspendisse sit risus feugiat ultrices.
                  Integer fringilla eros lacus vel diam vel tincidunt. Sed leo, sagittis tortor volutpat dui. Felis, nec cras ornare molestie. Nunc elit ornare sed malesuada viverra lorem malesuada ut. Montes, at elementum scelerisque ornare feugiat eu diam. Hendrerit nam in neque, tincidunt aliquam. Ac nulla sodales nisi imperdiet nisi, sed id egestas. Eros suspendisse hendrerit quisque faucibus nunc, donec mauris erat ut.
                  Sit aliquam augue ullamcorper facilisis vitae ornare mauris. Tellus, nulla pulvinar vel condimentum. Amet, habitant cum et vitae convallis netus. Lectus pulvinar vitae amet tincidunt. Ornare sem convallis at felis amet, volutpat. Congue placerat turpis interdum egestas sed.
                  A malesuada habitasse et sagittis pellentesque volutpat ultrices. A elementum, in in egestas non vitae pharetra. Velit eget sit tincidunt dignissim habitant duis diam ornare elementum. Aliquam metus, ac in massa quis. Lorem massa nibh risus ligula faucibus eu vel vivamus. Magna enim turpis ipsum nisi, purus ultricies viverra enim, vulputate. Est morbi urna lacus, in et sit morbi sem.
                  Blandit diam posuere venenatis posuere ac egestas. Tempus nisl quam congue consequat quisque et euismod. Risus at pellentesque non sodales elementum accumsan. Felis quis est ornare nunc, massa quam purus. Ultrices nibh enim arcu, eget donec ut eu dictum. Pellentesque urna, odio vel sagittis, lobortis id. Porttitor pharetra, dolor, commodo ullamcorper pulvinar egestas arcu eu. Neque proin feugiat pellentesque consequat, nullam nulla quam. Felis lorem purus sit consequat nulla semper molestie in neque.
                  Massa duis nisi, in morbi quis pulvinar curabitur volutpat. Urna nulla risus nulla risus sed mattis dignissim faucibus elit. Sed mauris, orci ut accumsan ipsum. Ullamcorper aliquam aliquet et sit turpis pharetra convallis aliquet. Cursus vel hac tincidunt a, eget. Sem ornare varius a aliquam. Malesuada gravida ornare amet neque integer.</p>
              </div>

              <div className="row">
                <div className="div-btn-accepter mt-5 mx-5 mx-md-3">
                 
                  <div className='boutton-div'>

                    {/* :::::::::::::::::::: NE PAS SUPPRIMER ::::::::::::::::::::::: */}

                    {/* <button className="btn boutton-continuer" type="submit">
                      Accepter
                    </button> */}

                    {/* :::::::::::::::::::: A SUPPRIMER :::::::::::::::::::::::::::: */}
                    <NavLink to="/dashboard/validation-identite" className="btn boutton-continuer" > Accepter</NavLink>
                    
                  </div>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  </div>);

}

export default ConditionUtilisationInscription;
