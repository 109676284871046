import React from "react";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import "./RisquesInvestissement.css";
import investment1 from "../../../assets/images/investment1.png";
import investment2 from "../../../assets/images/investment2.png";
import investment3 from "../../../assets/images/investment3.png";

const RisquesInvestissement = () => {
	return (
		<div className="component-front-home">
			<FrontHeaderV2 />
			<div className="row m-b-30">
				<div className="col-12 banner-investment">
					<div className="row p-5">
						<div className="col-md-9 col-lg-7 col-xl-6">
							<h1 className="banner-investment-title">
								Comprendre les risques
							</h1>
							<div className="banner-investment-text">
								Lorem ipsum dolor sit amet,
								consectetur adipiscing elit.
								Orci pellentesque id mattis
								blandit vitae, mus eget bibendum
								ornare. Molestie neque,
								facilisis blandit porta
								ultricies eget sed. Ultricies
								blandit ultrices dolor praesent
								adipiscing hendrerit. Tincidunt
								risus laoreet blandit vitae. Sed
								urna, tristique sed porta et.
								Dapibus feugiat facilisis
								vestibulum at tincidunt
								tincidunt lectus. Phasellus
								congue erat in a ac fringilla
								nunc. Neque semper fringilla
								viverra aliquam, etiam a amet.
								Lobortis libero gravida aenean
								pellentesque est ac habitant
								viverra diam. Scelerisque elit
								cras eu blandit mattis
								scelerisque semper. Sit amet,
								lectus vulputate interdum.
								Pellentesque fusce eleifend
								nulla blandit. Metus, purus,
								nulla diam nulla faucibus nunc,
								lorem. Vitae quis faucibus
								egestas elementum vivamus
								convallis feugiat posuere
								feugiat. Nunc urna, ultrices
								diam habitant venenatis morbi.
								Vestibulum pulvinar lacinia nisl
								quis duis. Tempor lacus.
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="funding-description-section-container">
				<div className="container-cayore funding-description-container">
					<div className="row funding-risques-item-row d-flex flex-md-row-reverse">
						<div className="col-md-6 funding-description-illustration-col">
							<div className="funding-description-illustration-container">
								<img
									src={investment1}
									className="funding-description-illustration-img"
									alt="finance-description-img"
								/>
							</div>
						</div>
						<div className="col-md-6 funding-description-textual-content-col">
							<div className="funding-description-textual-content-container">
								<h3 className="funding-description-textual-title mb-3">
									Les risques liés à
									l’investissement en Action
								</h3>
								<div className="funding-description-textual-container">
									<p className="funding-description-textual">
										Lorem ipsum dolor
										sit amet,
										consectetur
										adipiscing elit. Vel
										in porta bibendum
										dolor dignissim diam
										aliquam aliquet.
										Tristique nunc
										mauris eget mauris.
										Accumsan id
										consequat euismod
										iaculis suscipit
										egestas cursus. Nibh
										odio purus cum
										vestibulum a. Urna
										lacus, tellus
										interdum eu nunc,
										facilisis habitant.
										In eu, id morbi non
										interdum. Sit tortor
										vitae sed faucibus
										amet posuere tortor.
										Cras ullamcorper
										arcu leo id mattis
										nibh ultricies
										egestas arcu. Est
										curabitur morbi
										venenatis arcu nulla
										amet ac. Proin quis
										rhoncus mauris
										feugiat interdum
										tempus, enim
										tincidunt eu. Cursus
										elementum elit ut
										quam platea feugiat.
										Rhoncus, nunc nibh
										aenean tellus,
										quisque adipiscing
										lectus. Pellentesque
										tortor eget porta
										mollis urna
										penatibus. Enim
										aliquam mauris,
										molestie leo
										rhoncus. Metus
										maecenas iaculis.
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="row funding-risques-item-row ">
						<div className="col-md-6 funding-description-illustration-col">
							<div className="funding-description-illustration-container">
								<img
									src={investment2}
									className="funding-description-illustration-img"
									alt="levee-fond-img"
								/>
							</div>
						</div>
						<div className="col-md-6 funding-description-textual-content-col">
							<div className="funding-description-textual-content-container">
								<h3 className="funding-description-textual-title mb-3">
									Les risques liés à
									l’investissement 
								</h3>
								<div className="funding-description-textual-container">
									<p className="funding-description-textual">
										Lorem ipsum dolor
										sit amet,
										consectetur
										adipiscing elit. Vel
										in porta bibendum
										dolor dignissim diam
										aliquam aliquet.
										Tristique nunc
										mauris eget mauris.
										Accumsan id
										consequat euismod
										iaculis suscipit
										egestas cursus. Nibh
										odio purus cum
										vestibulum a. Urna
										lacus, tellus
										interdum eu nunc,
										facilisis habitant.
										In eu, id morbi non
										interdum. Sit tortor
										vitae sed faucibus
										amet posuere tortor.
										Cras ullamcorper
										arcu leo id mattis
										nibh ultricies
										egestas arcu. Est
										curabitur morbi
										venenatis arcu nulla
										amet ac. Proin quis
										rhoncus mauris
										feugiat interdum
										tempus, enim
										tincidunt eu. Cursus
										elementum elit ut
										quam platea feugiat.
										Rhoncus, nunc nibh
										aenean tellus,
										quisque adipiscing
										lectus. Pellentesque
										tortor eget porta
										mollis urna
										penatibus. Enim
										aliquam mauris,
										molestie leo
										rhoncus. Metus
										maecenas iaculis.
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="row funding-risques-item-row d-flex flex-md-row-reverse">
						<div className="col-md-6 funding-description-illustration-col">
							<div className="funding-description-illustration-container">
								<img
									src={investment3}
									className="funding-description-illustration-img"
									alt="plus-un-financement-img"
								/>
							</div>
						</div>
						<div className="col-md-6 funding-description-textual-content-col">
							<div className="funding-description-textual-content-container">
								<h3 className="funding-description-textual-title mb-3">
									Les risques liés à
									l’investissement en
									Obligation
								</h3>
								<div className="funding-description-textual-container">
									<p className="funding-description-textual">
										Lorem ipsum dolor
										sit amet,
										consectetur
										adipiscing elit. Vel
										in porta bibendum
										dolor dignissim diam
										aliquam aliquet.
										Tristique nunc
										mauris eget mauris.
										Accumsan id
										consequat euismod
										iaculis suscipit
										egestas cursus. Nibh
										odio purus cum
										vestibulum a. Urna
										lacus, tellus
										interdum eu nunc,
										facilisis habitant.
										In eu, id morbi non
										interdum. Sit tortor
										vitae sed faucibus
										amet posuere tortor.
										Cras ullamcorper
										arcu leo id mattis
										nibh ultricies
										egestas arcu. Est
										curabitur morbi
										venenatis arcu nulla
										amet ac. Proin quis
										rhoncus mauris
										feugiat interdum
										tempus, enim
										tincidunt eu. Cursus
										elementum elit ut
										quam platea feugiat.
										Rhoncus, nunc nibh
										aenean tellus,
										quisque adipiscing
										lectus. Pellentesque
										tortor eget porta
										mollis urna
										penatibus. Enim
										aliquam mauris,
										molestie leo
										rhoncus. Metus
										maecenas iaculis.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<FrontFooterV2 />
		</div>
	);
};

export default RisquesInvestissement;
