import React, { useEffect } from "react";
import "./Prets.css";
import * as IconlyPack from "react-iconly";
import SidebarTableauBord from "../SidebarTableauBord/SidebarTableauBord";
import SidebarMobileTableauBord from "../SidebarTableauBord/SidebarMobileTableauBord";
import HeaderV2 from "../../../BackendV2/Admin/Navs/HeaderV2";
// import FrontFooterV2 from "../../FrontFooterV2/FrontFooterV2";
import { Rate } from "rsuite";
import { NavLink } from "react-router-dom";
import HistoriquePretTable from "../../../Tables/TableauDeBordInvestisseur/HistoriquePretTable/HistoriquePretTable";
import InvestisseurCalendrierRemboursementTable from "../../../Tables/TableauDeBordInvestisseur/InvestisseurCalendrierRemboursementTable/InvestisseurCalendrierRemboursementTable";
// import Dashboard from "../MonTableauDeBord/Dashboard";

var Prets = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="component-investisseur">
      <HeaderV2 />
      <div className="content px-2 my-4 d-flex py-0 content-title-page-tb-investisseur">
        <div className="d-flex align-items-lg-center">
          {/* <Dashboard/> */}
        </div>
        <div className="d-flex align-item-end col-12 col-lg-9 col-xl-9 ">
          <ul
            class="nav nav-pills mb-3 d-flex flex-column flex-md-row align-items-md-end justify-content-between justify-content-xl-around p-5 col-12"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item nav-item-tabs" role="presentation">
              <a
                class="nav-link active navlink-tabs-prets"
                id="pills-activite-tab"
                data-toggle="pill"
                href="#pills-activite"
                role="tab"
                aria-controls="pills-activite"
                aria-selected="true"
              >
                Mes activités
              </a>
            </li>
            <li class="nav-item nav-item-tabs" role="presentation">
              <a
                class="nav-link navlink-tabs-prets"
                id="pills-solde-tab"
                data-toggle="pill"
                href="#pills-solde"
                role="tab"
                aria-controls="pills-solde"
                aria-selected="false"
              >
                Mon solde
              </a>
            </li>
            <li class="nav-item nav-item-tabs" role="presentation">
              <a
                class="nav-link navlink-tabs-prets"
                id="pills-historique-tab"
                data-toggle="pill"
                href="#pills-historique"
                role="tab"
                aria-controls="pills-historique"
                aria-selected="false"
              >
                Historique
              </a>
            </li>
            <li class="nav-item nav-item-tabs" role="presentation">
              <a
                class="nav-link navlink-tabs-prets"
                id="pills-remboursement-tab"
                data-toggle="pill"
                href="#pills-remboursement"
                role="tab"
                aria-controls="pills-remboursement"
                aria-selected="false"
              >
                Remboursement
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="content-view-start">
        <div className="row">
          <div className="col-lg-3 no-view-mobile">
            <SidebarTableauBord />
          </div>
          <div className="col-lg-9 px-0 pr-4">
            <div className="no-see-desktop-sidebar margin-top-sidebar-mobile-tabs">
              <SidebarMobileTableauBord />
            </div>
            <div className="mb-5 section-content-page-tb-investisseur">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active in"
                  id="pills-activite"
                  role="tabpanel"
                  aria-labelledby="pills-activite-tab"
                >
                  <div className="bg-white repartition-pret">
                    <div className="page-first-row-container border-bottom">
                      <div className="page-title-container pt-3">
                        <h1 className="page-title">Synthèse au 1er juin</h1>
                      </div>
                      <div className="d-flex justify-conetent-end">
                        <div className="mr-3">
                          <NavLink
                            to="#"
                            className="btn btn-debiter-compte trans-0-2"
                          >
                            <IconlyPack.ArrowUp set="light" />
                            <span className="pl-2">Alimenter</span>
                          </NavLink>
                        </div>
                        <div>
                          <NavLink
                            to="#"
                            className="btn btn-debiter-compte trans-0-2"
                          >
                            <IconlyPack.ArrowDown set="light" />
                            <span className="pl-2">Débiter</span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="py-4">
                      <ul>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <p className="titre-synthese-list">
                                Récapitulatif
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="titre-synthese-list">
                                Taux de rentabilité
                              </p>
                            </div>
                            <div className="col-md-4">
                              <p className="titre-synthese-list">
                                Diversification
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  En cours
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  10 000 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  TRI initial
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  2%
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Niveau
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  <Rate
                                    defaultValue={5}
                                    size="xs"
                                    style={{ color: "rgba(17, 92, 103, 0.5)" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Disponible
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  10 000 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  TRI actuel
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  2%
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Prêts
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  1
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Total
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  20 000 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Exposition max
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  100%
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="pt-5">
                      <div className="page-first-row-container border-bottom">
                        <div className="page-title-container pt-3">
                          <h1 className="page-title">Activités</h1>
                        </div>
                        <div className="d-flex justify-conetent-end">
                          <div className="mr-3">
                            <NavLink
                              to="#"
                              className="btn btn-debiter-compte trans-0-2"
                            >
                              <IconlyPack.Upload set="light" />
                              <span className="pl-2">Exporter</span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <ul>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <p className="titre-synthese-list">Mai 2021</p>
                            </div>
                            <div className="col-md-4">
                              <p className="titre-synthese-list">Juin 2021</p>
                            </div>
                            <div className="col-md-4">
                              <p className="titre-synthese-list">
                                Juillet 2021
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Remboursement{" "}
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  0 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Remboursements
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  0 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Remboursements
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  0 FCFA
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item-tabs py-2">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Prêté
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  10 000 FCFA
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Prêté
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="div-content-list">
                                <div className="text-contenu-list-pret">
                                  Prêts
                                </div>
                                <div className="text-contenu-list-pret-color">
                                  0 FCFA
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-solde"
                  role="tabpanel"
                  aria-labelledby="pills-solde-tab"
                >
                  <div className="bg-white repartition-pret">
                    <div className="page-first-row-container border-bottom">
                      <div className="page-title-container pt-3">
                        <h1 className="page-title">Solde</h1>
                      </div>
                    </div>
                    <div>
                      <div className="text-center py-4">
                        <p className="page-title">20 000 FCFA</p>
                        <p className="text-solde-abonnement">
                          Soldes des abondements
                        </p>
                        <p className="text-contenu-list-pret">
                          20 000 FCFA abondés + 0 FCFA bonus - 0 FCFA débité
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pb-3">
                          <div className="text-center">
                            <p className="text-contenu-list-pret">0 FCFA</p>
                            <p className="text-solde-abonnement">Prêts</p>
                            <p className="text-contenu-list-pret">
                              dont 0 terminés et 0 en défaut
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 pb-3">
                          <div className="text-center">
                            <p className="text-contenu-list-pret">0 FCFA</p>
                            <p className="text-solde-abonnement">Prêts</p>
                            <p className="text-contenu-list-pret">
                              dont 0 terminés et 0 en défaut
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 pb-3">
                          <div className="text-center">
                            <p className="text-contenu-list-pret">0 FCFA</p>
                            <p className="text-solde-abonnement">Prêts</p>
                            <p className="text-contenu-list-pret">
                              dont 0 terminés et 0 en défaut
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 pb-3">
                          <div className="text-center">
                            <p className="text-contenu-list-pret">0 FCFA</p>
                            <p className="text-solde-abonnement">Prêts</p>
                            <p className="text-contenu-list-pret">
                              dont 0 terminés et 0 en défaut
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-historique"
                  role="tabpanel"
                  aria-labelledby="pills-historique-tab"
                >
                  <div className="bg-white p-3">
                <div className="page-first-row-container border-bottom">
                  <div className="page-title-container pt-3">
                    <h1 className="page-title">Historique de vos prêts</h1>
                  </div>
                </div>
                <HistoriquePretTable />
              </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-remboursement"
                  role="tabpanel"
                  aria-labelledby="pills-remboursement-tab"
                >
                 <div className="bg-white p-3">
                <div className="page-first-row-container border-bottom">
                  <div className="page-title-container pt-3">
                    <h1 className="page-title">Calendrier de remboursement</h1>
                  </div>
                </div>
                <InvestisseurCalendrierRemboursementTable />
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FrontFooterV2 /> */}
    </div>
  );
};

export default Prets;
