import React from "react";
import "./telechargerPhotoIdentiteInscription.css";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import FileDownload from "../../../assets/images/icons/filedownload.png";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import api from "../../../Http/global-vars";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const TelechargerPhotoIdentiteInscription = ({ navigation }) => {
  const { next } = navigation;

  const token = window.sessionStorage.getItem("userToken");
  const userID = window.sessionStorage.getItem("userID");
  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const validationSchema = Yup.object().shape({
    last_piece: Yup.bool().oneOf([true], "Ce champ est obligatoire."),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(formOptions);

  const handleChangeStatus = ({ meta, file }, status) => {
    file.height = meta.height;
    // console.log(status, meta, file);
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length === 0 && (
      // 'Ajouter encore des photos' :
      <div className="start-label d-flex">
        <div className="label-img-container col-1">
          <img
            src={FileDownload}
            className="file-download pt-2"
            alt="file download"
          />
        </div>
        <div className="col-11">
          <span className="first_label">
            Déposez une image ici ou sélectionnez un fichier.
          </span>
          <span className="second-label">
            Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 2Mo.
          </span>
        </div>
      </div>
    );
    let buttonStartStyle = {
      // background: "rgb(255, 152, 0)",
      cursor: "pointer",
      padding: 15,
      borderRadius: 30,
      marginTop: 10,
      textAlign: "center",
      maxWidth: "30%",
      marginLeft: "35%",
      color: "var(--primaryColor)",
      fontWeight: 700,
    };

    if (files.length <= 0) {
      buttonStartStyle = {
        // color: '#ff9800',
        cursor: "pointer",
        padding: 15,
        borderRadius: 30,
        marginTop: 10,
        textAlign: "center",
      };
    }

    return (
      <div className="row upload-dropzone-row">
        <div style={buttonStartStyle} className="col-md-12">
          {text}
          <input
            style={{ opacity: 0, cursor: "pointer" }}
            className="file-input"
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        </div>
      </div>
    );
  };
  const onSubmitDocumentIdentite = async (data) => {
    console.warn("mamam", data);
    var fd = new FormData();
    fd.append("piece_2", data.piece_2);
    fd.append("last_piece", data.last_piece);
    axios
      .put(`${api}user/${userID}/`, fd, requestConfig)
      .then(async (response) => {
        console.log("responsnsnsns", response);
        window.$("#nextdocumentcard").click();
        // console.log(window.$('#continuons').click())
        navigate("/rib-inscription");
      })
      .catch((e) => {
        console.log("error", e);
        console.log("error", e);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Vous devez inserer une copie de votre piece d'identité.",
          showConfirmButton: true,
          iconColor: "#115c67",
          confirmButtonColor: "#115c67",
        });
      });
  };
  return (
    <div className="component-front-register-infos-perso-two">
      <div className="card card-body card-telecharger-document">
        <div>
          <form onSubmit={handleSubmit(onSubmitDocumentIdentite)}>
            <div className="div-contenant">
              <h5 className="title-verification titre-document">
                Téléchargez une photo de votre pièce d'identité
              </h5>
              <div className="bloc-div-register-form">
                <div className="row">
                  <div className="col-md-12">
                    <div className="style-checkbox  mt-5">
                      <div class="form-check">
                        <input
                          className="form-check-input style-chckbox style-chcbx-passport"
                          type="checkbox"
                          value={true}
                          defaultChecked={true}
                          name="last_piece"
                          id="defaultCheck1"
                          {...register("last_piece")}
                        />
                        <label
                          className="form-check-label my-1"
                          for="defaultCheck1"
                        >
                          <span className="style-prev">Passport</span>
                        </label>
                      </div>
                      {errors?.last_piece && (
                        <div className="alert alert-danger easypm-alert-danger">
                          {""}
                          {errors?.last_piece?.message}
                          {""}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 py-5 w-full">
                    <div className="input-file">
                      <div className="form-group">
                        <Dropzone
                          // getUploadParams={getUploadParams}
                          onChangeStatus={handleChangeStatus}
                          // onSubmit={handleSubmit}
                          accept="image/*"
                          styles={{
                            dropzoneReject: {
                              borderColor: "#F19373",
                              //backgroundColor: "#F1BDAB",
                            },
                            inputLabel: (files, extra) =>
                              extra.reject ? { color: "#A02800" } : {},
                          }}
                          maxFiles={1}
                          inputWithFilesContent={(files) => {
                            register("piece_2");
                            setValue("piece_2", files[0]?.file);
                          }}
                          InputComponent={InputChooseFile}
                          getFilesFromEvent={getFilesFromEvent}
                          classNames
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="boutton-div">
                    <button
                      className="btn boutton-continuer"
                      type="submit"
                      // onSubmit={() => {next()}}
                      onClick={next}
                    >
                      Enregistrer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TelechargerPhotoIdentiteInscription;
