import React from "react";
import "./ChoixTypeInvestisement.css";
import "material-icons/iconfont/material-icons.css";
import FrontFooterV2 from "../FrontFooterV2/FrontFooterV2";
import { NavLink } from "react-router-dom";
import { User, IconlyProvider, Chart } from "react-iconly";
import FrontHeaderV2 from "../FrontHeaderV2/FrontHeaderV2";
import TabsProfile from "../InvestisseurTableauDeBord/ProfilInvestisseur/TabsProfile/TabsProfile";
import SidebarTableauBord from "../InvestisseurTableauDeBord/SidebarTableauBord/SidebarTableauBord";
const ChoixTypeInvestisement = () => {
	// const [personnePhysique, setPersonnePhysique] = useState(false);
	// const [personneMorale, setPersonneMorale] = useState(false);

	return (
		<div className="component-mes-projet-tableau-de-bord bg-dark">
			<FrontHeaderV2 />
			<TabsProfile value={1}/>
			<div className="mt-3 row p-0">
				<div className="col-12 col-lg-4 col-xl-3 p-0">
					<SidebarTableauBord />
				</div>
				<div className="col-12 col-lg-8 col-xl-9 mt-3 mt-lg-0">
					<div className="title-block">
						<h1>Votre identité</h1>
					</div>

					<div className="d-flex justify-content-around bg-white p-b-200 px-5 pt-5">
						<div className="m-2 texteinvestir hover-link-physique choix-item mr-5 ">
							<NavLink
								to="/dashboard/informations-personnelles"
								className="link-personne-physique"
							>
								<div className="">
									<div className="text-center ">
										<IconlyProvider
											set="bulk"
											secondaryColor="blue"
											stroke="bold"
											size="xlarge"
										>
											<User
												set="bold"
												className="iconeinvestir"
											/>
										</IconlyProvider>
									</div>
									<p className="text-center">
										Vous souhaitez
										investir en nom
										<br /> propre
										(Personne physique)
									</p>
								</div>
							</NavLink>
						</div>
						<div className="m-2 texteinvestir hover-link-physique choix-item">
							<NavLink
								to="/dashboard/informations-personnelles-et-entreprise"
								className="link-personne-physique"
							>
								<div className="">
									<div className="text-center ">
										<IconlyProvider
											set="bulk"
											secondaryColor="blue"
											stroke="bold"
											size="xlarge"
										>
											<Chart
												set="bold"
												className="iconeinvestir"
											/>
										</IconlyProvider>
									</div>
									<p className="text-center">
										Vous souhaitez
										investir au nom
										d’une societé ou
										d’une
										<br />
										organisation
										(Personne
										<br /> morale)
									</p>
								</div>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
			<FrontFooterV2 />
		</div>
	);
};

export default ChoixTypeInvestisement;
