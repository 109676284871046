import React, { useEffect } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
// import ActionsDuMoisTableData from "./ActionsDuMoisTableData";
import ActionsDuMoisTableSkeleton from "./ActionsDuMoisTableSkeleton";
import { parseISO, format } from "date-fns";


const ActionsDuMoisTable = (props) => {
  var [isPageLoading, setIsPageLoading] = React.useState(true);
  // console.log(props.project)
  useEffect(() => {
    setTimeout(() => {
      setIsPageLoading(false);
    }, 3000);
  }, []);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container border-none">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <ActionsDuMoisTableSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={props.project}
                  striped={true}
                  hover={true}
                  condensed={false}
                  multiColumnSort={2}
                  options={options}
                  search={false}
                  version="4"
                  bordered={false}
                >
                  <TableHeaderColumn
                    dataField="id"
                    isKey
                    className="data-center-table-info"
                    hidden
                  ></TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="created_at"
                    className="data-center-table-info"
                    dataFormat={(cell, row) => format(parseISO(cell), "yyyy/MM/dd")}
                  >
                    Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="montant"
                    className="data-center-table-info"
                  >
                    Prêt (FCFA)
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="montant"
                    className="data-center-table-info"
                    hidden
                  >
                    Evolution
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="projet.title"
                    dataFormat={(cell, row) => row.projet.title}
                  >
                    Projet
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionsDuMoisTable;
