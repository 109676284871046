import React from 'react'
//import FrontFooterV2 from "../../../FrontFooterV2/FrontFooterV2";
import FrontHeaderV2 from "../../../FrontHeaderV2/FrontHeaderV2";
import ModificationMotDePasseComponent from '../../../TableauDeBord/ModificationMotDePasse/ModificationMotDePasseComponent';
import SidebarMobileTableauBord from "../../SidebarTableauBord/SidebarMobileTableauBord";
import SidebarTableauBord from "../../SidebarTableauBord/SidebarTableauBord";
import TabsProfile from "../TabsProfile/TabsProfile";

const ModificationMotDePasseInvestisseur = ({saveData}) => {
  return (
    <div className="component-investisseur">
			<FrontHeaderV2 />
			<TabsProfile />
			<div className="row">
				<div className="col-lg-4 col-md-6 col-xl-3 no-view-mobile">
					<SidebarTableauBord />
				</div>
				<div className="col-lg-8 col-xl-9 col-md-6  px-0 pr-4">
					<div className="no-see-desktop-sidebar margin-top-sidebar-mobile">
						<SidebarMobileTableauBord />
					</div>
					<div className="mb-5 section-content-page-tb-investisseur bg-white">
						<ModificationMotDePasseComponent />
					</div>
				</div>
			</div>
			{/* <FrontFooterV2 /> */}
		</div>
  )
}

export default ModificationMotDePasseInvestisseur;
